import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { IClients, ITicketDetails } from '../interfaces/interface';

export function getTicketsTableData(tableData: any[]) {
  const table: any[] = [];
  tableData.forEach((ticketDetails: any) => {
    table.push({
      asset: {
        assetCode: ticketDetails.ticketAssets[0]?.assets?.assetCode || '-',
        name: ticketDetails.ticketAssets[0]?.assets?.name || '-',
      },
      ticket: {
        ticketNumber: ticketDetails?.ticketNumber || '-',
        ticketId: ticketDetails?.id || ticketDetails?.ticketAssets[0]?.ticketId,
        fileUrl: ticketDetails.fileUrl,
        title: ticketDetails?.title || '-',
        isExternalTicket: `${ticketDetails?.isExternalUser ? true : false}`,
        user: {
          firstName: ticketDetails?.users?.firstName || '',
          lastName: ticketDetails?.users?.lastName || '',
          email:
            ticketDetails?.users?.email ||
            ticketDetails?.externalUserEmail ||
            '',
          externalUserName: ticketDetails?.externalUserName || '',
        },
        assignee: {
          firstName: ticketDetails?.assignee?.firstName || '',
          lastName: ticketDetails?.assignee?.lastName || '',
          email: ticketDetails?.assignee?.email || '',
        },
        createdAt: ticketDetails?.createdAt || '-',
        status: ticketDetails?.status || '-',
        isExternalUser: ticketDetails?.isExternalUser || false,
      },
      ticketOrder: {
        ticketOrder: ticketDetails.ticket_order
      },
      ticketPriorities: {
        ticketPriority: ticketDetails?.ticket_priorities?.name
      }
    });
  });
  return table;
}

export function getAssetTeamTicketsData(ticketDetails: any[]) {


  const tableDetails: any[] = []
  ticketDetails.forEach((ticketDetails: any) => {

    tableDetails.push({
      ticketId: ticketDetails?.id || ticketDetails?.ticketAssets[0]?.ticketId,
      TicketNo: ticketDetails?.ticketNumber || '-',
      AssetName: ticketDetails.ticketAssets[0]?.assets?.name || '-',
      Priority: ticketDetails?.ticket_priorities?.name || '-',
      status: ticketDetails?.status || '-',
      UserName: (ticketDetails?.users?.firstName || ' ') + ' ' + (ticketDetails?.users?.lastName || ' '),
      UserEmail:
        ticketDetails?.users?.email ||
        ticketDetails?.externalUserEmail ||
        '',
      isExternalTicket: ticketDetails?.isExternalUser || false,
      externalUserName: ticketDetails?.externalUserName || '',
      AssigneeName: (ticketDetails?.assignee?.firstName || ' ') + ' ' + (ticketDetails?.assignee?.lastName || ' '),
      AssigneEmail: ticketDetails?.assignee?.email || '',
      description: ticketDetails?.description || '',
      Date: ticketDetails?.createdAt.split('T')[0] || '-',
      Day: ticketDetails?.day,
      ticketSubStatus: ticketDetails?.ticketSubStatus
    })

  })
  return tableDetails;
}

export function getAssetsTableData(tableData: any[]) {
  const table: any[] = [];
  tableData.forEach((assetDetails: any) => {
    table.push({
      asset: {
        asset_type: {
          assetCode: assetDetails.assetCode ? assetDetails.assetCode : '-',
          name: assetDetails.name ? assetDetails.name : '-',
        },
        name: assetDetails.name ? assetDetails.name : '-',
      },
      ticket: {
        user: {
          firstName: '-',
          lastName: '',
          email: '',
        },
        updatedAt: assetDetails.updatedAt
          ? assetDetails.updatedAt
          : assetDetails.createdAt,
      },
      status: assetDetails.status,
    });
  });
  return table;
}

export function getAssetsTableDataInventory(tableData: any[]) {

  const table: any[] = [];
  tableData.forEach((assetDetails: any) => {
    table.push({
      asset: {
        asset_type: {
          assetCode: assetDetails.assetCode ? assetDetails.assetCode : '-',
          name: assetDetails.name ? assetDetails.name : '-',
        },
        assetCode: assetDetails.assetCode ? assetDetails.assetCode : '-',
        name: assetDetails ? assetDetails.name : '-',
        loan_status: assetDetails.status ? assetDetails.status : '-',
        loanee: '-',
        asset_id: assetDetails.id,
        location: assetDetails?.location,
        purchaseDate: assetDetails?.purchaseDate,
        createdAt: assetDetails.createdAt || '-',
      },
      ticket: {
        user: {
          firstName: assetDetails.asset_allocations[0] && assetDetails.asset_allocations[0].user ? assetDetails.asset_allocations[0].user.firstName : '-',
          lastName: assetDetails.asset_allocations[0] && assetDetails.asset_allocations[0].user ? assetDetails.asset_allocations[0].user.lastName : '-',
          email: assetDetails.asset_allocations[0] && assetDetails.asset_allocations[0].user ? assetDetails.asset_allocations[0].user.email : '-'
        },
        updatedAt: assetDetails.updatedAt
          ? assetDetails.updatedAt
          : assetDetails.createdAt,
      },
    });
  });
  return table;
}

export function getWorkspaceTableData(tableData: any[]) {
  const table: any[] = [];

  tableData.forEach((workspaceDetails: any) => {

    table.push({
      workspace: {
        name: workspaceDetails.name || '-', // Workspace name
        status: workspaceDetails.isActive ? 'Active' : 'Inactive', // Workspace status based on isActive field
        expiresOn: workspaceDetails.plan[0].activeFrom || '-', // Expiration date
        workspace_id: workspaceDetails.id, // Workspace ID
        codeTemplate: workspaceDetails.codeTemplate.join(', ') || '-', // Code templates as a comma-separated string
        acronyms: workspaceDetails.acronyms.join(', ') || '-', // Acronyms as a comma-separated string
      },
      creator: {
        firstName: workspaceDetails.createdByUser?.firstName || '-', // Creator first name
        lastName: workspaceDetails.createdByUser?.lastName || '-', // Creator last name
        email: workspaceDetails.createdByUser?.email || '-', // Creator email
      },
      createdAt: workspaceDetails.createdAt ? new Date(workspaceDetails.createdAt).toLocaleString() : '-', // Created date formatted
      projects: workspaceDetails.projects.map((project: any) => project.name).join(', ') || '-', // Projects in the workspace as a comma-separated string
    });
  });
  return table;
}


export function getAssetCounts(dataSource: any) {
  const data = dataSource.map(
    (assetType: {
      assetName: string;
      totalAssetCount: string;
      assetDetails: { count: number }[];
    }) => {
      return {
        totalAssets: {
          assetType: assetType.assetName,
          count: assetType.totalAssetCount,
        },
        assignedAssets: assetType.assetDetails[0].count,
        unassignedAssets: assetType.assetDetails[1].count,
        outForRepair: assetType.assetDetails[2].count,
        inHouseRepair: assetType.assetDetails[3].count
      };
    }
  );

  return data;
}

export function getTicketDetails(ticket: any) {
  const ticketDetails = {
    assetDetails: {
      assetName: ticket.ticketAssets[0]?.assets?.name || '-',
      assetId: ticket.ticketAssets[0]?.assets?.assetCode || '-',
    },
    issueDescription: {
      description: ticket.description || '-',
      title: ticket.title || '-',
      approvalStatus: ticket.approvalStatus,
    },
    assignedTo: {
      id: ticket.assignee?.id,
      name: `${ticket.assignee?.firstName} ${ticket.assignee?.lastName}`
    },
    ticketRaisedBy: ticket.users || null,
    ticketClosureFile: ticket.ticketClosureFile || null,
    ticketClosedBy: ticket.ticketClosedBy ? {
        id: ticket.ticketClosedBy.id,
        firstName: ticket.ticketClosedBy.firstName,
        lastName : ticket.ticketClosedBy.lastName
    } : null,
    ticketDetails: {
      isExternalUser: ticket.isExternalUser || false,
      externalUserEmail: ticket.externalUserEmail,
      ticketNo: `#${ticket.isExternalUser ? 'Ext - ' : ''}${ticket.ticketNumber || '-'
        }`,
      ticketId: `${ticket.ticketAssets[0]?.ticketId || '-'}`,
      files: ticket.ticket_files || [],
      status: ticket.status || '-',
      ticketSubStatus: ticket.ticketSubStatus || '',
      assignedTo: ticket?.assignee?.id || '',
      comments: ticket.ticketComments,
      type: ticket.type,
      reporter: `${ticket.users?.firstName && ticket.users?.lastName
        ? ticket.users?.firstName + ' ' + ticket.users?.lastName
        : ticket.externalUserName || '-'
        }`,
      designation: ticket.users?.employeeDesignation.attributeValue || '-',
      userId: ticket.userId || null,
      userLocation: ticket.location || null,
      userEmail: ticket.users?.email || null,
      reporterEmployeeId: ticket.users?.employeeId || '-',
      ticketRaisedDate: new DatePipe('en-us').transform(
        ticket.createdAt,
        'dd/MM/yyyy'
      ),
      ticketRaisedTime: new DatePipe('en-us').transform(ticket.createdAt, 'hh:mm a'),
      workspaceId: ticket?.workspaceId
    },
    assetTypes: {
      name: ticket.assetTypes?.name || null,
      assetTypeCode: ticket.assetTypes?.assetTypeCode || null,
      id:ticket.assetTypes?.id || null,
    },
    newAsset: ticket.newAsset ? { ...ticket.newAsset } : null,
    ticketSubcategories: [...ticket?.ticket_sub_categories],
    assetId: ticket.ticketAssets[0]?.assetId,
    ticketPriority: {
      priorityId: ticket?.ticket_priorities?.id,
      priorityName: ticket?.ticket_priorities?.name,
      timeStamp: ticket?.ticket_priorities?.durationInHours,
      priorityChangeCount: ticket?.priorityChangeCount
    },
    currentRecord: ticket?.ticketAssets[0]?.assets?.asset_allocations[0]?.currentRecord
  };

  return ticketDetails as ITicketDetails;
}

export function getAddAssetData(addAssetData: any) {
  const data = {
    assetCode: addAssetData.assetCode || null,
    type: addAssetData.type || null,
    modelName: addAssetData.modelName || null,
    location: addAssetData.location || null,
    purchaseDate: addAssetData.purchaseDate || null,
    vendorName: addAssetData.vendorName || null,
    warranty: addAssetData.warranty || null,
    ram: addAssetData.laptopFields?.ram || null || addAssetData.mobileFields?.ram,
    diskSpace: addAssetData.laptopFields?.diskSpace || null,
    processor: addAssetData.laptopFields?.processor || null,
    clientId:addAssetData.clientId || null,
    projectId: addAssetData.projectId || null ,
    serialNo:
      addAssetData.laptopFields?.serialNo ||
      addAssetData.headphoneFields?.serialNo ||
      addAssetData.mobileFields?.serialNo ||
      addAssetData.monitorFields?.serialNo ||
      null,
    operatingSystem: addAssetData.laptopFields?.operatingSystem || null,
    screenSize:
      addAssetData.laptopFields?.screenSize ||
      addAssetData.monitorFields?.screenSize ||
      null,
    endPointSecurityId: addAssetData.laptopFields?.endPointSecurityId || null,
    screenSizeUnit:
      addAssetData.laptopFields?.screenSizeUnit ||
      addAssetData.monitorFields?.screenSizeUnit ||
      null,
    diskSpaceUnit: addAssetData.laptopFields?.diskSpaceUnit || null,
    ramUnit: addAssetData.laptopFields?.ramUnit || null || addAssetData.mobileFields?.ramUnit,
  };

  return data;
}

export const closeDropdown = new Subject<boolean>();

export const paginationChange = new Subject<boolean>();

export function convertToTitleCase(data: string) {
  return data
    .toLowerCase()
    .replace(/[-_]/g, " ")
    .replace(/\b\w/g, c => c.toUpperCase());
}

export function convertToCapitalCase(data: string) {
  return data
    .toUpperCase()
    .replace(" ", "-")
}
