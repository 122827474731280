<div class="asset-team-container">
  <app-back-button></app-back-button>
  <div class="wrapper-container">
    <div class="asset-container" [ngClass]="{ loader: loaderView || hasError }">
      <app-loader *ngIf="loaderView"></app-loader>
      <h1 *ngIf="hasError" class="asset-not-found">Workspace Not Found</h1>
      <div>
        <div class="head" *ngIf="!hasError && !loaderView">
          <h1 class="heading">{{ pageTitle }}</h1>
        </div>
      </div>
      <div class="button">
        <app-button class="spacing" type="submit" size="medium" theme="primary"
          >Create
        </app-button>
      </div>
    </div>
  </div>
</div>
