import { environment } from 'src/environments/environment';

export const base_url = environment.STESSA_API_URL;
export const user_profile = '/user/get-current-user';
export const user_asset = '/asset-allocation/user/Id';
export const raise_issue = '/ticket/user-raise-ticket';
export const user_table_data = '/ticket/user';
export const user_tickets = '/ticket';
export const assets = '/asset';
export const ticket_status = '/ticket/dashboard-ticket-count';
export const asset_status = '/asset/inventory-insights';
export const asset_team_table_data = '/ticket';
export const filteredData = '/';
export const asset_details = '/asset';
export const check_in_check_out = '/checkIn-or-checkOut';
export const get_user_by_email = '/user/get-user-by-email';
// New Changes
export const getAllTicketsForAssetTeam = `${base_url}/ticket`;
export const getAllTicketsForApprovalTeam = `${base_url}/approval`;
export const getAllTicketsForUser = `${base_url}/ticket/userTickets`;
export const getAllTicketsForUserApproval = `${base_url}/approval/userTickets`;
export const addComment = `${base_url}/ticket-comment`;
export const getAdmins = `${base_url}/user-role/role/ADMIN`;
export const getAllAdmins = `${base_url}/user/allUser?role=ADMIN`;
export const raiseTicket = `${base_url}/ticket/public-raise-ticket`;
export const getAllWorkspaces = `${base_url}/workspace`;
export const getAllAcronyms = `${base_url}/workspace/acronyms`;
export const getAllWorkspaceOwner = `${base_url}/workspace`;
export const getLocations = `${base_url}/workspace-attribute?attributeName=location`;
export const getAllOwnersProject = `${base_url}/client-project/project`;
export const getAllOwnersVendor = `${base_url}/vendor`;

 export const getWorkspaceUsers = `${base_url}/users/v2`;

export const markTicketAsResolved = `${base_url}/ticket/update/ticket-status`;
export const updateTicketAssisgnee = `${base_url}/ticket/update/ticket-assignee`;

export const getAllComments = `${base_url}/ticket-comment/ticketId`;
// export const getUserById = `${base_url}/user-role/user/userId`;
export const getUserById = `${base_url}/user/userId`;
export const getAssetLocations = `${base_url}/public/get-all-ticket-locations`;
export const updateAssetLocation = `${base_url}/asset/update-location`;
export const sendCustodayVerification = `${base_url}/asset-allocation/sendVerificationLink`;

export const getAssetListCountForEkoDevices = `${base_url}/asset/total-dashboard-count`;

export const workspaceAttributes = `${base_url}/workspace-attribute?`;
