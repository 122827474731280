import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-generic-drop-down',
  templateUrl: './generic-drop-down.component.html',
  styleUrls: ['./generic-drop-down.component.scss'],
})
export class GenericDropDownComponent {
  @Output() value = new EventEmitter<string>();
  @Output() index = new EventEmitter<number>();
  @Input() label?= '';
  @Input() options: any;
  @Input() title!: string;
  @Input() titleName: string = '';
  @Input() valueName!: string;
  @Input() captionItem!: string;
  @Input() showDropContainerTop = false;
  @Input() disabled = false;
  @Input() hasCaption: boolean = false;
  showDropDownOptions: boolean = false;
  icon = '';
  @Input() shouldEmitdisplayValue: boolean = false;
  @Input() shouldEmitIndex: boolean = false;
  @Input() shouldEmitEmittedValue: boolean = false;
  @Input() shouldEmitOption: boolean = false;
  @Input() shouldEmitDisplayValueAndEmittedValue!: boolean;
  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void { }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    clickedInside
      ? (this.showDropDownOptions = !this.showDropDownOptions)
      : (this.showDropDownOptions = false);
    this.showOptions();
  }

  showOptions(): void {
    if (this.disabled) {
      this.showDropDownOptions = false;
      return;
    }
  }

  getOption(
    displayValue: string,
    index: number,
    emitValue: string,
    option: string
  ): void {
    displayValue === undefined
      ? (this.title = option)
      : (this.title = displayValue);
    this.showDropDownOptions = false;
    if (this.shouldEmitdisplayValue) {
      this.value.emit(displayValue); //value to be displayed in the title and options
    }
    if (this.shouldEmitOption) {
      this.value.emit(option); //when only  array of strings is passed
    }
    if (this.shouldEmitIndex) {
      this.index.emit(index); //index of the object that has been selected from array
    }
    if (this.shouldEmitEmittedValue) {
      this.value.emit(emitValue); //the value of the option
    }
    if (this.shouldEmitDisplayValueAndEmittedValue) {
      this.value.emit(displayValue + '  ' + emitValue);
    }
  }
}