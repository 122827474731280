<div class="asset-team-container">
  <app-alert-box
    [config]="snackBarConfig"
    *ngIf="alertBox && snackBarConfig"
    (close)="alertBox = false"
  ></app-alert-box>
  <app-back-button
    [backRouteLink]="{ path: '/home/inventory', params: historyConfig }"
  ></app-back-button>
  <div class="outer-container">
    <div class="asset-container">
      <!-- Asset Details Section Start -->
      <div class="asset-details-container">
        <app-loader
          class="loader"
          *ngIf="loaderView; else assetDetailsSection"
        ></app-loader>
        <ng-template #assetDetailsSection>
          <div *ngIf="assetDetails; else assetNotFoundSection">
            <section class="asset-primary-details">
              <div class="left-column">
                <div class="barcode">
                  <qrcode
                    [qrdata]="assetDetails.asset.assetCode || 'No-data-found'"
                    [width]="100"
                    [margin]="0"
                    [errorCorrectionLevel]="'M'"
                    [allowEmptyString]="true"
                  ></qrcode>
                </div>
                <div class="asset-name-id">
                  <div class="asset-name">
                    {{ assetDetails.asset.name | titlecase }}
                  </div>
                  <div class="asset-type">
                    {{ assetDetails.asset.asset_type.name | titlecase }}
                  </div>
                  <div class="asset-id">
                    {{ assetDetails.asset.assetCode }}
                  </div>
                </div>
              </div>
              <div class="asset-availability" *ngIf="assetStatus">
                <app-chip [status]="assetStatus"></app-chip>
              </div>
            </section>
            <section class="asset-related-buttons">
              <ng-container
                *stessaHasPermission="'INVENTORY__ASSET_DETAILS__EDIT_BUTTON'"
              >
                <app-button
                  class="asset-edit-disable-button"
                  size="larger"
                  theme="tertiary"
                  (click)="
                    assetStatus === ASSET_STATUS.MARK_AS_EWASTE ||
                    assetStatus === ASSET_STATUS.NOT_IN_USE ||
                    isOutForRepair
                      ? false
                      : editAsset()
                  "
                  [disabled]="
                    assetStatus === ASSET_STATUS.MARK_AS_EWASTE ||
                    assetStatus === ASSET_STATUS.NOT_IN_USE ||
                    isOutForRepair
                  "
                >
                  <span>
                    <img
                      [src]="editButtonImage"
                      class="disable-edit-button-image"
                    />
                  </span>
                  <span class="disable-button-text">Edit</span>
                </app-button>
              </ng-container>

              <ng-container
                *stessaHasPermission="
                  'INVENTORY__ASSET_DETAILS__ENABLE_DISABLE_BUTTON'
                "
              >
                <app-button
                  class="asset-edit-disable-button"
                  size="larger"
                  theme="tertiary"
                  (click)="disableProperty ? false : onDisable()"
                  [disabled]="disableProperty"
                  [tooltip]="disableMessage"
                >
                  <span>
                    <img
                      [src]="disableButtonImage"
                      class="disable-edit-button-image"
                    />
                  </span>
                  <span class="disable-button-text">{{
                    disableButtonText
                  }}</span>
                </app-button>
              </ng-container>
            </section>
            <section class="asset-info">
              <ng-container *ngIf="isOutForRepair || isInHouseRepair">
                <div class="asset-details-headings">Issue Details</div>

                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span class="employee-name">
                      {{ isInHouseRepair ? "Assignee" : "Vendor" }} </span
                    ><span class="colon">:</span>
                  </div>
                  <span
                    class="asset-attribute-value"
                    [ngClass]="{ assignee: assigneeName }"
                    *ngIf="isInHouseRepair"
                    (click)="navigateToProfile(assigneeId)"
                  >
                    {{ assigneeName || "Not Available" }}
                  </span>
                  <span
                    class="asset-attribute-value"
                    *ngIf="isOutForRepair && !isInHouseRepair"
                  >
                    {{ vendorName || "Not Available" }}
                  </span>
                </div>
                <div class="description">
                  <div class="description-title">
                    <span>Type</span>
                  </div>
                  <div>
                    <span class="colon">:</span>
                  </div>
                  <div class="description-content">
                    <p class="issues">
                      {{ getSubCategories() || "Not Available" }}
                    </p>
                  </div>
                </div>

                <div class="description">
                  <div class="description-title">
                    <span>Description</span>
                  </div>
                  <div>
                    <span class="colon">:</span>
                  </div>
                  <div class="description-content">
                    <p class="issues">
                      {{
                        assetDetails.asset.asset_allocations[0]
                          .transactionReason || "Not Available"
                      }}
                    </p>
                    <ng-container
                      *stessaHasPermission="
                        'INVENTORY__ASSET_DETAILS__CHECKIN_ASSET'
                      "
                    >
                      <div
                        *ngIf="assetStatus !== 'NOT_IN_USE'"
                        class="checkin-link"
                        (click)="checkInAsset()"
                      >
                        <a>Check In Asset</a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isInHouseRepair && !isOutForRepair">
                <div class="asset-details-headings">Basic Summary</div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Added By</span><span class="colon">:</span>
                  </div>
                  <div class="col">
                    <p
                      *ngIf="
                        createdBy.firstName &&
                          createdBy.lastName &&
                          createdBy.id;
                        else userNotAvailable
                      "
                    >
                      <a
                        routerLink="/home/employee-details/{{ createdBy.id }}"
                        >{{ createdBy.firstName }} {{ createdBy.lastName }}</a
                      >
                    </p>
                    <ng-template #userNotAvailable>
                      <p>Not Available</p>
                    </ng-template>
                  </div>
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Added On</span><span class="colon">:</span>
                  </div>
                  <p class="asset-attribute-value">
                    {{ formattedDate || "Not Available"
                    }}<span class="time"> | {{ formattedTime }}</span>
                  </p>
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Purchase Date</span><span class="colon">:</span>
                  </div>
                  <div class="asset-attribute-value">
                    {{ purchaseDate || "Not Available" }}
                  </div>
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Owned By</span><span class="colon">:</span>
                  </div>
                  <div class="asset-attribute-value">
                    {{ assetDetails.asset.ownedByClient }}
                  </div>
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Managed By</span><span class="colon">:</span>
                  </div>
                  <div class="asset-attribute-value">
                    {{ assetDetails.asset.managedByClient }}
                  </div>
                </div>
                <div class="asset-details" *ngIf="assetStatus === 'ALLOCATED'">
                  <div class="asset-attribute-name">
                    <span>Allocated To Project</span><span class="colon">:</span>
                  </div>
                  <div class="asset-attribute-value">
                    {{ assetDetails.asset.asset_allocations[0].project.name }}
                  </div>
                </div>
              </ng-container>

              <div
                *ngFor="let category of mappedCategories"
              >
                <div class="asset-details-headings" *ngIf="category.mappedProperties.length > 0">{{ category.displayName }}</div>
                <div class="asset-details" *ngFor="let property of category.mappedProperties">
                  <div class="asset-attribute-name">
                    <span>{{ property.propertyDisplayName }}</span
                    ><span class="colon">:</span>
                  </div>
                  <span class="asset-attribute-value" *ngIf="property.value; else notAvailable">{{ property.value }} {{ property.unit }} </span>
                </div>
                <ng-template #notAvailable>
                  <span class="asset-attribute-value">Not Available</span>
                </ng-template>
              </div>

              <ng-container
                *ngIf="isCheckedOut && !isOutForRepair && !isInHouseRepair"
              >
                <div class="asset-details-headings">Vendor Details</div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Vendor Name</span
                    ><span class="colon">:</span>
                  </div>
                  <span class="asset-attribute-value">
                    {{ assetDetails.asset.vendor?.name || "Not Available" }}
                  </span>
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Vendor Email</span><span class="colon">:</span>
                  </div>
                  <span class="asset-attribute-value">
                    {{ assetDetails.asset.vendor?.email || "Not Available" }}
                  </span>
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Vendor Phone Number</span><span class="colon">:</span>
                  </div>
                  <span class="asset-attribute-value">
                    {{ assetDetails.asset.vendor?.phoneNumber || "Not Available" }}
                  </span>
                </div>
              </ng-container>

              <ng-container
                *ngIf="isCheckedOut && !isOutForRepair && !isInHouseRepair"
              >
                <div class="asset-details-headings">Loanee Details</div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Employee Name</span
                    ><span class="colon">:</span>
                  </div>
                  <span class="asset-attribute-value"
                    ><a (click)="navigateToEmployeeDetails(assetDetails.userId)"
                      >{{ assetDetails.asset.asset_allocations[0].user?.firstName }}
                      {{ assetDetails.asset.asset_allocations[0].user?.lastName }}</a
                    ></span
                  >
                </div>
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Designation</span><span class="colon">:</span>
                  </div>
                  <div class="resend-link">
                    <span class="asset-attribute-value">
                      {{ assetDetails.asset.asset_allocations[0].user?.employeeDesignation?.attributeValue }}
                    </span>
                    <div
                      *stessaHasPermission="
                        'INVENTORY__ASSET_DETAILS__CHECKIN_ASSET'
                      "
                      class="checkin-link"
                      (click)="checkInAsset()"
                    >
                      <a>Check In Asset</a>
                    </div>
                  </div>
                </div>
              </ng-container>
              

                <span class="asset-details-headings"
                  >Asset Allocation Status</span
                >
                <div class="asset-details">
                  <div class="asset-attribute-name">
                    <span>Custody Verification</span
                    ><span class="colon">:</span>
                  </div>
                  <div class="resend-link">
                    <span class="asset-attribute-value" *ngIf="!isCheckedOut"
                      >Unassigned</span
                    >
                    <ng-container *ngIf="isCheckedOut">
                      <ng-container
                        *ngIf="
                          assetDetails.asset.asset_allocations[0]?.custodyVerified ===
                          CUSTODY_VERIFICATION_STATUS.PENDING
                        "
                      >
                        <span class="pending">Pending</span>
                      </ng-container>
                      <span
                        *ngIf="
                          assetDetails.custodyVerified ===
                          CUSTODY_VERIFICATION_STATUS.VERIFIED
                        "
                        class="verified"
                        >Verified</span
                      >
                    </ng-container>
                    <div
                      class="custody-verify-btn"
                      *ngIf="
                        isCheckedOut &&
                        assetDetails.asset.asset_allocations[0]?.custodyVerified ===
                          CUSTODY_VERIFICATION_STATUS.PENDING
                      "
                      (click)="sendCustodayVerification(assetDetails.asset.asset_allocations[0].user?.id!)"
                    >
                      <a *stessaHasPermission="'GET_SEND_VERIFICATION_LINK'">
                        Resend Request</a
                      >
                    </div>
                  </div>
                </div>

              <div
                *ngIf="
                  !isCheckedOut &&
                  !isOutForRepair &&
                  assetStatus !== 'NOT_IN_USE' &&
                  assetStatus !== 'MARKED_AS_E_WASTE' &&
                  !isInHouseRepair
                "
                class="hr"
              ></div>
              <ng-container
                *stessaHasPermission="
                  'INVENTORY__ASSET_DETAILS__CHECKOUT_ASSET'
                "
              >
                <ng-container
                  *ngIf="
                    !isCheckedOut &&
                    !isOutForRepair &&
                    assetStatus !== 'NOT_IN_USE' &&
                    assetStatus !== 'MARKED_AS_E_WASTE' &&
                    !isInHouseRepair
                  "
                >
                  <h3 class="checkout-heading">Check Out</h3>
                  <div>
                    <div class="select-lonee-text">Select Loanee</div>
                    <div class="employee-email-input">
                      <div class="form-container">
                        <input
                          [ngClass]="{
                            'error-border': loaneeVerifyStatus === 'error'
                          }"
                          type="email"
                          [formControl]="formControl"
                          (keypress)="onSearch($event)"
                          [value]="value"
                          placeholder="Enter employee email ID"
                        />
                        <div [ngClass]="!visibility ? 'close' : 'open'">
                          <div class="autocomplete-panel">
                            <div
                              *ngFor="
                                let result of filters$ | async;
                                let idx = index
                              "
                              #options
                            >
                              <div
                                [ngClass]="
                                  idx === tabIndex ? 'option active' : 'option'
                                "
                                (click)="onSelect(result)"
                                *ngIf="
                                  result.firstName &&
                                  result.lastName &&
                                  result.email
                                "
                                #option
                                [scrollTop]="
                                  idx === tabIndex
                                    ? option.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start'
                                      })
                                    : 0
                                "
                              >
                                <div>
                                  <span class="name">{{
                                    result.firstName + " " + result.lastName
                                      | titlecase
                                  }}</span>
                                  <small class="email">{{
                                    result.email.length > 25
                                      ? (result.email | slice : 0 : 25) + "..."
                                      : result.email
                                  }}</small>
                                </div>
                                <img
                                  *ngIf="idx === tabIndex"
                                  src="assets/12x12/check.svg"
                                  alt=""
                                  srcset=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <img
                          *ngIf="loaneeVerifyStatus === 'success'"
                          [src]="iconStateData[loaneeVerifyStatus]"
                          alt="email-verfiy-icon"
                          class="p-absolute icon"
                          width="16"
                        />
                        <button
                          type="submit"
                          *ngIf="loaneeVerifyStatus !== 'success'"
                          (click)="verify()"
                          [disabled]="formControl.invalid"
                          class="button p-absolute verify-btn"
                        >
                          Verify
                        </button>
                      </div>
                      <p
                        class="incorrect-error-note"
                        *ngIf="loaneeVerifyStatus === 'error'"
                      >
                        Enter correct email.
                      </p>

                      <div>
                        <div class="dropdown">
                          <span class="label select-lonee-text">Select Project</span>
                          <app-generic-drop-down
                            [options]="projects"
                            (value)="onSelectProject($event)"
                            selectedValue=""
                            [titleName]="'name'"
                            [valueName]="'id'"
                            [title]="selectedValue || 'Select Project'"
                            [shouldEmitEmittedValue]="true"
                          >
                          </app-generic-drop-down>
                        </div>
                      </div>

                      <div class="reason-container">
                        <label class="label select-lonee-text" >Reason *</label>
                        <app-textarea
                          class="text-area"
                          cols="30"
                          rows="10"
                          [formControl]="textArea"
                          characterCounter
                          maxLength="255"
                          (countEvent)="displayCount($event)"
                          [countData]="countData"
                          [placeholder]="'Enter your reason here...'"
                          [isRequired]="true"
                          [isLabelPresent]="false"
                        >
                        </app-textarea>
                        <!-- <div *ngIf="textArea.hasError('required')" class="error-message">This field is required</div> -->

                      </div>
                      
                    </div>

                    <ng-container
                      *ngIf="
                        isCheckedOut &&
                        assetStatus !== 'NOT_IN_USE' &&
                        assetStatus !== 'MARKED_AS_E_WASTE'
                      "
                    >
                      <div class="asset-allocation-text">
                        <span>Asset Allocation Status</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isEmailVerified && textArea.valid">
                      <div class="employee-info">
                        <div>
                          <span>Employee Name</span><span>:</span
                          ><span class="employee-name"
                            ><a
                              (click)="
                                navigateToEmployeeDetails(employeeDetails.id)
                              "
                              >{{ employeeDetails.firstName }}
                              {{ employeeDetails.lastName }}</a
                            ></span
                          >
                        </div>
                        <div>
                          <span>Designation</span><span>:</span
                          ><span class="employee-designation">{{
                            employeeDetails.designation
                          }}</span>
                        </div>
                      </div>
                      <ng-container *ngIf="isCheckedOut">
                        <div class="checked-out">
                          <span>Checked Out</span>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!isCheckedOut">
                      <div class="cancel-checkout-div">
                        <app-button
                          class="cancel-button"
                          size="xsmall"
                          theme="tertiary"
                          [disabled]="!(isEmailVerified && textArea.valid)"
                          (click)="onCancel()"
                          >Cancel</app-button
                        >
                        <app-button
                          class="checkout-button"
                          size="xsmall"
                          theme="primary"
                          [disabled]="!(isEmailVerified && textArea.valid)"
                          (click)="checkedOut()"
                        >
                         Check Out</app-button
                        >
                      </div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="isCheckedOut">
                    <div class="asset-allocation-text">
                      <span>Asset Allocation Status</span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <div
                *ngIf="
                  !isCheckedOut &&
                  !isOutForRepair &&
                  assetStatus !== 'NOT_IN_USE' &&
                  assetStatus !== 'MARKED_AS_E_WASTE' &&
                  !isInHouseRepair
                "
                class="hr"
              ></div>

              <div
                *ngIf="
                  !isCheckedOut &&
                  !isOutForRepair &&
                  assetStatus !== 'NOT_IN_USE' &&
                  assetStatus !== 'MARKED_AS_E_WASTE' &&
                  !isInHouseRepair
                "
              >
                <ng-container
                  *stessaHasPermission="
                    'INVENTORY__ASSET_DETAILS__CHECKOUT_FOR_REPAIR'
                  "
                >
                  <p class="asset-details-headings">
                    Having issues with asset ?
                  </p>

                  <p class="subtitle">
                    Check Out for repair or maintenance.
                    <span (click)="triggerModal('out-for-repair-modal')">
                      <a> Send to repair</a>
                    </span>
                  </p>
                </ng-container>
              </div>
            </section>
          </div>
          <ng-template #assetNotFoundSection>
            <div class="asset-not-loaded-section">
              <p>Something went wrong, try later!</p>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <!-- Asset Details Section End -->

      <!-- Asset History Section Start -->
      <div
        class="asset-history"
        *stessaHasPermission="'INVENTORY__ASSET_DETAILS__ASSET_HISTORY'"
      >
        <app-asset-history
          [assetId]="assetId"
          [historyUpdated]="isAssetHistoryUpdated"
        ></app-asset-history>
      </div>
      <!-- Asset History Section End -->
    </div>
  </div>
</div>
