<main class="split-screen">
  <div class="loader-container" *ngIf="loaderView">
    <img src="assets/32x32/coditas.gif" class="loader-logo" loading="lazy" />
  </div>
  <section class="left">
    <div class="image">
      <img
        src="assets/32x32/stessa-logo.svg"
        height="48"
        class="image"
        alt="stessa-logo"
      />
    </div>
    <div class="headings">
      <p class="signin-heading">Welcome to our</p>
      <p class="signin-heading">Assets Tracking Platform</p>
    </div>
    <div class="input-section">
      <div class="email-section">
        <input
          type="email"
          placeholder="Enter email ID"
          [(ngModel)]="email"
          required
          (keydown.enter)="onEnterKey()"
        />

        <!-- Display error message below email input if there is an error -->
        <p *ngIf="emailError" class="error-message">{{ emailError }}</p>
      </div>

      <!-- Conditionally show password input based on email verification -->
      <!-- Password input with show/hide functionality -->
       <div  class="password-section">
        <div

        *ngIf="isEmailVerified"
        style="position: relative"
      >
        <input
          [type]="showPassword ? 'text' : 'password'"
          placeholder="Enter Password"
          [(ngModel)]="password"
          required
          (keydown.enter)="onEnterKey()"
        />
        <!-- Eye icon inside input, positioned to the right -->

        <img
          [src]="
            showPassword
              ? 'assets/14x14/icon-openeye.svg'
              : 'assets/14x14/Iconclosed-icon.svg'
          "
          class="eye-icon"
          (click)="togglePasswordVisibility()"
          style="
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          "
        />


      </div>
      <p *ngIf="passError" class="error-message">{{ passError }}</p>
       </div>


      <div class="continue-button">
        <app-button
          class="spacing"
          type="button"
          size="medium"
          theme="primary"
          (click)="isEmailVerified ? continue() : verifyEmail()"
        >
          {{ isEmailVerified ? "Sign In" : "Continue" }}
        </app-button>
      </div>
    </div>
    <div class="divider">
      <span>OR</span>
    </div>

    <p class="signin-subheading">Sign in using your Coditas G-Suite account:</p>

    <div class="button">
      <div id="g_id_signin" class="g_id_signin"></div>
    </div>
    <div class="subheading">
      <p class="subheading-note">Having trouble logging in?</p>
      <a class="button-text" routerLink="../contact-admin"> Contact Admin </a>
    </div>
    <div class="footer">
      <span class="footer-text">© 2023 Coditas. All rights reserved.</span>
      <div class="privacy-policy-text">
        <span routerLink="../terms-and-conditions">Terms and conditions</span>
        <span class="pipe">|</span>
        <span routerLink="../privacy-policy">Privacy Policy</span>
      </div>
    </div>
  </section>
  <section class="right"></section>
</main>
