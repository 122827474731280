import { DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  DOWNLOAD_LIST_OPTIONS,
  DOWNLOAD_LIST_TYPES,
  TEMPLATE_LIST_TYPES,
} from 'libs/common/src/lib/constants/constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bulk-download-list',
  templateUrl: './bulk-download-list.component.html',
  styleUrls: ['./bulk-download-list.component.scss'],
})
export class BulkDownloadListComponent implements OnInit {
  constructor(
    private commonHttpService: CommonHttpService,
    private dialogRef: DialogRef,
    private toasterService: ToastrService
  ) {}

  ngOnInit(): void {}

  @Output() closeModal = new EventEmitter();
  @Output() alertBoxEmitter = new EventEmitter();

  popupForm = new UntypedFormGroup({
    list_type_options: new UntypedFormControl('', Validators.required),
    list_category_options: new UntypedFormControl('', Validators.required),
  });

  categoryOptions = DOWNLOAD_LIST_OPTIONS;
  listTypeOptions = DOWNLOAD_LIST_TYPES;
  listCategoryOptions = DOWNLOAD_LIST_OPTIONS;
  showRemoveFileBtn = true;
  progressInfos: any[] = [];
  uploadFailed = false;
  isLoading = false;

  private downloadDatabaseFile(url: string, urlOf: string) {
    const databaseFileDownloadEl = document.createElement('a');
    databaseFileDownloadEl.href = url;
    databaseFileDownloadEl.target = '_blank';
    databaseFileDownloadEl.download = `${urlOf}-${new Date().getTime()}.xlsx`;
    databaseFileDownloadEl.click();
  }

  getValue(selectedValue: string, type?: string) {
    if (type === 'list_type_options') {
      this.popupForm.get('list_category_options')?.setValue('');
      this.categoryOptions = this.listCategoryOptions.filter(
        (value) => value.fileType === selectedValue || value.fileType === 'all'
      );
    }
    this.popupForm.get(type!)?.setValue(selectedValue);
  }

  closeDialog() {
    this.popupForm.reset();
    this.dialogRef.removePanelClass('download-upload-dialog');
    this.dialogRef.addPanelClass('close-upload-download-dialog');
    const current = this;
    setTimeout(() => {
      current.dialogRef.close();
    }, 1000);
  }

  // Mapping download functions to avoid switch-case
  databaseDownloadFunctions: Record<string, any> = {
    asset_list: () => this.commonHttpService.downloadAssetListDatabaseFile(),
    asset_allocation_list: () =>
      this.commonHttpService.downloadAssetAllocationListDatabaseFile(),
    user_list: () => this.commonHttpService.downloadUserListDatabaseFile(),
  };

  templateDownloadFunctions: Record<string, () => Observable<any>> = {
    user_list: () => this.commonHttpService.downloadUserListTemplateFile(),
    asset_list: () =>this.commonHttpService.downloadAssetsFileTemplate(TEMPLATE_LIST_TYPES.BULK_ADD_ASSETS),
    asset_allocation_list: () =>this.commonHttpService.downloadAssetsFileTemplate(TEMPLATE_LIST_TYPES.BULK_ASSET_ALLOCATION),
    asset_out_for_repair: () =>this.commonHttpService.downloadAssetsFileTemplate(TEMPLATE_LIST_TYPES.BULK_ASSET_OUT_FOR_REPAIR),
    asset_update_list: () =>this.commonHttpService.downloadAssetsFileTemplate(TEMPLATE_LIST_TYPES.BULK_UPDATE_ASSETS),
    mark_asset_e_waste: () =>this.commonHttpService.downloadAssetsFileTemplate(TEMPLATE_LIST_TYPES.BULK_MARK_ASSET_AS_EWASTE),
  };
  
  onSubmit() {
    this.isLoading = true;

    const { list_type_options, list_category_options } = this.popupForm.value;
    const fileName = `${list_category_options}-${list_type_options}`;

    const downloadFunction =
      list_type_options === 'database_file'
        ? this.databaseDownloadFunctions[list_category_options]
        : this.templateDownloadFunctions[list_category_options];
        
    if (downloadFunction) {
      this.downloadAndClose(fileName, downloadFunction);
    } else {
      this.isLoading = false;
    }
  }

  downloadAndClose(fileName: string, downloadFunction: () => Observable<any>) {
    downloadFunction().subscribe({
      next: (response: any) => {
        this.isLoading = false;
        const fileUrl = this.getFileUrlFromResponse(response);
        this.downloadDatabaseFile(fileUrl, fileName);
        this.closeDialog();
      },
      error: (error: HttpErrorResponse) => {
        this.toasterService.error(
          `${fileName} file could not be downloaded, try later.`,
          'Download failed!',
          { toastClass: 'toaster-wrapper' }
        );
        this.isLoading = false;
      },
    });
  }

  getFileUrlFromResponse(response: {
    downloadUrl?: string;
    data?: string;
  }): string {
    let downloadUrl = response.data || response.downloadUrl;
    const isHttps = downloadUrl?.startsWith('https://');
    if (!isHttps) {
      downloadUrl = `https:${downloadUrl?.split(':')[1]}`;
    }
    return downloadUrl!;
  }

  getCategoryOptionById(categoryId: string) {
    const matchingOption = this.listCategoryOptions.find(
      (option) => option.id === categoryId
    );
    return matchingOption ? matchingOption.title : null;
  }
}
