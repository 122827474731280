import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UiComponentsModule } from "@shared/ui-components";
import { DashboardModule } from "../dashboard.module";
import { ManageWorkspaceOwnerComponent } from "./manage-workspace-owner/manage-workspace-owner.component";
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule } from '@angular/forms';
import { ProjectSearchComponent } from './project-search/project-search.component';
import { UserSearchComponent } from './user-search/user-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AddAssetTypeComponent } from './add-asset-type/add-asset-type.component';
import { AssetTypeDetailComponent } from './asset-type-detail/asset-type-detail.component';
import { AccessoryTypeDetailComponent } from "./accessory-type-detail/accessory-type-detail.component";
import { AddAccessoryTypeComponent } from "./add-accessory-type/add-accessory-type.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
@NgModule({
  declarations: [ManageWorkspaceOwnerComponent, ProjectSearchComponent, UserSearchComponent, AddAssetTypeComponent, AssetTypeDetailComponent, AddAccessoryTypeComponent, AccessoryTypeDetailComponent, UserDetailsComponent],
  imports: [UiComponentsModule, DashboardModule, RouterModule, CommonModule, CdkTableModule, FormsModule, ReactiveFormsModule],
  providers: [],
  exports: [ManageWorkspaceOwnerComponent],
})
export class WorkspaceOwnerModule {}
