import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import {
  IAssetStatus,
  IClientDetails,
  IFileInfo,
  IMetricsData,
  IOperatingSystem,
  IProcessor,
  IProjectDetails,
  IRaiseAnIssueFormData,
  ISecurityOptions,
  ISubCategory,
  IUpdateAssetResponse,
  IVendors,
  IVerificationParams,
  IWarranty,
} from '../interfaces/interface';
import * as Urls from '../urls/urls';

@Injectable({
  providedIn: 'root',
})
export class CommonHttpService {
  constructor(private http: HttpClient) {}

  isEkoAdmin() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    return userInfo.roles[0] === 'EKO-ADMIN';
  }

  private uploadFileToPresignedUrl(url: string, file: File): Promise<any> {
    return fetch(url, {
      method: 'PUT',
      body: file,
    });
  }

  getUserProfile() {
    return this.http.get(`${Urls.base_url}${Urls.user_profile}`);
  }

  getUserAsset(issueType?: string) {
    let params = new HttpParams();

    if (issueType) {
      params = params.set('issueType', issueType);
    }

    return this.http.get(
      `${Urls.base_url}${Urls.user_asset}`
    );
  }
  getUserDetailsById(userId: string) {
    return this.http.get(
      `${Urls.base_url}/user/get-user-assets-by-userId?userId=${userId}`
    );
  }
  uploadFiles(data: any) {
    return this.http.post(`${Urls.base_url}/public/upload-files`, data);
  }

  addProjects(projectData: any) {
    return this.http.post(
      `${Urls.base_url}/client-project/project`,
      projectData
    );
  }

  addClients(clientData: any) {
    return this.http.post(`${Urls.base_url}/client-project/client`, clientData);
  }

  getUserById(userId: string) {
    return this.http.get(`${Urls.getUserById}`.replace('userId', userId));
  }

  getPermissions(userId: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + token);
    return this.http.get(
      `${Urls.base_url}/role-permission/getAllPermissions/${userId}`,
      { headers: headers }
    );
  }
  raiseIssue(
    raiseAnIssueFormData: IRaiseAnIssueFormData
  ): Observable<HttpEvent<any>> {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}${Urls.raise_issue}`,
      { ...raiseAnIssueFormData },
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  getTableData() {
    return this.http.get(`${Urls.base_url}${Urls.user_table_data}`);
  }

  getTicketData() {
    return this.http.get(`${Urls.base_url}${Urls.user_tickets}`);
  }

  getTicketStatus() {
    return this.http.get(`${Urls.base_url}${Urls.ticket_status}`);
  }

  getApprovalsCount() {
    return this.http.get(`${Urls.base_url}/approval/count`);
  }

  getAssetStatus() {
    return this.http.get(
      `${Urls.base_url}${Urls.asset_status}`
    );
  }

  getAssetTeamTableData(params?: {
    status?: string;
    searchText?: string;
    pageNumber?: string;
    itemsPerPage?: string;
    searchColumn?: string;
    sortDirection?: string;
    sortColumn?: string;
    assignedTickets?: boolean;
  }) {
    if (params?.assignedTickets) {
      let paramString = `assignedTickets=true&pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
      if (params?.status) paramString += `&ticketStatus=${params?.status}`;
      if (params?.searchColumn && params?.searchText)
        paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
      if (params?.sortColumn && params?.sortDirection)
        paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;
      return this.http.get(`${Urls.getAllTicketsForAssetTeam}?${paramString}`);
    } else {
      let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
      if (params?.status) paramString += `&ticketStatus=${params?.status}`;
      if (params?.searchColumn && params?.searchText)
        paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
      if (params?.sortColumn && params?.sortDirection)
        paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;
      return this.http.get(`${Urls.getAllTicketsForAssetTeam}?${paramString}`);
    }
  }
  getApprovalTeamTableData(params?: {
    status?: string;
    searchText?: string;
    pageNumber?: string;
    itemsPerPage?: string;
    searchColumn?: string;
    sortDirection?: string;
    sortColumn?: string;
    assignedTickets?: boolean;
  }) {
    if (params?.assignedTickets) {
      let paramString = `assignedTickets=true&pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
      if (params?.status) paramString += `&ticketStatus=${params?.status}`;
      if (params?.searchColumn && params?.searchText)
        paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
      if (params?.sortColumn && params?.sortDirection)
        paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;
      return this.http.get(
        `${Urls.getAllTicketsForApprovalTeam}?${paramString}`
      );
    } else {
      let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
      if (params?.status) paramString += `&ticketStatus=${params?.status}`;
      if (params?.searchColumn && params?.searchText)
        paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
      if (params?.sortColumn && params?.sortDirection)
        paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;
      return this.http.get(
        `${Urls.getAllTicketsForApprovalTeam}?${paramString}`
      );
    }
  }
  getAllTicketsForUser(params?: {
    status?: string;
    pageNumber?: string;
    itemsPerPage?: string;
    sortColumn?: string;
    sortDirection?: string;
  }) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    if (params?.status !== '') paramString += `&ticketStatus=${params?.status}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;

    return this.http.get(`${Urls.getAllTicketsForUser}?${paramString}`);
  }

  getAllTicketsForUserApproval(params?: {
    status?: string;
    pageNumber?: string;
    itemsPerPage?: string;
    sortColumn?: string;
    sortDirection?: string;
  }) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    if (params?.status !== '') paramString += `&ticketStatus=${params?.status}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;

    return this.http.get(`${Urls.getAllTicketsForUserApproval}?${paramString}`);
  }

  getTicketsByStatus(params: string) {
    return this.http.get(`${Urls.base_url}${Urls.user_tickets}${params}`).pipe(
      (response) => {
        return response;
      },
      catchError((error) => {
        throw error.error.message;
      })
    );
  }

  getAssetsByStatus(params?: any) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    if (params?.assetStatus)
      paramString += `&assetStatus=${params?.assetStatus}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortColumn=${params?.sortColumn}&sortDirection=${params?.sortDirection}`;

    return this.http
      .get(`${Urls.base_url}${Urls.assets}?${paramString}`)
      .pipe(
        (response) => {
          return response;
        },
        catchError((error) => {
          throw error.error.message;
        })
      );
  }

  getAllWorkSpaces(params?: any) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;

    if (params?.status) paramString += `&status=${params?.status}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortColumn=${params?.sortColumn}&sortDirection=${params?.sortDirection}`;
    return this.http.get(`${Urls.getAllWorkspaces}?${paramString}`).pipe(
      (response) => {
        return response;
      },
      catchError((error) => {
        throw error.error.message;
      })
    );
  }

  getAllWorkspaceCount() {
    return this.http.get(`${Urls.getAllWorkspaces}/count`);
  }

  getWorkspaceDetails(workspaceId: string) {
    return this.http.get(`${Urls.getAllWorkspaces}/${workspaceId}`);
  }

  getAcronyms() {
    return this.http.get(`${Urls.getAllAcronyms}`);
  }

  //workspace-owner
  getWorkspaceOwnnerDetails(workspaceId: any) {
    return this.http.get(`${Urls.getAllWorkspaceOwner}/${workspaceId}`);
  }



  getOwnersProjects(params?: any) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    // if (params?.workspaceId)
    //   paramString += `&workspaceId=${params?.workspaceId}`;
    if (params?.status) paramString += `&status=${params?.status}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortColumn=${params?.sortColumn}&sortDirection=${params?.sortDirection}`;
    return this.http.get(`${Urls.getAllOwnersProject}?${paramString}`).pipe(
      (response) => {
        return response;
      },
      catchError((error) => {
        throw error.error.message;
      })
    );
  }

  getOwnersVendors(params?: any) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    // if (params?.workspaceId)
    //   paramString += `&workspaceId=${params?.workspaceId}`;
    if (params?.status) paramString += `&status=${params?.status}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortColumn=${params?.sortColumn}&sortDirection=${params?.sortDirection}`;
    return this.http.get(`${Urls.getAllOwnersVendor}?${paramString}`).pipe(
      (response) => {
        return response;
      },
      catchError((error) => {
        throw error.error.message;
      })
    );
  }
  getWorkspaceUsers(params?: any) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    // if (params?.workspaceId)
    //   paramString += `&workspaceId=${params?.workspaceId}`;
    if (params?.status) paramString += `&status=${params?.status}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortColumn=${params?.sortColumn}&sortDirection=${params?.sortDirection}`;
    if (params?.roleId) paramString += `&roleId=${params?.roleId}`;
    return this.http.get(`${Urls.getWorkspaceUsers}?${paramString}`).pipe(
      (response) => {
        return response;
      },
      catchError((error) => {
        throw error.error.message;
      })
    );
  }

  getLocations(){
    return this.http.get(`${Urls.getLocations}`);
  }
  addAcronyms(acronymsData: any) {
    return this.http.patch(
      `${Urls.base_url}/workspace`,
      acronymsData
    );
  }
  addAssetTypes(assetData:any){
    return this.http.post(
      `${Urls.base_url}/asset-types/v2`,
        assetData
    );
  }

  addAccessoryTypes(accessoryData: any) {
    return this.http.post(
      `${Urls.base_url}/accessory-type`,
      accessoryData
    )
  }

  addWorkspaceProject(projectData: any) {
    return this.http.post(
      `${Urls.base_url}/client-project/project`,
      projectData
    );
  }
  updateWorkspaceProject(projectId: string, updatedProjectData: any) {
    return this.http.patch(
      `${Urls.base_url}/client-project/project?id=${projectId}`,
      {
        ...updatedProjectData,
      }
    );
  }


  addWorkspaceVendor(vendorData:any){
    return this.http.post(
      `${Urls.base_url}/vendor`,
      vendorData
    );
  }
  submitAssetType(id: string, data: any) {
    return this.http.patch(`${Urls.base_url}/asset-types/v2/${id}`, {
      ...data,
    });
  }

  submitAccessoryType(id: string, data: any) {
    return this.http.patch(`${Urls.base_url}/accessory-type/${id}`, {
      ...data,
    });
  }

  getAssetTypeDetails(id:string) :Observable<any>{
    return this.http.get(`${Urls.base_url}/asset-types/v2/${id}`);
  }

  getAccessoryTypeDetails(id:string){
    return this.http.get(`${Urls.base_url}/accessory-type/${id}`);
  }

  getOneUserDetails(id: string) {
    return this.http.get(`${Urls.base_url}/users/v2/${id}`);
  }

  getAllUserRoles() {
    return this.http.get(`${Urls.base_url}/role`);
  }

  updateUserRole(id: string, role: string) {
    return this.http.patch(`${Urls.base_url}/user-role/v2?userId=${id}`, {roleId: role});
  }

  //asset apis
  getAssetDetails(Asset_id: any) {
    return this.http.get(`${Urls.base_url}${Urls.asset_details}/${Asset_id}`);
  }


  checkOutAsset(AssetData: any) {
    return this.http.post(
      `${Urls.base_url}${Urls.asset_details}${Urls.check_in_check_out}`,
      AssetData
    );
  }

  getTicketDetails(ticketId: string) {
    return this.http.get(`${Urls.base_url}/ticket/${ticketId}`);
  }

  // bulk asset list upload
  bulkAssetListUpload(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}/asset/bulk-add-assets`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  uploadMarkAssetAsEwaste(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}/asset/bulk-mark-asset-ewaste`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  // bulk asset list update upload
  bulkAssetListUpdateUpload(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}/asset/bulk-update-assets`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  // bulk asset out for repair upload
  bulkAssetOutForRepairUpload(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}/asset/bulk-upload-out-for-repair`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  // bulk asset allocation list upload
  bulkAssetAllocationListUpload(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}/asset-allocation/upload-csv`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  // bulk user list upload
  bulkUserListUpload(formData: FormData) {
    const req = new HttpRequest(
      'POST',
      `${Urls.base_url}/user/bulk-upload`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  downloadAssetsMarkedAsEWaste() {
    return this.http.get(
      `${Urls.base_url}/asset/template/bulk-mark-asset-ewaste`
    );
  }

  // download asset-list database file
  downloadAssetListDatabaseFile() {
    return this.http.get(`${Urls.base_url}/asset/download/assetList`);
  }

  // download asset-allocation-list database file
  downloadAssetAllocationListDatabaseFile() {
    return this.http.get(
      `${Urls.base_url}/asset-allocation/download/assetAllocationList`
    );
  }

  // download user-list template file
  downloadUserListDatabaseFile() {
    return this.http.get(`${Urls.base_url}/user/download/userList`);
  }

  // download user-list database file
  downloadUserListTemplateFile() {
    return this.http.get(`${Urls.base_url}/user/template/bulk-upload`);
  }

  // download asset-list template file
  downloadAssetListTemplateFile() {
    return this.http.get(`${Urls.base_url}/asset/template/assetList`);
  }

  // download asset-allocation-list template file
  downloadAssetAllocationListTemplateFile() {
    return this.http.get(
      `${Urls.base_url}/asset-allocation/template/assetAllocationList`
    );
  }

  // download asset-out-for-repair-list template file
  downloadAssetOutForRepairListTemplateFile() {
    return this.http.get(
      `${Urls.base_url}/asset/template/bulk-out-for-repair-asset-list`
    );
  }

  // download asset-update-list template file
  downloadAssetUpdateListTemplateFile() {
    return this.http.get(
      `${Urls.base_url}/asset/template/bulk-update-asset-list`
    );
  }

  downloadAssetsFileTemplate(name:string){
    return this.http.get(`${Urls.base_url}/asset/template?templateName=${name}`)
  }

  verifyEmployee(email: string) {
    return this.http.get(`${Urls.base_url}${Urls.get_user_by_email}/${email}`);
  }

  addComment(commentData: any) {
    return this.http.post(`${Urls.addComment}/`, {
      ...commentData,
    });
  }

  getAllComments(ticketId: string) {
    return this.http.get(
      `${Urls.getAllComments}`.replace('ticketId', ticketId)
    );
  }

  addAssignee() {
    // return this.http.post(`${Urls.addComment}/`, {});
  }

  updateTicketAsResolved(ticketData: any) {
    return this.http.post(`${Urls.markTicketAsResolved}/`, {
      ...ticketData,
    });
  }

  updateTicketAssignee(data: any) {
    return this.http.post(`${Urls.updateTicketAssisgnee}/`, {
      ...data,
    });
  }

  getAssestLocationsList() {
    return this.http.get(`${Urls.workspaceAttributes}attributeName=location`);
  }

  assetLocationUpdate(data: any) {
    return this.http.post(`${Urls.updateAssetLocation}/`, {
      ...data,
    });
  }

  getBulkUploadCounts() {
    return this.http.get(`${Urls.base_url}/asset/total-dashboard-count`);
  }

  sendApprovalLink(ticketId: string) {
    return this.http.get(
      `${Urls.base_url}/ticket/approval/request/${ticketId}`
    );
  }

  sendCustodyVerificationMail(data: any) {
    return this.http.get(`${Urls.sendCustodayVerification}`, {
      params: {
        ...data,
      },
    });
  }

  getAllUsers() {
    return this.http.get(`${Urls.base_url}/user/allUser`);
  }

  getAllAssetTypeCounts() {
    return this.http.get(`${Urls.base_url}/asset/total-asset-type-count`);
  }

  checkOutForRepair(assetId: string) {
    return this.http.patch(`${Urls.base_url}/asset/out-for-repair`, null, {
      params: {
        assetId: assetId,
      },
    });
  }

  checkInOrCheckOut(formData: any) {
    return this.http.post(
      `${Urls.base_url}/asset/checkIn-or-checkOut`,
      formData
    );
  }
  updateApprovalStatus(params: IVerificationParams) {
    return this.http.get(
      `${Urls.base_url}/ticket/public/update-approval-status?ticketId=${params.ticketId}&approvalStatus=${params.approvalStatus}&emailId=${params.emailId}`
    );
  }

  updateCustodyStatus(params: IVerificationParams) {
    return this.http.get(
      `${Urls.base_url}/asset-allocation/public/update-custody-status?userId=${params.userId}&assetId=${params.assetId}&custodyVerified=${params.custodyVerified}`
    );
  }

  getMetricsData() {
    return this.http.get<IMetricsData>(
      `${Urls.base_url}/utility/get-unit-metrics`
    );
  }

  getEndPointSecurityData() {
    return this.http.get<ISecurityOptions>(
      `${Urls.base_url}/endpoint/get-endpoint-security`
    );
  }

  addNewAsset(data: any) {
    return this.http.post(`${Urls.base_url}/asset`, {
      ...data,
    });
  }

  updateAsset(id:string,data: any) {
    return this.http.patch(`${Urls.base_url}/asset/${id}`, {
      ...data,
    });
  }

  verifyAssetCode(assetCode: string) {
    return this.http.get(
      `${Urls.base_url}/asset/validate-asset/?assetCode=${assetCode}`
    );
  }

  getAssetCode(assetTypeId: string,params: Record<string,string>) {
    const {financialYear,acronym} = params
    return this.http.get(
      `${Urls.base_url}/asset/generate-asset-code?type=${assetTypeId}&financialYear=${financialYear}&acronym=${acronym}`
    );
  }

  getAssestAllLocationsList() {
    return this.http.get(`${Urls.base_url}/asset/location/get-all-locations`);
  }

  getWarrantyListItems() {
    return this.http.get<IWarranty>(
      `${Urls.base_url}/asset/warranty/get-warranty-list`
    );
  }

  updateAssetStatus(params: IAssetStatus) {
    return this.http.patch<IUpdateAssetResponse>(
      `${Urls.base_url}/asset/update-asset-status`,
      params
    );
  }

  getAllOperatingSystems() {
    return this.http.get<IOperatingSystem>(
      `${Urls.base_url}/asset/operating-system/get-all-operating-system`
    );
  }

  getAllWarrantyList() {
    return this.http.get<IOperatingSystem>(
      `${Urls.base_url}/asset/warranty/get-warranty-list`
    );
  }
  getAllProcessors() {
    return this.http.get<IProcessor>(
      `${Urls.base_url}/asset/processor/get-all-processor`
    );
  }

  getAllVendorNames() {
    return this.http.get(`${Urls.base_url}/vendor/`);
  }

  getAllProjectNames() {
    return this.http.get<IProjectDetails>(
      `${Urls.base_url}/client-project/project`
    );
  }

  getAllClientNames() {
    return this.http.get<IClientDetails>(
      `${Urls.base_url}/client-project/client`
    );
  }

  verifyAsset(assetCode: string, ticketId: string,assetTypeId:string) {
    return this.http.get(
      `${Urls.base_url}/asset/verify-asset/?assetCode=${assetCode}&ticketId=${ticketId}&assetTypeId=${assetTypeId}`
    );
  }

  getAssetDetailsByAssetCode(assetCode: string) {
    return this.http.get(
      `${Urls.base_url}/asset/details?assetCode=${assetCode}`
    );
  }

  getSubCategories(endpoint: string) {
    return this.http.get<ISubCategory>(
      `${Urls.base_url}/issues${endpoint}`
    );
  }

  getPublicSubCategories(endPoint: string) {
    return this.http.get<ISubCategory>(
      `${Urls.base_url}/public/get-ticket-subcategories${endPoint}`
    );
  }

  duplicateAssetValidate(assetCode: string, employeeId: string) {
    return this.http.get(
      `${Urls.base_url}/asset-allocation/dual-asset?assetCode=${assetCode}&employeeId=${employeeId}`
    );
  }

  downloadTicketList(params?: {
    status?: string;
    searchText?: string;
    pageNumber?: string;
    itemsPerPage?: string;
    searchColumn?: string;
    sortDirection?: string;
    sortColumn?: string;
    assignedTickets?: boolean;
    isDownload?: boolean;
  }): any {
    if (params?.assignedTickets) {
      let paramString = `assignedTickets=true&pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
      if (params?.status) paramString += `&ticketStatus=${params?.status}`;
      if (params?.searchColumn && params?.searchText)
        paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
      if (params?.sortColumn && params?.sortDirection)
        paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;
      if (params?.isDownload) {
        paramString = paramString + `&isDownload=${params?.isDownload}`;
      }
      return this.http.get(`${Urls.getAllTicketsForAssetTeam}?${paramString}`);
    } else {
      let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;

      if (params?.status) paramString += `&ticketStatus=${params?.status}`;
      if (params?.searchColumn && params?.searchText)
        paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
      if (params?.sortColumn && params?.sortDirection)
        paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;
      if (params?.isDownload) {
        paramString = paramString + `&isDownload=${params?.isDownload}`;
      }
      return this.http.get(`${Urls.getAllTicketsForAssetTeam}?${paramString}`);
    }
  }

  downloadAssetList(params?: any) {
    let paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    if (params?.assetStatus)
      paramString += `&assetStatus=${params?.assetStatus}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortColumn=${params?.sortColumn}&sortDirection=${params?.sortDirection}`;

    if (params?.isDownload) {
      paramString = paramString + `&isDownload=${params?.isDownload}`;
    }
    return this.http.get(`${Urls.base_url}${Urls.assets}?${paramString}`);
  }

  validateAssetCode(assetCode: string, assetTypeId: string) {
    return this.http.get(
      `${Urls.base_url}/public/ticket-asset-code-validation?assetCode=${assetCode}&assetTypeId=${assetTypeId}`
    );
  }

  getAllAssetType() {
    return this.http.get(`${Urls.base_url}/asset-types/v2/`);
  }

  getPriorities(ticketId: string) {
    return this.http.get(
      `${Urls.base_url}/ticket/get-ticket-priorities?ticketId=${ticketId}`
    );
  }

  getFilteredTickets(params?: {
    status?: string;
    searchText?: string;
    pageNumber?: string;
    itemsPerPage?: string;
    searchColumn?: string;
    sortDirection?: string;
    sortColumn?: string;
    isDownload?: boolean;
    assignedTickets?: boolean;
  }): any {
    let paramString = ``;
    if (params?.assignedTickets) {
      paramString = `assignedTickets=true&pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    } else {
      paramString = `pageNumber=${params?.pageNumber}&itemsPerPage=${params?.itemsPerPage}`;
    }

    if (params?.status) paramString += `&ticketStatus=${params?.status}`;
    if (params?.searchColumn && params?.searchText)
      paramString += `&searchColumn=${params?.searchColumn}&searchText=${params?.searchText}`;
    if (params?.sortColumn && params?.sortDirection)
      paramString += `&sortDirection=${params?.sortDirection}&sortColumn=${params?.sortColumn}`;

    return this.http.get(`${Urls.getAllTicketsForAssetTeam}?${paramString}`);
  }

  getHistoryTypes() {
    return this.http.get(
      `${Urls.base_url}/asset-allocation/get-all-history-type`
    );
  }

  getAssetHistory(assetId: string, historyType: string) {
    return this.http.get(
      `${Urls.base_url}/asset-allocation/asset-history?assetId=${assetId}&historyType=${historyType}`
    );
  }

  getUserByEmail(email: string) {
    return this.http.get(
      `${Urls.base_url}/user/get-users-by-email?email=${email}`
    );
  }

  async uploadFilesToPresignedUrls(
    preSignedUrls: { key: string; presignedUrl: string }[],
    files: IFileInfo[]
  ): Promise<Observable<any>[]> {
    const uploadObservables: Observable<any>[] = [];

    for (let i = 0; i < preSignedUrls.length; i++) {
      const url = preSignedUrls[i].presignedUrl;
      const file = files[i];

      uploadObservables.push(
        await this.uploadFileToPresignedUrl(url, file.file)
      );
    }

    return Promise.all(uploadObservables);
  }

  getSuggestions(userId: any) {
    return this.http.get(
      `${Urls.base_url}/users/v2/suggestions?userId=${userId}`
    );
  }

  sendRequestApprovals(ticketId: any, data: any) {
    return this.http.post(
      `${Urls.base_url}/ticket/approval/request/${ticketId}`,
      data
    );
  }

  getApprovalStatus(ticketId: any) {
    return this.http.get(`${Urls.base_url}/ticket/approval/${ticketId}/status`);
  }
}
