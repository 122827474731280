import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import {
  GSC_API_PATH,
  ILoginResponse,
  IPermissions,
  ROUTES,
} from '@common/interfaces';
import { CredentialResponse } from 'google-one-tap';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ScriptLoader } from 'libs/common/src/lib/service/script-loader.service';
import { environment } from 'src/environments/environment';
import { SignInService } from './sign.in.service';
import { HttpClient } from '@angular/common/http'; // Import HttpClient for API calls

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  ifConnected: boolean = window.navigator.onLine;
  userDetails: any = {};
  showTicketMsg: boolean = false;
  loaderView: boolean = false;

  // Add properties for email and password input
  email: string = '';
  password: string = '';
  emailError: string | null = null; // For storing email validation error messages
  passError:string | null = null;
  isEmailVerified: boolean = false;
  showPassword: boolean = false;


  constructor(
    private signInService: SignInService,
    private router: Router,
    private loadScript: ScriptLoader,
    private commonHttpService: CommonHttpService,
    private renderer: Renderer2,
    private http: HttpClient // Inject HttpClient for API calls
  ) {}

  ngOnInit(): void {
    const scriptEl = this.loadScript.loadJsScript(this.renderer, GSC_API_PATH);

    scriptEl.onload = () => {
      const client_id = environment.GSC_CLIENT_ID;
      const divEl = document.getElementById('g_id_signin');
      const btnConfig = {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'signin_with',
        shape: 'rectangular',
        logo_alignment: 'left',
        auto_select: true,
        itp_support: true,
      };

      // Initializing Google API
      // @ts-ignore
      google.accounts.id.initialize({
        client_id,
        callback: (response: CredentialResponse) => {
          this.signinWithGoogle(response);
        },
      });

      // Rendering button after initialization
      // @ts-ignore
      google.accounts.id.renderButton(divEl, btnConfig);
    };
  }

 // Toggle password visibility
 togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
}
onEnterKey(): void {
  if (this.isEmailVerified) {
    this.continue(); // Calls Sign In logic
  } else {
    this.verifyEmail(); // Calls Continue logic
  }
}
  // Function to handle Google sign-in
  signinWithGoogle = (response: CredentialResponse) => {
    try {
      this.loaderView = true;
      if (response.credential) {
        const loadRequest = this.signInService.signInWithGoogle(
          response.credential
        );
        loadRequest.subscribe({
          next: (response) => {
            const userInfo = Object(response) as ILoginResponse;
            this.loginUser(userInfo);
          },
        });
      }
    } catch (error) { }
  };

  // Set user permissions after login
  setPermissions(userId: string, token: string) {
    const loadRequest = this.commonHttpService.getPermissions(userId, token);
    loadRequest.subscribe({
      next: (response: IPermissions) => {
        const permissions = response as IPermissions;
        const permissionsString = JSON.stringify(permissions.data);
        localStorage.setItem('permissions', permissionsString);
      },
    });
  }

  // Login user and redirect based on roles
  loginUser(user: ILoginResponse) {
    const { token, userId,workspaceId, workspaceName } = user.data;
    const { firstName, lastName, email, profilePictureUrl } =
      user.data.userData;
    if (token) {
      const userData = {
        firstName,
        lastName,
        email,
        photo_url: profilePictureUrl,
        roles: user.data.roles,
      };
      localStorage.setItem('userInfo', JSON.stringify(userData));
      localStorage.setItem('token', token || '');
      localStorage.setItem('userId', userId || '');
      localStorage.setItem('workspaceId',workspaceId || '')
      localStorage.setItem('workspaceName',workspaceName || '')

      const employeeDashboard = !user.data.isUserOnboarded
        ? '/home/welcome'
        : ROUTES.USER_DASHBOARD;
      const adminDashboard = ROUTES.ASSET_TEAM_DASHBOARD;
      const redirect =
        user.data.roles[0] === 'USER' ? employeeDashboard : adminDashboard;

      this.setPermissions(userId, token);
      this.router.navigate([redirect]);
    }
  }


  verifyEmail() {
    this.loaderView = true; // Show loader at the start of verification

    this.signInService.verifyEmail(this.email).subscribe(
      (response: any) => {
        this.loaderView = false; // Hide loader once verification completes
        if (response.data.isVerified) {
          this.isEmailVerified = true;
          this.emailError = null; // Clear any previous error
        } else {
          this.emailError = response.data.message; // Set error message
        }
      },
      (error) => {
        this.loaderView = false; // Hide loader even if an error occurs
        console.error('Verification error:', error);
        this.emailError = error.error?.error?.errorList || 'Verification failed. Please try again.';
      }
    );
  }



  continue() {
    // Clear previous error messages and show loader
    this.emailError = null;
    this.loaderView = true;

    if (this.isEmailVerified) {
      this.signInService.apiSignIn({
        email: this.email,
        password: this.password,
      }).subscribe(
        (response: ILoginResponse) => {
          this.loaderView = false;

          // Check if token exists in the response
          if (response.data?.token) {
            this.loginUser(response); // Handle login and redirection
          } else {
            this.emailError = 'Login failed. Please try again.';
          }
        },
        (error) => {
          console.log('error: ', error);
          this.loaderView = false;

          // Extract the error message from the response
          if (error?.error?.error?.errorList) {
            this.passError = error.error.error.errorList; // Display "Invalid Password"
          } else if (error?.message) {
            this.passError = error.message; // Fallback to the general error message
          } else {
            this.passError = 'An unexpected error occurred. Please try again.';
          }

          console.error('Login error:', error); // Log error for debugging
        }
      );
    } else {
      this.emailError = 'Email verification is required. Please verify your email.';
    }
  }



  // Navigate to contact admin page
  contactAdmin() {
    this.router.navigate(['contact-admin']);
  }
}
