<section class="wrapper">
  <section class="popup-header">
    <div class="header-text">
      <img [src]="imageAddress" />
      <div class="header">
        <h1 class="primary-header">{{ header }}</h1>
        <p class="description">{{ description }}</p>
      </div>
    </div>
    <div>
      <div class="close" (click)="closeDialog()">
        <img src="assets/24x24/close.svg" alt="" />
      </div>
    </div>
  </section>
  <section *ngIf="data.assetStatusOption" class="textarea-section">
    <app-generic-drop-down
      [options]="options"
      title="Select Asset Status"
      (index)="getIndex($event)"
      titleName="assetStatus"
      captionItem="assetValue"
      [shouldEmitIndex]="true"
      [shouldEmitdisplayValue]="false"
    ></app-generic-drop-down>
    <p *ngIf="displayCaption" class="caption">
      Assets marked as e-waste cannot be enabled again.
    </p>
    <!-- <label class="label">Reason</label> -->
        <!-- <app-textarea
          class="text-area"
          cols="30"
          rows="10"
          [formControl]="textArea"
          characterCounter
          maxLength="255"
          (countEvent)="displayCount($event)"
          [countData]="countData"
          [placeholder]= "placeholder"
          [isRequired]="true"
          [isLabelPresent]="false"
        >
        </app-textarea> -->
  </section>
  <section *ngIf="data.primaryBtn === 'Enable' || data.assetStatusOption">
    <app-textarea
          class="text-area"
          cols="30"
          rows="10"
          [formControl]="textArea"
          characterCounter
          maxLength="255"
          (countEvent)="displayCount($event)"
          [countData]="countData"
          [placeholder]= "placeholder"
          [isRequired]="true"
          [isLabelPresent]="false"
        >
        </app-textarea>
  </section>
  <section class="popup-footer">
    <ng-container *ngIf="secondaryBtn">
      <app-button
        size="xsmall"
        theme="secondary"
        (click)="onClick(secondaryBtn)"
      >
        {{ secondaryBtn }}
      </app-button>
    </ng-container>
    <ng-container>
      <app-button size="xsmall" theme="primary" *ngIf="data.assetStatusOption;else other" [disabled]="!(this.isDropDownSelected && this.textArea.valid)" (click)="onClick(primaryBtn)"
        >{{ primaryBtn }}
      </app-button>

      <ng-template #other>
        <app-button size="xsmall" theme="primary" (click)="onClick(primaryBtn) " [disabled]="((data.primaryBtn === 'Enable' && !this.textArea.valid) )"
          >{{ primaryBtn  }}
        </app-button>
      </ng-template>
    </ng-container>
  </section>
</section>
