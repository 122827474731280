import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { getAssetTeamTicketsData, ISearchData } from '@common/interfaces';
import {
  PAGINATION_DATA,
  REQUEST_MENU,
  REQUEST_SEARCH_OPTIONS,
  REQUEST_SORT_COLUMNS,
} from 'libs/common/src/lib/constants/constants';
import { FIELD_ARRAY_REQUEST_LIST } from 'libs/common/src/lib/constants/table-field-constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { UploadDownloadService } from 'libs/common/src/lib/service/upload-download.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-approvals',
  templateUrl: './admin-approvals.component.html',
  styleUrls: ['./admin-approvals.component.scss'],
})
export class AdminApprovalsComponent implements OnInit {
  requestStatus = REQUEST_MENU;
  fieldArray = FIELD_ARRAY_REQUEST_LIST;
  titleForClientDropDown!: string;
  isEkoAdmin!: boolean;
  isAdmin!: boolean;
  isUser!: boolean;
  isSemiAdmin!: boolean;
  textOnButton = 'Download Request List';
  provider!: string;
  searchColumn!: string;
  searchText!: string;
  status = '';
  PAGINATION_DATA = PAGINATION_DATA;
  pageSize = '5';
  currentPageNumber = '1';
  loaderView: boolean = false;
  sortDigit = 'DESC';
  tableDetails: any;
  count!: number;
  currentParams: any;

  REQUEST_SEARCH_OPTIONS = Object.values(REQUEST_SEARCH_OPTIONS);
  // displayTicketList: boolean = false;
  isAssignedTicketSelected: boolean = false;
  tableConfig = [
    {
      title: 'Ticket no.',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isTicket: true,
        keys: [
          {
            key: 'ticketNumber',
            from: 'ticket',
            handleAction: (ticketId: string) => {
              this.navigateToTicket(ticketId);
            },
          },
          { key: 'title', from: 'ticket' },
        ],
      },
    },
    {
      title: 'Ticket date',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'createdAt', from: 'ticket', isDate: true },
        isTicket: true,
      },
    },
    {
      title: 'RequestStatus',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [{ key: 'status', from: 'ticket', isStatus: true }],
        isTicket: true,
      },
    },
    {
      dataType: 'asset',
      title: 'Asset name',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'name', from: 'asset' },
        isAsset: true,
      },
    },
    {
      dataType: 'userInfo',
      title: 'Request raised by',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [
          { key: 'firstName', from: 'user' },
          { key: 'lastName', from: 'user' },
          { key: 'externalUserName', from: 'user' },
          { key: 'email', from: 'user' },
        ],
        isUserInfo: true,
      },
    },
    {
      dataType: 'assigneeInfo',
      title: 'Request assigned to',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [
          { key: 'firstName', from: 'assignee' },
          { key: 'lastName', from: 'assignee' },
          { key: 'email', from: 'assignee' },
        ],
        isAssigneeInfo: true,
      },
    },
  ];
  constructor(
    private commonHttpService: CommonHttpService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private uploadAndDownloadService: UploadDownloadService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    const userRole = JSON.parse(localStorage.getItem('userInfo') || '{}')
      ?.roles[0];
    this.isEkoAdmin = userRole === 'EKO-ADMIN';
    this.isAdmin = userRole === 'ADMIN';
    this.isSemiAdmin = userRole === 'SEMI-ADMIN';
    this.isUser = userRole === 'USER';
    this.activatedRoute?.queryParams?.subscribe((params) => {
      if (!this.isUser) {
          this.requestStatus = REQUEST_MENU;
          this.titleForClientDropDown = 'Coditas';
        }
      

      this.pageSize = params['size'] || '5';
      this.searchText = params['search'] || '';
      this.searchColumn = params['column'] || '';
      this.currentPageNumber = params['page'] || '1';
      this.status =
        params['status'] && params['status'] !== 'ALL' ? params['status'] : '';
      this.searchColumn = '';
      this.searchText = '';
      const param = {
        status: this.status,
        pageNumber: params['page'] || '1',
      };

      const pageSize = sessionStorage.getItem('pageSize');
      if (pageSize) {
        this.pageSize = pageSize;
      }

      this.loadCounters();
      this.getAllTickets(param);
      this.changeDetectorRef.detectChanges();
    });
  }
  downloadTicketList() {}

  getFilteredData(filter: string, param?: any) {
    if (filter == 'assigned') {
      this.isAssignedTicketSelected = true;
      const params = {
        status: this.status,
        pageNumber: param?.pageNumber || '1',
        itemsPerPage: this.pageSize || '5',
        searchColumn: this.searchColumn || '',
        searchText:
          this.searchColumn === 'TICKET_DATE'
            ? this.formatDate(this.searchText)
            : this.searchText || '',
        sortColumn: param?.sortColumn || '',
        sortDirection: param?.sortDirection || '',
        assignedTickets: this.isAssignedTicketSelected,
      };

      this.getAllTickets(params);
    } else {
      this.isAssignedTicketSelected = false;
      this.getAllTickets();
    }
  }

  getAllTickets(param?: any) {
    this.loaderView = true;
    if (this.router.url.includes('status=TO_DO')) {
      this.isAssignedTicketSelected = false;
    }
    const params = {
      provider: this.provider,
      status: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      searchColumn: this.searchColumn || '',
      searchText:
        this.searchColumn === 'TICKET_DATE'
          ? this.formatDate(this.searchText)
          : this.searchText || '',
      sortColumn: param?.sortColumn || '',
      sortDirection: param?.sortDirection || '',
      assignedTickets: this.isAssignedTicketSelected || false,
    };

    this.currentParams = params;

    const loadRequest = this.commonHttpService.getApprovalTeamTableData(params);
    if (loadRequest) {
      loadRequest.subscribe({
        next: (response: any) => {
          const ticketsData = Object(response).data;
          this.tableDetails = getAssetTeamTicketsData(ticketsData.tickets);
          const groupedTableDetails: { [key: string]: any[] } = {};
          for (const object of this.tableDetails) {
            const date = object.Date;
            if (!groupedTableDetails.hasOwnProperty(date)) {
              groupedTableDetails[date] = [];
            }
            groupedTableDetails[date].push(object);
          }
          this.count = ticketsData.count;

          this.commonService.searchParam.next({
            searchColumn: param?.searchColumn,
            searchText: param?.searchText,
            sortColumn: param?.sortColumn,
            sortDirection: param?.sortDirection,
            pageNumber: param?.pageNumber,
            pageSize: param?.pageSize,
          });
          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
        },
      });
      this.currentPageNumber = param?.pageNumber;
      this.sortDigit = this.sortDigit === 'DESC' ? 'ASC' : 'DESC';
      this.changeDetectorRef.detectChanges();
    }
  }
  filterTickets(filter: any) {
    this.status = filter;
    this.getAllTickets();
  }
  search(searchData: ISearchData) {
    this.searchColumn = searchData.filterBy;
    this.searchText = searchData.searchStr;
    this.getAllTickets();
    this.textOnButton = 'Download Request List';
  }
  formatDate(date: string) {
    return date.split('/').reverse().join('-');
  }
  onClientChange(client: string) {
      this.requestStatus = REQUEST_MENU;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
      });
    
    this.loadCounters();
    this.getAllTickets();
  }
  pageSizeChange(pageSize: string) {
    const pageCount = sessionStorage.getItem('pageSize');
    if (pageCount) {
      this.pageSize = pageCount;
    } else {
      this.pageSize = pageSize;
    }
    this.getAllTickets();
  }
  loadCounters() {
    this.commonHttpService
      .getApprovalsCount()
      .subscribe((res: any) => {
          this.requestStatus[0].count = res.data.approvedCount;
          this.requestStatus[1].count = res.data.declinedCount;
          this.requestStatus[2].count = res.data.pendingCount;
          this.requestStatus[3].count = res.data.totalCount;
      });
  }
  handleSortAction(col: string) {
    const sortAction = col.split(' ');
    const columnName = sortAction[1];
    const sortOrder = sortAction[0];
    this.sortDigit = sortOrder;
    const param = {
      sortColumn: '',
      sortDirection: this.sortDigit,
      pageNumber: this.currentPageNumber,
    };
    switch (columnName) {
      case 'TicketNo':
        param.sortColumn = REQUEST_SORT_COLUMNS.TICKET_NUMBER;
        break;

      case 'Date':
        param.sortColumn = REQUEST_SORT_COLUMNS.TICKET_DATE;
        break;

      case 'AssetName':
        param.sortColumn = REQUEST_SORT_COLUMNS.ASSET_NAME;
        break;

      case 'RequestStatus':
        param.sortColumn = REQUEST_SORT_COLUMNS.REQUEST_STATUS;
        break;

      case 'Priority':
        param.sortColumn = REQUEST_SORT_COLUMNS.PRIORITY;
        break;

      case 'RequestRaisedby':
        param.sortColumn = REQUEST_SORT_COLUMNS.REQUEST_RAISED_BY;
        break;

      case 'RequestAssignedto':
        param.sortColumn = REQUEST_SORT_COLUMNS.ASSIGNED_TO;
        break;

      default:
        param.sortColumn = '';
        break;
    }

    this.getAllTickets(param);
  }

  navigateToTicket(ticketId: string) {
    // this.requestStatusClicked = true;
    const buildURL = this.commonService.buildURL(
      `home/admin-ticket/ticket/${ticketId}`,
      {
        page: this.currentPageNumber,
        size: this.pageSize,
        search: this.searchText,
        column: this.searchColumn,
        provider: this.provider,
      }
    );
    this.router.navigateByUrl(buildURL);
  }
}
