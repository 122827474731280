import { Component } from '@angular/core';

@Component({
  selector: 'app-add-workspace',
  templateUrl: './add-workspace.component.html',
  styleUrls: ['./add-workspace.component.scss']
})
export class AddWorkspaceComponent {
  pageTitle: string = 'Create Workspace';
  hasError: boolean = false;
  loaderView: boolean = false;

}
