import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
@Component({
  selector: 'app-generate-ticket-section',
  templateUrl: './generate-ticket-section.component.html',
  styleUrls: ['./generate-ticket-section.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class GenerateTicketSectionComponent implements OnInit {
  assetCount: number = 0;
  generateTicketOptions = [
    'Report an Issue',
    'Request an Asset',
    'Request Access/Software Installation',
  ];
  constructor(
    private commonService: CommonService,
    public dialog: Dialog,
    private commonHttpService: CommonHttpService
  ) {}

  @Output() toggleModalEmitter = new EventEmitter();
  userDetails!: any;
  selectedOption: string = 'Report an Issue';

  ngOnInit(): void {
    this.commonHttpService.getUserAsset(this.selectedOption).subscribe((res: any) => {
      this.userDetails = res;
      this.assetCount = this.userDetails.data.length;
    });
  }

  getSelectedOption(selectedValue: string) {
    this.selectedOption = selectedValue;
    this.commonHttpService.getUserAsset(selectedValue).subscribe({
      next: (res: any) => {
        this.userDetails = res;
        this.assetCount = this.userDetails.data.length;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  openModal() {
    switch (this.selectedOption) {
      case 'Report an Issue':
        this.open('raise-ticket-modal');
        break;
      case 'Request an Asset':
        this.open('request-asset-modal');
        break;
      case 'Request Access/Software Installation':
        this.open('access-installation-modal');
        break;
      default:
        // Handle any other cases if needed
        break;
    }
  }
  open(modal: string) {
    this.toggleModalEmitter.emit(modal);
  }
}
