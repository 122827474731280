import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILoginResponse } from '@common/interfaces';
import { base_url } from '../../../../common/src/lib/urls/urls';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  constructor(private http: HttpClient) { }

  getToken() {
    return localStorage.getItem('token');
  }

  getUserId() {
    return localStorage.getItem('userId');
  }

  getWorkspaceId() {
    return localStorage.getItem('workspaceId');


  }
  apiSignIn(data: any) {
    return this.http.post<ILoginResponse>(`${base_url}/auth/login`, data);
  }

  signInWithGoogle(idToken: string) {
    const data = {
      token: `${idToken}`,
    };
    return this.http.post(`${base_url}/auth/google`, data);
  }

  handleAzureAuthToken(token: string) {
    return this.http.get(`${base_url}/auth/validate-token?authToken=${token}`);
  }

  signInWithAzure() {
    return this.http.get(`${base_url}/auth/azure`);
  }

  verifyEmail(email: string) {
    const emailVerificationUrl = `${base_url}/auth/verify-email`;
    return this.http.post(emailVerificationUrl, { email });
  }
}
