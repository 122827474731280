import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@common/interfaces';

import { CommonService } from 'libs/common/src/lib/service/common.service';

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private commonService: CommonService, private router: Router) { }
  info: boolean = false;
  displayAdminHeader: boolean = false;
  userRole!: string;
  userNavigation = [
    {
      label: 'Dashboard',
      route: 'employee-dashboard',
      img: 'assets/24x24/dashboard.svg',
    },
    {
      label: 'Approvals',
      route: 'employee-approval',
      img: 'assets/24x24/approvals.svg',
    },
  ];

  filterOptionsForUser = [
    { title: 'Ongoing', value: 'PENDING' },
    { title: 'Closed', value: 'CLOSED' },
    { title: 'All tickets', value: '' },
  ];

  ngOnInit(): void {
    this.commonService.setFilterOptions(this.filterOptionsForUser);
    const userDetails = JSON.parse(
      localStorage.getItem('userInfo') as string
    );
    if (userDetails.roles[0] === 'ADMIN') {
      this.userNavigation = [
        {
          label: 'Dashboard',
          route: 'asset-team-dashboard',
          img: 'assets/24x24/dashboard.svg',
        },
        {
          label: 'Tickets',
          route: 'admin-ticket',
          img: 'assets/22x22/tickets.svg',
        },
        {
          label: 'Approvals',
          route: 'admin-approval',
          img: 'assets/24x24/approvals.svg',
        },
        {
          label: 'Inventory',
          route: 'inventory',
          img: 'assets/24x24/inventory.svg',
        },
        // {
        //   label: 'Bulk Upload',
        //   route: 'bulk-upload',
        //   img: 'assets/24x24/bulk-upload.svg',
        // },
      ];
    }
    if (userDetails.roles[0] === 'SUPER-ADMIN') {
      this.userNavigation = [
        {
          label: 'Dashboard',
          route: 'asset-team-dashboard',
          img: 'assets/24x24/dashboard.svg',
        },
        {
          label: 'Tickets',
          route: 'admin-ticket',
          img: 'assets/22x22/tickets.svg',
        },
        {
          label: 'Approvals',
          route: 'admin-approval',
          img: 'assets/24x24/approvals.svg',
        },
        {
          label: 'Inventory',
          route: 'inventory',
          img: 'assets/24x24/inventory.svg',
        },
        {
          label: 'Bulk Upload',
          route: 'bulk-upload',
          img: 'assets/24x24/bulk-upload.svg',
        },
        {
          label: 'Manage Workspace',
          route: 'manage-workspace',
          img: 'assets/24x24/manage-workspace.svg',
        },
      ];
    }
    if (userDetails.roles[0] === 'WORKSPACE-OWNER') {
      this.userNavigation = [
        {
          label: 'Dashboard',
          route: 'asset-team-dashboard',
          img: 'assets/24x24/dashboard.svg',
        },
        {
          label: 'Tickets',
          route: 'admin-ticket',
          img: 'assets/22x22/tickets.svg',
        },
        {
          label: 'Approvals',
          route: 'admin-approval',
          img: 'assets/24x24/approvals.svg',
        },
        {
          label: 'Inventory',
          route: 'inventory',
          img: 'assets/24x24/inventory.svg',
        },
        {
          label: 'Bulk Upload',
          route: 'bulk-upload',
          img: 'assets/24x24/bulk-upload.svg',
        },
        {
          label: 'Manage Workspace',
          route: 'manage-workspace-owner',
          img: 'assets/24x24/manage-workspace.svg',
        },
      ];
    }
    else if (userDetails.roles[0] === 'SEMI-ADMIN' || userDetails.roles[0] ==='EKO-ADMIN') {
      this.userNavigation = [
        {
          label: 'Dashboard',
          route: 'asset-team-dashboard',
          img: 'assets/24x24/dashboard.svg',
        },
        {
          label: 'Tickets',
          route: 'admin-ticket',
          img: 'assets/22x22/tickets.svg',
        },
        {
          label: 'Approvals',
          route: 'admin-approval',
          img: 'assets/24x24/approvals.svg',
        },
        {
          label: 'Inventory',
          route: 'inventory',
          img: 'assets/24x24/inventory.svg',
        }]
    }
    this.userRole = userDetails.roles[0];
  }

  ngDoCheck() {
    if (this.userRole === 'ADMIN') {
      if (
        this.router.url === (ROUTES.ADMIN_TICKET) ||
        this.router.url === (ROUTES.ASSET_TEAM_DASHBOARD) || this.router.url === (ROUTES.ADMIN_TICKET_PERSONAL) || this.router.url === (ROUTES.ASSET_TEAM_DASHBOARD_PERSONAL) || this.router.url.includes('home/admin-ticket?status') || this.router.url.includes('ticketType')
      ) {
        this.displayAdminHeader = true;
      } else {
        this.displayAdminHeader = false;
      }
    }
  }

  showInfo() {
    this.info = true;
  }

  goToEmployeeDetails(userId: string) {
    this.router.navigate(['home/profile-details', userId]);
  }
}
