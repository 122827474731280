<div class="asset-team-container">
  <app-back-button
    [backRouteLink]="{ path: '/home/manage-workspace', params: historyConfig }"
  ></app-back-button>

  <div class="outer-container">
    <div class="asset-container">
      <div class="asset-details-container">
        <app-loader
          class="loader"
          *ngIf="loaderView; else workspaceDetailsSection"
        ></app-loader>

        <ng-template #workspaceDetailsSection>
          <div *ngIf="workspaceDetails; else workspaceNotFoundSection">
            <!-- Workspace Name & Status -->
            <span  class="asset-details-headings">Workspace Details</span>
            <ng-container>
              <div class="asset-details">
                <div class="asset-attribute-name">
                  <span>Name</span><span class="colon">:</span>
                </div>
                <span class="asset-attribute-value">
                  {{ workspaceDetails.name || "Not Available" }}
                </span>
              </div>
              <div class="asset-details">
                <div class="asset-attribute-name">
                  <span>Status</span><span class="colon">:</span>
                </div>
                <span class="asset-attribute-value">
                  {{ workspaceDetails.isActive ? "Active" : "Inactive" }}
                </span>
              </div>
            </ng-container>

            <!-- Projects -->
            <ng-container *ngIf="workspaceDetails.projects && workspaceDetails.projects.length > 0">
              <div class="asset-details">
                <div class="asset-attribute-name">
                  <span>Projects</span><span class="colon">:</span>
                </div>
                <span class="asset-attribute-value">
                  <!-- List the project names without repeating the heading -->
                  <div *ngFor="let project of workspaceDetails.projects">
                    {{ project.name || "Not Available" }}
                  </div>
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="workspaceDetails.codeTemplate && workspaceDetails.codeTemplate.length > 0">
              <div class="asset-details">
                <div class="asset-attribute-name">
                  <span>Code Templates</span><span class="colon">:</span>
                </div>
                <span class="asset-attribute-value">
                  <!-- List the formatted code templates -->
                  <div *ngFor="let codeTemplate of workspaceDetails.codeTemplate">
                    {{ formatCodeTemplate(codeTemplate) || "Not Available" }}
                  </div>
                </span>
              </div>
            </ng-container>


            <!-- Asset Types -->
            <ng-container *ngIf="workspaceDetails.assetTypes && workspaceDetails.assetTypes.length > 0">
              <div class="asset-details">
                <div class="asset-attribute-name">
                  <span>Asset Types</span><span class="colon">:</span>
                </div>
                <span class="asset-attribute-value">
                  <!-- List the asset type names without repeating the heading -->
                  <div *ngFor="let assetType of workspaceDetails.assetTypes">
                    {{ assetType.name || "Not Available" }}
                  </div>
                </span>
              </div>
            </ng-container>


          </div>
        </ng-template>

        <!-- Workspace Not Found Section -->
        <ng-template #workspaceNotFoundSection>
          <div class="asset-not-loaded-section">
            <p>Something went wrong, try later!</p>
          </div>
        </ng-template>
      </div>
      <div
      class="asset-history"
    >
    <app-loader
          class="loader"
          *ngIf="loaderView;"
        ></app-loader>

        <ng-container>
          <div class="asset-details">
            <div class="asset-attribute-name">
              <span>Owners</span><span class="colon">:</span>
            </div>
            <span class="asset-attribute-value">
              {{ workspaceDetails.workspaceOwnerCount || "0" }}
            </span>
          </div>
          <div class="asset-details">
            <div class="asset-attribute-name">
              <span>Admins</span><span class="colon">:</span>
            </div>
            <span class="asset-attribute-value">
              {{ workspaceDetails.adminCount || "0" }}
            </span>
          </div>
          <div class="asset-details">
            <div class="asset-attribute-name">
              <span>Users</span><span class="colon">:</span>
            </div>
            <span class="asset-attribute-value">
              {{ workspaceDetails.userCount || "0" }}
            </span>
          </div>
        </ng-container>

        <ng-template #workspaceNotFoundSection>
          <div class="asset-not-loaded-section">
            <p>Something went wrong, try later!</p>
          </div>
        </ng-template>
  </div>
    </div>
  </div>
</div>
