<router-outlet #outlet="outlet"></router-outlet>
<ng-container *ngIf="!outlet.isActivated">
  <app-admin-header
    coditasRoute="/home/asset-team-dashboard/coditas"
    personalRoute="/home/asset-team-dashboard/personal"
    personalTabPermission="DASHBOARD__PERSONAL"
  ></app-admin-header>
  <div class="asset-team-container">
    <div class="ticket-status">
      <app-asset-info
        [assets]="ticketStatus"
        title="Ticket Status"
        [isDashboard]="true"
        [isAssetTypeCounter]="false"
        class="ticket-status"
      ></app-asset-info>
    </div>
    <div class="dashboard-container">
      <app-asset-info
        [assets]="assetStatus"
        [title]="'Asset Status'"
        [isDashboard]="false"
        [isAssetTypeCounter]="true"
        [reportType]="'Asset Status'"
        [displayDashboardContainer]="true"
      ></app-asset-info>
    </div>
  </div>
</ng-container>
