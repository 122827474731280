<div class="main-container">
  <div class="drop-down-container" [ngClass]="{ disabled: disabled }">
    <div class="head">
      <div class="head-title" [ngClass]="{ focus: showDropDownOptions }">
        <div class="selected-item">
          <img
            class="icons"
            *ngIf="icon"
            src="assets/24x24/{{ icon }}.svg"
            alt="{{ icon }}"
          />
          <span class="title">{{ title }}</span>
        </div>

        <img class="icons" src="assets/12x12/sort-asc.svg" alt="expand" />
      </div>
    </div>

    <div
      class="options"
      *ngIf="showDropDownOptions"
      [ngClass]="{ 'is-down': showDropContainerTop }"
    >
    <div
    class="option"
    *ngFor="let option of options; let i = index"
    [ngClass]="{ active: option === title }"
    >
        <div
          class="option-item"
          (click)="getOption(option[titleName], i, option[valueName], option)"
          [ngClass]="{ active: option === title }"
        >
          <p
            class="option-name"
            *ngIf="titleName.length > 0"
            value="option[valueName]"
          >
            {{ option[titleName] }}
          </p>
          <ng-container *ngIf="titleName.length === 0">
            <p
              class="option-name"
              value="option[valueName]"
              *ngIf="option !== title; else otherOptions"
            >
              {{ option }}
            </p>
            <ng-template #otherOptions>
              <p class="option-name">{{ title | titlecase }}</p>
              <img src="assets/12x12/check.svg" alt="" srcset="" />
            </ng-template>
          </ng-container>
          <p class="option-asset-code" *ngIf="hasCaption">
            {{ option[captionItem] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
