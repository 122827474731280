<ng-container >
  <app-admin-header
  coditasRoute="/home/admin-approval/coditas"
  personalRoute="/home/admin-approval/personal"
></app-admin-header>

<div
class="asset-team-container" >


<app-asset-info
[assets]="requestStatus"
[title]="'Request Status'"
[titleForClientDropDown]="titleForClientDropDown"
class="ticket-status"
(buttonClick)="downloadTicketList()"
[buttonText]="textOnButton"
(onClientSelect)="onClientChange($event)"
></app-asset-info>
<div class="divider-container"><div class="divider"></div></div>
<div
class="dash-section-wrapper container-with-no-border"
[ngClass]="{ loader: loaderView }"
>
<div *ngIf="loaderView">
  <app-loader></app-loader>
</div>

<div>
  <app-table
    [tableConfig]="tableConfig"
    [tableData]="tableDetails"
    [searchOptions]="REQUEST_SEARCH_OPTIONS"
    (searchData)="search($event)"
    (activeFilter)="filterTickets($event)"
    [count]="count"
    (paginatorEmitter)="getAllTickets($event)"
    [filterOptions]="PAGINATION_DATA"
    (pageSizeChange)="pageSizeChange($event)"
    [activeMenu]="pageSize"
    class="width-100"
    [displayTicketList]="true"
    (onFilterApply)="getFilteredData($event)"
    [fieldArray]="fieldArray"
    [tableDetails]="tableDetails"
    (tableNavigationClick)="navigateToTicket($event)"
    (onSort)="handleSortAction($event)"
  ></app-table>
</div>
</div>


</div>


</ng-container>
