<div class="asset-team-container">
  <app-back-button></app-back-button>
  <app-loader class="loader" *ngIf="loaderView"></app-loader>
  <div class="wrapper-container" *ngIf="!loaderView">
    <div class="asset-container">
      <div>
        <div>
          <div class="head">
            <h1 class="heading">{{ pageTitle }}</h1>
            <button
              *ngIf="!assetTypeId"
              class="close-button"
              type="button"
              (click)="closeConfirmationDialog()"
            >
              <img src="assets/24x24/close.svg" alt="" />
            </button>
            <button
              *ngIf="assetTypeId"
              class="close-button"
              type="button"
              (click)="backToDetails()"
            >
              <img src="assets/24x24/close.svg" alt="" />
            </button>
          </div>
          <p class="info">
            {{
              assetId
                ? "Edit and update specifications of selected asset"
                : "Select an asset category and specifications to add a new asset"
            }}
          </p>
          <p class="note">Fields marked with * are mandatory</p>
        </div>
        <!-- Create Asset Form starts here -->
        <div>
          <form [formGroup]="assetForm">
            <div class="form-group">
              <div class="header-container">
                <ng-container>
                  <div class="dropdown">
                    <p class="label">Category *</p>
                    <app-generic-drop-down
                      [options]="assetTypeOptions"
                      (value)="getValueOfCategory($event)"
                      selectedValue=""
                      [titleName]="'name'"
                      [valueName]="'id'"
                      [title]="selectedValue || 'Select Asset Category'"
                      [shouldEmitEmittedValue]="true"
                      [disabled]="!!assetId"
                    >
                    </app-generic-drop-down>
                  </div>
                </ng-container>
                <ng-container *ngIf="assetTypeId">
                  <div class="field">
                    <div class="add-asset">
                      <p for="" class="label">Asset Code *</p>
                      <button
                        type="button"
                        (click)="onGenerateCode($event)"
                        class="generate-asset-code"
                        [disabled]="!!assetId"
                      >
                        Generate Code
                      </button>
                    </div>
                    <app-text-field
                      [required]="true"
                      for="assetCode"
                      id="assetCode"
                      type="text"
                      name="assetCode"
                      formControlName="assetCode"
                      label=""
                      placeholder="XYZ-00-00-L-0001"
                      (change)="onAssetCodeChange($event)"
                      [value]="assetCode"
                      [disabled]="!!assetId"
                    ></app-text-field>
                    <!-- Validation Error -->
                    <small
                      *ngIf="
                        assetForm.get('assetCode')?.invalid &&
                        (assetForm.get('assetCode')?.touched ||
                          assetForm.get('assetCode')?.dirty)
                      "
                      class="error-message"
                    >
                      This field is mandatory.
                    </small>
                  </div>

                  <div class="field">
                    <p for="" class="label">Model Name *</p>
                    <app-text-field
                      [required]="true"
                      for="modelName"
                      id="modelName"
                      type="text"
                      [formControlName]="'modelName'"
                      label=""
                      placeholder="Enter Model Name"
                      [hasError]="''"
                    ></app-text-field>
                    <!-- Validation Error -->
                    <small
                      *ngIf="
                        assetForm.get('modelName')?.invalid &&
                        (assetForm.get('modelName')?.touched ||
                          assetForm.get('modelName')?.dirty)
                      "
                      class="error-message"
                    >
                      This field is mandatory.
                    </small>
                  </div>

                  <div class="field">
                    <p class="label">Vendor Name *</p>
                    <app-generic-drop-down
                      [options]="vendorNames"
                      [title]="properties['vendorName'] || 'Select'"
                      placeholder="select vendor name"
                      [shouldEmitEmittedValue]="true"
                      [titleName]="'name'"
                      [valueName]="'id'"
                      (value)="
                        properties['vendorId'] = $event;
                        onSelectionChange($event, 'vendorId')
                      "
                    ></app-generic-drop-down>
                    <!-- Validation Error -->
                    <small
                      *ngIf="
                        assetForm.get('vendorId')?.invalid &&
                        (assetForm.get('vendorId')?.touched ||
                          assetForm.get('vendorId')?.dirty)
                      "
                      class="error-message"
                    >
                      This field is mandatory.
                    </small>
                  </div>
                  <div class="field">
                    <p class="label">Owned By *</p>
                    <app-generic-drop-down
                      [options]="clients"
                      [title]="properties['ownedByClient'] || 'Select Owned By'"
                      placeholder="select owned by"
                      [shouldEmitEmittedValue]="true"
                      [titleName]="'name'"
                      [valueName]="'id'"
                      (value)="onSelectionChange($event, 'ownedBy')"
                    ></app-generic-drop-down>
                    <!-- Validation Error -->
                    <small
                      *ngIf="
                        assetForm.get('ownedBy')?.invalid &&
                        (assetForm.get('ownedBy')?.touched ||
                          assetForm.get('ownedBy')?.dirty)
                      "
                      class="error-message"
                    >
                      This field is mandatory.
                    </small>
                  </div>
                  <div class="field">
                    <p class="label">Managed By *</p>
                    <app-generic-drop-down
                      [options]="clients"
                      [title]="
                        properties['managedByClient'] || 'Select Managed By'
                      "
                      placeholder="select Managed By"
                      [shouldEmitEmittedValue]="true"
                      [titleName]="'name'"
                      [valueName]="'id'"
                      (value)="onSelectionChange($event, 'managedBy')"
                    ></app-generic-drop-down>
                    <!-- Validation Error -->
                    <small
                      *ngIf="
                        assetForm.get('managedBy')?.invalid &&
                        (assetForm.get('managedBy')?.touched ||
                          assetForm.get('managedBy')?.dirty)
                      "
                      class="error-message"
                    >
                      This field is mandatory.
                    </small>
                  </div>
                  <div class="field">
                    <p class="label">Purchase Date *</p>
                    <app-text-field
                      [formControlName]="'purchaseDate'"
                      [required]="true"
                      for="purchaseDate"
                      id="purchaseDate"
                      type="date"
                      [max]="maxDate"
                      label=""
                      [value]="properties['purchaseDate']"
                      [hasError]=""
                      [placeholder]="'dd-MM-yyyy'"
                      >
                    </app-text-field>
                    <!-- Validation Error -->
                    <small
                      *ngIf="
                        assetForm.get('purchaseDate')?.invalid &&
                        (assetForm.get('purchaseDate')?.touched ||
                          assetForm.get('purchaseDate')?.dirty)
                      "
                      class="error-message"
                    >
                      This field is mandatory.
                    </small>
                  </div>
                </ng-container>
              </div>
              <hr *ngIf="assetTypeId" />
              <ng-container *ngIf="assetTypeId">
                <div *ngFor="let category of assetTypeData['categories']">
                  <h3 class="title">{{ category.displayName }}</h3>
                  <div class="categories-section">
                    <div
                      class="categories-info"
                      *ngFor="let config of getConfigsByCategory(category.name)"
                    >
                      <label class="label"
                        >{{ config.displayName
                        }}{{ config.isRequired ? " *" : "" }}</label
                      >
                      <div class="property-field">
                        <app-text-field
                          *ngIf="!config.list"
                          [type]="
                            config.valueType === 'number' ? 'number' : 'text'
                          "
                          [placeholder]="'Enter ' + config.displayName"
                          [required]="config.isRequired"
                          [formControlName]="config.name"
                          [value]="properties[config.name]"
                        >
                        </app-text-field>
                        <app-generic-drop-down
                          *ngIf="config.list"
                          [label]="config.displayName"
                          [options]="config.values"
                          [title]="
                            properties[config.name] ||
                            'Select ' + config.displayName
                          "
                          (value)="
                            properties[config.name] = $event;
                            onSelectionChange($event, config.name)
                          "
                          [valueName]="'unit'"
                          [shouldEmitOption]="true"
                        ></app-generic-drop-down>

                        <app-generic-drop-down
                          *ngIf="config.units && config.units.length"
                          [label]="config.displayName + ' Unit'"
                          [options]="config.units"
                          [title]="
                            properties[config.name + 'Unit'] ||
                            'Select ' + config.displayName + ' Unit'
                          "
                          (value)="
                            properties[config.name + 'Unit'] = $event;
                            onSelectionChange($event, config.name + 'Unit')
                          "
                          [shouldEmitOption]="true"
                        ></app-generic-drop-down>
                      </div>
                      <!-- Validation Error -->
                      <small
                        *ngIf="
                          config.isRequired &&
                          assetForm.get(config.name)?.invalid &&
                          (assetForm.get(config.name)?.touched ||
                            assetForm.get(config.name)?.dirty)
                        "
                        class="error-message"
                      >
                        This field is mandatory.
                      </small>
                    </div>
                  </div>

                  <hr />
                </div>
                <div class="button">
                  <app-button
                    class="spacing"
                    type="submit"
                    size="medium"
                    theme="primary"
                    [disabled]="assetForm.invalid"
                    (click)="showAddAssetConfirmationDialog()"
                    >{{ assetId ? "Update" : "Add" }}
                  </app-button>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
      </div>

      <!-- Form ends here -->
    </div>
  </div>
</div>
