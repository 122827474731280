import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { KeyValue } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IPopupConfig } from '@common/interfaces';
import { UploadDownloadService } from 'libs/common/src/lib/service/upload-download.service';

@Component({
  selector: 'app-generic-pop-up',
  templateUrl: './generic-pop-up.component.html',
  styleUrls: ['./generic-pop-up.component.scss'],
})
export class GenericPopUpComponent implements OnInit {
  @Input() popUpConfig!: IPopupConfig;
  @Input() formGroup!: UntypedFormGroup;
  @Input() fileSrc!: string;
  @Input() isImage: boolean = false;
  @Input() isVideo: boolean = false;
  textArea = new FormControl('', Validators.required);
  Object = Object;
  assetCode: string = '';
  errorIcon: string = 'assets/48x48/error.svg';
  ckeckMarkIcon: string = 'assets/48x48/checked.svg';
  warningIcon: string = 'assets/48x48/warning.svg';
  iconType!: string;
  originalOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    return 0;
  };
  uploadCounts: any;
  hasSummary: boolean = false;
  countData: any;
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public data: IPopupConfig
  ) { }
  keys!: string[];
  fieldKeys!: string[];
  ngOnInit() {
    this.popUpConfig = this.data;
    if (this.popUpConfig?.headerIcon === 'error') {
      this.iconType = this.errorIcon;
    } else if (this.popUpConfig?.headerIcon === 'warning') {
      this.iconType = this.warningIcon;
    } else if (this.popUpConfig?.headerIcon === 'success') {
      this.iconType = this.ckeckMarkIcon;
    }
    if (this.popUpConfig.data) {
      this.keys = Object.keys(this.popUpConfig?.data);
    }
    this.uploadCounts = this.popUpConfig?.data;
    this.hasSummary = this.popUpConfig?.hasSummary || false;
  }

  handleAction(actionType: string) {
    if (actionType === 'Verify Asset') {
      this.dialogRef.close([actionType, this.assetCode])
    }
    else if (actionType === 'Check In') {
      this.dialogRef.close([actionType, this.textArea.value])
    }
    else {
      this.dialogRef.close(actionType);
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
  displayCount($event: any) {
    this.countData = $event;
  }
}
