import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import {
  getTicketsTableData,
  ITicketSnackbarConfig,
  TICKET_SORT_COLUMNS,
  ROUTES,
  MODALS
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { ModalService } from 'libs/common/src/lib/service/modal.service';
import { TableComponent } from 'libs/shared/ui-components/src/lib/table/table.component';
@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.scss'],
})
export class EmployeeDashboardComponent implements OnInit {
  content: string = '';
  color: string = '';
  alertBox: boolean = false;
  userDetails: any;
  assets: any;
  userData: any;
  icon: string = '';
  ticket: string = '';
  tableDetails: any;
  count!: number;
  assetDetail: any[] = [];
  ticketDetail: any[] = [];
  user: any[] = [];
  pageSize: string = '5';
  status: string = '';
  alertBoxUser: any;
  @ViewChild('table') _tableDetails!: TableComponent;
  filterOptions = [
    { title: 'Ongoing', value: 'ONGOING' },
    { title: 'To-Do', value: 'TO_DO' },
    { title: 'On-Hold', value: 'ON_HOLD' },
    { title: 'Closed', value: 'CLOSED' },
    { title: 'All Tickets', value: '' },
  ];
  sortDigit = 'DESC';
  currentPageNumber = '1';
  currentParams: any;
  snackBarConfig!: ITicketSnackbarConfig;
  loaderView: boolean = false;
  ticketStatusClicked: boolean = true;
  tableConfig = [
    {
      title: 'Ticket no.',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isTicket: true,
        keys: [
          {
            key: 'ticketNumber',
            from: 'ticket',
            handleAction: (ticketId: string) => {
              this.navigateToTicket(ticketId);
            },
          },
          { key: 'title', from: 'ticket' },
        ],
      },
    },
    {
      title: 'Asset tag',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isAssetTag: true,
        keys: [
          { key: 'assetCode', from: 'asset' },
          { key: 'name', from: 'asset' },
        ],
      },
    },
    {
      title: 'Status',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [{ key: 'status', from: 'ticket', isStatus: true }],
        isTicket: true,
      },
    },
    {
      title: 'Asset name',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'name', from: 'asset' },
        isAsset: true,
      },
    },
    {
      title: 'Ticket date',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'createdAt', from: 'ticket', isDate: true },
        isTicket: true,
      },
    },
  ];
  constructor(
    private commonHttpService: CommonHttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private router: Router,
    private modalService: ModalService
  ) { }
  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userInfo') as string);
    this.commonHttpService.getUserAsset().subscribe((res: any) => {
      this.userDetails = res.data;
    });
    this.commonService.showTicketAlert.subscribe(
      (data) => (this.alertBox = data)
    );
    this.commonService.submittedValue.subscribe((data) => {
      this.alertBoxUser = data;
    });
    this.getUserTickets();
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (this.router.url.endsWith('employee-dashboard')) {
          this.ticketStatusClicked = true;
        }
        if (this.router.url.includes('ticket/')) {
          this.ticketStatusClicked = false;
        }
      }
    });
  }
  showAlertBox(alertBoxConfig: any) {
    // this.toggleRaiseTicketModal = false;
    this.content = alertBoxConfig.content;
    this.color = alertBoxConfig.color;
    this.icon = alertBoxConfig.icon;
    this.ticket = 'View Ticket';
  }
  getColor(color: string) {
    this.color = color;
  }
  getIcon(icon: string) {
    this.icon = icon;
  }
  viewTicket(ticket: string) {
    this.ticket = ticket;
  }
  closeAlertBox() {
    this.alertBox = false;
  }
  showAlertModal(alertModalConfig: any, from: string) {
    if (from === 'raise_issue') {
      this.snackBarConfig = alertModalConfig;
      this.alertBox = true;
      this.getUserTickets(this.currentParams);
    } else if (from === 'request_an_asset') {
      this.snackBarConfig = alertModalConfig;
      this.alertBox = true;
      this.getUserTickets(this.currentParams);
    }
    else if (from === 'access_installation') {
      this.snackBarConfig = alertModalConfig;
      this.alertBox = true;
      this.getUserTickets(this.currentParams);
    }
  }
  navigateToTicket(ticketId: string) {
    this.ticketStatusClicked = false;
    this.router.navigate([ROUTES.USER_TICKET_DETAILS + ticketId]);
  }
  getUserTickets(param?: any) {
    this.loaderView = true;
    const params = {
      status: this.status || '',
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      sortColumn: param?.sortColumn || '',
      sortDirection: param?.sortDirection || '',
    };
    this.currentParams = params;
    const loadRequest = this.commonHttpService.getAllTicketsForUser(params);
    if (loadRequest) {
      loadRequest.subscribe({
        next: (response) => {
          const ticketsData = Object(response).data;
          // this.tableDetails = ticketsData.tickets;
          this.tableDetails = getTicketsTableData(ticketsData.tickets);
          this.count = ticketsData.count;
          this.commonService.searchParam.next({
            sortColumn: param?.sortColumn,
            sortDirection: param?.sortDirection,
            pageNumber: param?.pageNumber,
          });
          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
        },
      });
      this.currentPageNumber = param?.pageNumber;
      this.sortDigit = this.sortDigit === 'DESC' ? 'ASC' : 'DESC';
      this.changeDetectorRef.detectChanges();
    }
  }
  filterBy(filter: string) {
    this.status = filter;
    this.getUserTickets();
  }
  handleSortAction(col: string) {
    const param = {
      sortColumn: '',
      sortDirection: this.sortDigit,
      pageNumber: this.currentPageNumber,
    };
    const iconConfig = this.tableConfig.filter(
      (response: any) => response.title === col
    );
    iconConfig[0].icon = this.sortDigit === 'ASC' ? 'sort-asc' : 'sort-desc';
    switch (col) {
      case 'Ticket no.':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_NUMBER;
        break;
      case 'Asset Code':
        param.sortColumn = TICKET_SORT_COLUMNS.ASSET_CODE;
        break;
      case 'Status':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_STATUS;
        break;
      case 'Asset name':
        param.sortColumn = TICKET_SORT_COLUMNS.ASSET_NAME;
        break;
      case 'Ticket date':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_DATE;
        break;
      default:
        param.sortColumn = '';
        break;
    }
    this.getUserTickets(param);
  }
  pageSizeChange(pageSize: string) {
    this.pageSize = pageSize;
    this.getUserTickets();
  }
  triggerModal(modal: string) {
    if (modal === MODALS.RAISE_TICKET_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'raise_issue');
      })
    } else if (modal === MODALS.REQUEST_ASSET_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'request_an_asset');
      })
    }
    else if (modal === MODALS.ACCESS_INSTALLATION_MODAL) {
      this.modalService.triggerModal(modal)?.subscribe((result) => {
        if (result) this.showAlertModal(result, 'access_installation');
      })
    }
  }
}
