import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ISearchData, ROUTES, TICKET_PRIORITIES } from '@common/interfaces';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { paginationChange } from '@common/interfaces';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() fieldArray!: Array<{
    fieldName: string;
    type: string;
    keyType: string;
    key: any;
    icon: string;
  }>;
  @Input() tableDetails!: any[];
  @Input() displayTicketList!: boolean;
  @Input() tableData: any;
  @Input() pages: number[] = [];
  @Input() pagination: boolean = false;
  @Input() tableConfig: any;
  @Input() title!: string;
  @Input() searchOptions!: { title: string; value: string }[];
  @Input() count!: number;
  data!: any;
  @Output() tableNavigationClick = new EventEmitter<string>();
  @Output() searchData = new EventEmitter<ISearchData>();
  @Output() paginatorEmitter = new EventEmitter<any>();
  @Input() filterOptions!: any[];
  @Input('activeMenu') pageCount!: string;
  @Output() activeFilter = new EventEmitter<string>();
  @Output() pageSizeChange = new EventEmitter<string>();
  status!: string;
  @Input() counterText = '';
  filterStateOptions: { title: string; value: string }[] = [];
  pageSize = 5;
  hideFilters!: boolean;
  ticketStatusFilter!: boolean;
  approvalFilter!: boolean;
  loanStatusFilter!: boolean;
  statusFilter!: string[];
  displayColorCode: boolean = true;
  isAdmin!: boolean;
  isSemiAdmin!: boolean;
  isEkoAdmin!: boolean;
  ROUTES = ROUTES;
  placeholderTitle = 'No tickets generated yet';
  placeholderSubtitle =
    'Once you start generating tickets, they will appear here';
  placeholderIcon = 'assets/58x58/no_tickets.svg';
  @Input() hasButton: boolean = false;
  @Input() displayFilterState: boolean = false;
  @Input() currentPageNumber: number = 1;
  @Output() onFilterApply = new EventEmitter<string>();
  @Output() onSort = new EventEmitter<string>();
  pageSizeOptions = ['5', '10', '15'];
  @ViewChild('tickets') _ticketsForPagination!: PaginationComponent;
  filterStateMenu!: string;
  constructor(private router: Router, private _commonService: CommonService) {
    this.hideFilters =
      this.router.url.includes('ticket')  || this.router.url.includes('admin-approval/coditas')
      this.router.url.includes('inventory') || this.router.url.includes('manage-workspace') ;
    this.loanStatusFilter = this.router.url.includes('inventory');
    this.ticketStatusFilter = this.router.url.includes('admin-ticket');
    this.approvalFilter = this.router.url.includes('admin-approval')
    if (this.ticketStatusFilter || this.approvalFilter) {
      this.statusFilter = ['To-Do','On-Hold', 'Ongoing', 'Closed', 'All Tickets'];
    }
    if (this.loanStatusFilter) {
      this.statusFilter = [
        'Assigned',
        'Available',
        'Out for Repair',
        'All Assets',
      ];
    }
  }

  ngDoCheck() {
    const userDetails = JSON.parse(localStorage.getItem('userInfo') as string);
    if (
      userDetails.roles[0] === 'USER' ||
      this.router.url === ROUTES.ADMIN_TICKET_PERSONAL
    ) {
      this.displayColorCode = false;
    } else {
      this.displayColorCode = true;
    }
    if (this.loanStatusFilter) {
      this.getPlaceholderConfig('assets');
    } else {
      this.getPlaceholderConfig('tickets');
    }
  }

  ngOnInit(): void {
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'ADMIN';
    this.isSemiAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'SEMI-ADMIN';
    this.isEkoAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'EKO-ADMIN';
    if (this.router.url === ROUTES.ADMIN_TICKET_PERSONAL && ROUTES.ADMIN_APPROVAL_PERSONAL && ROUTES.USER_APPROVAL) {
      this.hideFilters = false;
    }
    if (this.loanStatusFilter) {
      this.getPlaceholderConfig('assets');
    } else {
      this.getPlaceholderConfig('tickets');
    }

    const pageCount = sessionStorage.getItem('pageSize');
    if (pageCount) {
      this.pageCount = pageCount;
    }
  }

  showFilterState() {
    if (this.isAdmin) {
      const routerUrl = this.router.url.split('=')[1]?.split('&')[0];
      const wordsNeededToBePresentOnRouterUrl = [
        'ALL',
        'CLOSED',
        'ONGOING',
        'EXTERNAL',
      ];
      if (
        ((wordsNeededToBePresentOnRouterUrl.includes(routerUrl) &&
          this.router.url !== '/home/inventory?status=ALL') ||
          this.router.url === `/home/admin-ticket` ||
          this.router.url.includes('ticketType')) &&
        this.router.url !==
          `/home/admin-ticket/admin-employee-dashboard?ticketType=assigned`
      ) {
        if (this.ticketStatusFilter) {
          this.filterStateOptions = [
            { title: 'Assigned', value: 'assigned' },
            { title: 'All tickets', value: 'all' },
          ];
          this.filterStateMenu = 'all';
        }
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  addStatusClass(status: string) {
    return {
      'badge-ongoing': status === 'ONGOING',
      'badge-todo': status === 'TO_DO',
      'badge-closed': status === 'CLOSED',
      'badge-onhold': status==='ON_HOLD'
    };
  }

  search(searchData: ISearchData) {
    this.searchData.emit(searchData);
  }

  gotoAssetDetails(AssetId: any) {
    this._commonService.AssetId.next(AssetId);
    this.router.navigate(['/home/inventory/asset-details']);
  }

  paginationEmitter(params: any) {
    paginationChange.next(true);
    this.paginatorEmitter.emit(params);
  }

  pageSizeEmitter(index: number) {
    paginationChange.next(true);
    this.pageSize = +this.pageSizeOptions[index];
    sessionStorage.setItem('pageSize', this.pageSize.toString());
    this.pageSizeChange.emit(`${this.pageSize}`);
  }
  getSubtitle(subtitle: string) {
    return subtitle.length > 15 ? subtitle.slice(0, 12) + '...' : subtitle;
  }
  sendFilter(filterType: string) {
    if (!this.hideFilters) {
      this.activeFilter.emit(filterType);
    }
  }
  getPriorityClass(priority: string) {
    if (priority == TICKET_PRIORITIES.HIGH) {
      return 'high';
    } else if (priority == TICKET_PRIORITIES.MEDIUM) {
      return 'medium';
    } else if (priority == TICKET_PRIORITIES.LOW) {
      return 'low';
    } else {
      return '';
    }
  }

  getPlaceholderConfig(type?: string) {
    this.status = this.router.url.split('=')[1];
    if (!this.isAdmin) {
      this.placeholderTitle = 'No tickets generated yet';
      this.placeholderSubtitle =
        'Once you start generating tickets, they will appear here';
    }

    if (this.isAdmin && type === 'tickets') {
      this.placeholderIcon = 'assets/58x58/no_tickets.svg';
      this.placeholderTitle = 'No tickets generated yet';
      this.placeholderSubtitle =
        'Once you start generating tickets, they will appear here';
      switch (this.status) {
        case 'CLOSED':
          this.placeholderTitle = 'No Closed Tickets';
          this.placeholderSubtitle = 'There are no closed tickets';
          break;
        case 'TO_DO':
          this.placeholderTitle = 'No To-DO Tickets';
          this.placeholderSubtitle = 'There are no To-Do tickets';
          break;
        case 'ONGOING':
          this.placeholderTitle = 'No Ongoing Tickets';
          this.placeholderSubtitle = 'There are no ongoing tickets';
          break;
          case 'ON_HOLD':
            this.placeholderTitle = 'No On-HOLD Tickets';
            this.placeholderSubtitle = 'There are no On-Hold tickets';
            break;
        case 'EXTERNAL':
          this.placeholderTitle = 'No External Tickets';
          this.placeholderSubtitle = 'There are no external tickets';
          break;
        case 'ALL':
          this.placeholderTitle = 'No tickets generated yet';
          this.placeholderSubtitle =
            'Once you start generating tickets, they will appear here';
          break;
      }
    } else if ((this.isAdmin || this.isEkoAdmin || this.isSemiAdmin) && type === 'assets') {
      this.placeholderTitle = 'No Assets';
      this.placeholderSubtitle = 'There are no assets to show';
      this.placeholderIcon = 'assets/58x58/no_assets.svg';
      switch (this.status) {
        case 'ALLOCATED':
          this.placeholderTitle = 'No Assigned Assets';
          this.placeholderSubtitle = 'There are no assinged assets';
          break;
        case 'AVAILABLE':
          this.placeholderTitle = 'No Available Assets';
          this.placeholderSubtitle = 'There are no available assets';
          break;
        case 'DISABLED':
          this.placeholderTitle = 'No Disabled Assets';
          this.placeholderSubtitle = 'There are no disabled assets';
          break;
        case 'OUT_FOR_REPAIR':
          this.placeholderTitle = 'No Out-for-repair Assets';
          this.placeholderSubtitle = 'There are no out for repair Assets';
          break;
        case 'ALL':
          this.placeholderTitle = 'No Assets';
          this.placeholderSubtitle = 'There are no assets to show';
          break;
      }
    }
  }

  onSelectFilter(filter: any) {
    this.onFilterApply.emit(filter);
  }

  onNavigationById(ticketId: string) {
    this.tableNavigationClick.emit(ticketId);
  }
  onSortAction(fieldName: string) {
    this.onSort.emit(fieldName);
  }
}
