<div
  class="asset-container"
  [ngClass]="{
    loader: loaderView && !isDashboard && isAssetTypeCounter,
    'dashboard-container': isDashboard || displayDashboardContainer
  }"
>
  <div class="loader-container" *ngIf="loaderView && isAssetTypeCounter">
    <img src="assets/32x32/coditas.gif" class="loader-logo" loading="lazy" />
  </div>

  <div class="asset-header">
    <div class="asset-heading">
      <p>{{ title }}</p>
      <app-category
        *ngIf="!isDashboard && !isAssetTypeCounter && (isAdmin || isSemiAdmin)"
        [title]="titleForClientDropDown"
        (selectClient)="onSelectClient($event)"
        [title]="titleForClientDropDown"
      ></app-category>
    </div>
    <div class="button">
      <ng-container
        *stessaHasPermission="'INVENTORY__ASSET_STATS__DOWNLOAD_ASSET_BUTTON'"
      >
        <app-button
          *ngIf="showButton"
          theme="tertiary"
          size="semi-large"
          (click)="onDownload()"
          [disabled]="isDownloadButtonDisabled()"
        >
          <div class="download-btn">
            <img src="assets/15x15/Icondownload-icon.svg" />
            <span class="download-btn-text">{{ buttonText }}</span>
          </div>
        </app-button>
      </ng-container>
      <ng-container
        *stessaHasPermission="'INVENTORY__ASSET_STATS__ADD_ASSET_BUTTON'"
      >
        <app-button
          *ngIf="hasAddAsset && (isAdmin || isEkoAdmin)"
          class="spacing"
          type="submit"
          [disabled]="isDisabled"
          size="medium"
          theme="primary"
          routerLink="asset"
          (click)="addAssetModal($event)"
          >Add Asset
        </app-button>
      </ng-container>
      <ng-container>
        <app-button
          *ngIf="showWorkSpace"
          class="workspace-button"
          type="submit"
          size="semi-large"
          theme="primary"
          routerLink="create-workspace"
          (click)="CreateWorkspaceModal($event)"

          >Create Workspace
        </app-button>
      </ng-container>
    </div>
  </div>

  <div
    class="wrapper"
    *ngIf="isDashboard || !isAssetTypeCounter"
    [ngClass]="{
      'dashboard-container': !isDashboard,
      inventory: buttonText === 'Download Asset List'
    }"
    [@basicAnimation]
  >
    <div *ngFor="let asset of assets" class="asset-group">
      <a
        class="asset-group-container"
        [ngClass]="
          (asset.status === status || (!status && asset.status === 'ALL')) &&
          !isAssetTypeCounter &&
          !isDashboard
            ? 'card-selected'
            : 'card-unselected'
        "
        [routerLink]="[asset.route]"
        (click)="setAssetPageParam(asset.status)"
        [queryParams]="{ status: asset.status, provider: asset.provider }"
      >
        <div class="asset">
          <span class="asset-id">{{ asset.title }}</span>
        </div>
        <div class="asset">
          <span class="asset-name">{{
            asset.count < 10 ? "0" + asset.count : asset.count || "00"
          }}</span>
        </div>
      </a>
    </div>
  </div>
  <div
    class="table-container"
    *ngIf="isAssetTypeCounter"
    [@basicAnimation]
    [ngClass]="{ 'dashboard-container': isDashboard }"
  >
  <table class="table" cdk-table [dataSource]="dataSource">
    <!-- Assets Column -->
    <ng-container cdkColumnDef="assets">
      <th cdk-header-cell *cdkHeaderCellDef class="table-head assets">
        Assets ({{ displayedColumnsCount.totalAssets }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="table-data do-flex">
        <span>
          <img
            src="assets/asset-list-dashboard/{{ element.assetType.toLowerCase() }}.svg"
            alt="{{ element.assetType }}"
          />
        </span>
        <p class="count-with-asset-type asset-name">
          <span class="asset-type">
            <a (click)="handleAssetClick(element.assetType)">{{ element.assetType }}</a>
          </span>
          <span class="asset-count">({{ element.totalByType }})</span>
        </p>
      </td>
    </ng-container>

    <!-- Assigned Column -->
    <ng-container cdkColumnDef="assigned">
      <th cdk-header-cell *cdkHeaderCellDef class="table-head">
        Assigned ({{ displayedColumnsCount.assignedAssets }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="table-data">
        {{ element.allocated }}
      </td>
    </ng-container>

    <!-- Unassigned Column -->
    <ng-container cdkColumnDef="unAssigned">
      <th cdk-header-cell *cdkHeaderCellDef class="table-head">
        Unassigned ({{ displayedColumnsCount.unAssignedAssets }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="table-data">
        {{ element.available }}
      </td>
    </ng-container>

    <!-- Out For Repair Column -->
    <ng-container cdkColumnDef="outForRepair">
      <th cdk-header-cell *cdkHeaderCellDef class="table-head">
        Out For Repair ({{ displayedColumnsCount.outForRepairAssets }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="table-data">
        {{ element.outForRepair }}
      </td>
    </ng-container>

    <!-- In House Repair Column -->
    <ng-container cdkColumnDef="inHouseRepair">
      <th cdk-header-cell *cdkHeaderCellDef class="table-head">
        In House Repair ({{ displayedColumnsCount.inHouseRepairAssets }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="table-data">
        {{ element.inHouseRepair }}
      </td>
    </ng-container>

    <!-- Disabled Column -->
    <ng-container cdkColumnDef="disabled">
      <th cdk-header-cell *cdkHeaderCellDef class="table-head">
        Disabled ({{ displayedColumnsCount.disabledAssets }})
      </th>
      <td cdk-cell *cdkCellDef="let element" class="table-data">
        {{ element.disabled }}
      </td>
    </ng-container>

    <!-- Header and Rows -->
    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns"
      class="table-head-row"
    ></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns"
      class="table-body-row"
    ></tr>
  </table>

  </div>
</div>
