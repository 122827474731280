import { Component, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-vendor-search',
  templateUrl: './vendor-search.component.html',
  styleUrls: ['./vendor-search.component.scss']
})
export class VendorSearchComponent {
  @Output() searchData = new EventEmitter<any>();

  searchStr: string = '';
  activeCol: { title: string; value: string }[] = [];
  searchOptions = [
    { title: 'Vendor Name', value: 'VENDOR_NAME' }
  ];

  // private searchSubject = new Subject<string>();

  // constructor() {
  //   this.searchSubject.pipe(
  //     debounceTime(300) // Adjust the debounce time as needed
  //   ).subscribe(() => {
  //     this.emitSearchData();
  //   });
  // }

  changeIndex(event: any) {
    this.activeCol = this.searchOptions.filter(
      (option) => option.value === event
    );
  }

  removeClick() {
    this.searchStr = '';
    this.emitSearchData();
  }

  search() {
    this.emitSearchData();
  }

  onInputChange() {
    this.emitSearchData();
    }

  private emitSearchData() {
    this.searchData.emit({
      searchBy: this.activeCol[0]?.value || 'VENDOR_NAME',
      searchText: this.searchStr
    });
  }
}
