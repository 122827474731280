import {
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import {
  HISTORY_HEADER_TITLES,
  HISTORY_TRANSACTION_STATUS,
  HISTORY_TYPES,
  ROUTES,
} from '@common/interfaces';
import { retry } from 'rxjs';
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent implements OnInit {
  @Input() stepData!: any[];
  @Input() historyType!: any;
  expandedIndex = 0;
  constructor(private router: Router) {}
  ngOnInit(): void {}

  formatMonthAndYear(info: any) {
    const formatedMonthAndYear: string = '';
    let startMonthAndYear = new DatePipe('en-us').transform(
      info[0]?.createdAt,
      'MMM yyyy'
    );
    let endMonthAndYear = new DatePipe('en-us').transform(
      info[1]?.createdAt,
      'MMM yyyy'
    );
    if (endMonthAndYear) {
      if (startMonthAndYear === endMonthAndYear) {
        return startMonthAndYear;
      }
      return endMonthAndYear + ' - ' + startMonthAndYear;
    } else {
      return startMonthAndYear;
    }
  }
  getHeaderTitle(info: any) {
    let title!: string;
    if (this.historyType === HISTORY_TYPES.LOANEE_HISTORY) {
      title = HISTORY_HEADER_TITLES.LOANEE;
    } else {
      title = '';
      info.forEach((item: any) => {
        if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_IN_HOUSE_REPAIR
        ) {
          title = HISTORY_HEADER_TITLES.IN_HOUSE_REPAIR;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_OUT_FOR_REPAIR
        ) {
          title = HISTORY_HEADER_TITLES.OUT_FOR_REPAIR;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_E_WASTE
        ) {
          title = HISTORY_HEADER_TITLES.CHECKOUT_E_WASTE;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_NOT_IN_USE
        ) {
          title = HISTORY_HEADER_TITLES.CHECKOUT_NOT_IN_USE;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKIN_OUT_FOR_REPAIR
        ) {
          title = HISTORY_HEADER_TITLES.CHECKIN_OUT_FOR_REPAIR;
        }
      });
    }
    return title !== '' ? title + ': ' : '';
  }

  getUserName(info: any) {
    switch (info?.transactionStatus) {
      case HISTORY_TRANSACTION_STATUS.CHECKIN_USER:
        return info?.checkedInBy?.firstName + ' ' + info?.checkedInBy?.lastName;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_USER:
        return info?.user?.firstName + ' ' + info?.user?.lastName;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_IN_HOUSE_REPAIR:
        return info?.assignee?.firstName + ' ' + info?.assignee?.lastName;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_OUT_FOR_REPAIR:
        return (
          info?.checkedOutBy?.firstName + ' ' + info?.checkedOutBy?.lastName
        );
      case HISTORY_TRANSACTION_STATUS.CHECKIN_IN_HOUSE_REPAIR:
        return info?.checkedInBy?.firstName + ' ' + info?.checkedInBy?.lastName;
      case HISTORY_TRANSACTION_STATUS.CHECKIN_OUT_FOR_REPAIR:
        return info?.checkedInBy?.firstName + ' ' + info?.checkedInBy?.lastName;
      case HISTORY_TRANSACTION_STATUS.CHECKIN_NOT_IN_USE:
        return info?.checkedInBy?.firstName + ' ' + info?.checkedInBy?.lastName;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_NOT_IN_USE:
        return (
          info?.checkedOutBy?.firstName + ' ' + info?.checkedOutBy?.lastName
        );
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_E_WASTE:
        return (
          info?.checkedOutBy?.firstName + ' ' + info?.checkedOutBy?.lastName
        );
      default:
        return 'Not Available';
    }
  }
  getHeader(info: any) {
    let label;
    if (this.historyType === HISTORY_TYPES.LOANEE_HISTORY) {
      label = 'Unassigned';
      info.forEach((item: any) => {
        if (
          item?.transactionStatus !== HISTORY_TRANSACTION_STATUS.CHECKIN_USER
        ) {
          label = item?.user?.firstName + ' ' + item?.user?.lastName;
        }
      });
    } else {
      label = '';
      info.forEach((item: any) => {
        if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_IN_HOUSE_REPAIR
        ) {
          label = item?.assignee?.firstName + ' ' + item?.assignee?.lastName;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_OUT_FOR_REPAIR
        ) {
          label =
            item?.checkedOutBy?.firstName + ' ' + item?.checkedOutBy?.lastName;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_E_WASTE
        ) {
          label =
            item?.checkedOutBy?.firstName + ' ' + item?.checkedOutBy?.lastName;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKOUT_NOT_IN_USE
        ) {
          label =
            item?.checkedOutBy?.firstName + ' ' + item?.checkedOutBy?.lastName;
        } else if (
          item?.transactionStatus ===
          HISTORY_TRANSACTION_STATUS.CHECKIN_OUT_FOR_REPAIR
        ) {
          label =
            item?.checkedInBy?.firstName + ' ' + item?.checkedInBy?.lastName;
        }
      });
    }
    return label;
  }
  navigateToProfile(info: any) {
    let userId: string = '';
    switch (info?.transactionStatus) {
      case HISTORY_TRANSACTION_STATUS.CHECKIN_USER:
        userId = info?.checkedInBy?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_USER:
        userId = info?.user?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_IN_HOUSE_REPAIR:
        userId = info?.assignee?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_OUT_FOR_REPAIR:
        userId = '';
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKIN_IN_HOUSE_REPAIR:
        userId = info?.checkedInBy?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKIN_OUT_FOR_REPAIR:
        userId = info?.checkedInBy?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKIN_NOT_IN_USE:
        userId = info?.checkedInBy?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_NOT_IN_USE:
        userId = info?.checkedOutBy?.id;
        break;
      case HISTORY_TRANSACTION_STATUS.CHECKOUT_E_WASTE:
        userId = info?.checkedOutBy?.id;
        break;
      default:
        break;
    }
    if (userId) {
      this.router.navigate([ROUTES.LOANEE_PROFILE, userId]);
    }
  }
}
