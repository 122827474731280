import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import {
  IIssuesList,
  IRaiseAnIssueFormData,
  ITicketSnackbarConfig,
  ROUTES,
} from '@common/interfaces';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';

@Component({
  selector: 'app-access-installation-modal',
  templateUrl: './access-installation-modal.component.html',
  styleUrls: ['./access-installation-modal.component.scss'],
})
export class AccessInstallationModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Output() alertBoxEmitter = new EventEmitter();
  countData!: number[];
  constructor(
    private commonHttpService: CommonHttpService,
    private dialogRef: DialogRef,
    private dialog: Dialog,
    private router: Router,
    private commonService: CommonService
  ) {}

  isDisable: boolean = false;
  asset: any;
  options: any[] = ['No records found...'];
  locations: any;
  selectedAsset!: string;
  issueArray: string[] = [];
  issues!: string;
  isAssetSelected: boolean = false;
  asset_type: string = '';
  subCategoryOptions!: IIssuesList[];
  otherOptionId!: string;
  assetTypes: any;
  userDetails: any;
  assetTypeId!: string;
  asset_id: string[] = [];
  isAdmin!: boolean;
  subCategories: string[] = [];
  popupForm = new UntypedFormGroup({
    location: new UntypedFormControl('', [Validators.required]),
    option: new UntypedFormControl('', [Validators.required]),
    subCategories: new UntypedFormControl(this.issueArray, [
      Validators.required,
    ]),
    description: new UntypedFormControl('', [
      Validators.required,
      CommonService.cannotContainSpace,
      Validators.minLength(3),
    ]),
  });
  ngOnInit(): void {
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') as string).roles[0] ===
      'ADMIN';
    this.getLocations();
    this.getAssetTypes();
    this.commonHttpService.getUserAsset().subscribe({
      next: (res) => {
        this.userDetails = Object(res).data;
        if (this.userDetails.length) {
          let tempIndexes = [];
          this.options.pop();
          const mobileLaptopAssets = this.userDetails.filter((user: any) => {
            if (
              user.asset.assetCode.split('-')[4] === 'L' ||
              user.asset.assetCode.split('-')[4] === 'MB'
            ) {
              this.options.push({
                assetName: user.asset.name,
                assetCode: user.asset.assetCode,
                assetType: user.asset.asset_type?.assetTypeCode,
              });
              return user;
            }
          });
          this.userDetails = mobileLaptopAssets;
        }
      },
      error: (err) => {
      },
    });
  }

  getAssetTypes() {
    this.commonHttpService.getAllAssetType().subscribe({
      next: (response) => {
        this.assetTypes = Object(response).data;
        // const laptopMobileAssetType = Object(response).data;
        // const tempAssetTypes = [];
        // for (let i = 0; i < laptopMobileAssetType.length; i++) {
        //   if (
        //     laptopMobileAssetType[i].assetTypeCode === 'L' ||
        //     laptopMobileAssetType[i].assetTypeCode === 'MB'
        //   ) {
        //     tempAssetTypes.push(laptopMobileAssetType[i]);
        //   }
        // }
        // this.assetTypes = tempAssetTypes;
      },
    });
  }
  close() {
    this.popupForm.reset();
    this.closeModal.emit('access-installation-modal');
  }

  getValue(value: any, type: string) {
    if (type === 'option') {
      this.asset_id = [];
      this.issueArray = [];
      this.issues = '';
      this.commonService.resetMultiSelect.next(true);
      this.popupForm.get('option')?.setValue(value?.assetName);

      this.isAssetSelected = true;
      this.assetTypes.forEach((asset: any) => {
        if (value.assetType === asset?.assetTypeCode) {
          this.assetTypeId = asset?.id;
        }
      });
    }
    if (type === 'subCategory') {
      this.popupForm.get('subCategories')?.setValue(value);
      this.issueArray = [...value[0]];

      this.issues = value[1];
      this.isAssetSelected = true;
    }
    if (type === 'location') {
      this.popupForm.get('location')?.setValue(value);
    }
  }

  getIndex(index: number) {
    this.asset_id.push(this.userDetails[index].asset.id);
    this.asset_type = this.userDetails[index].asset.asset_type.id;

    const endPoint = `?ticketType=ACCESS/INSTALLATION&assetTypeId=${this.asset_type}`;
    this.commonHttpService.getSubCategories(endPoint).subscribe((response) => {
      this.subCategoryOptions = [...response.data].map((item) => {
        if (item.subCategory === 'Other') {
          this.otherOptionId = item.id;
        }
        item.isChecked = false;
        return item;
      });
    });
  }

  getLocations() {
    this.commonHttpService.getAssestLocationsList().subscribe({
      next: ({ data }: any) => {
        if (data) {
          this.locations = data.map(({attributeName, attributeValue}:any) => ({
              locationKey: attributeName,
              locationValue: attributeValue,
            }))
        }
      },
      error: (err) => {},
    });
  }

  onSubmit() {
    this.openConfirmationDialog();
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response.buttonText === 'Submit Form') {
        this.isDisable = true;
        this.popupForm.get('subCategories')?.setValue(this.issueArray);
        const raiseAnIssueFormData = {} as IRaiseAnIssueFormData;
        raiseAnIssueFormData['location'] = this.popupForm.value.location;
        raiseAnIssueFormData['description'] =
          this.popupForm.value.description.trim();
        raiseAnIssueFormData['assets'] = [...this.asset_id];
        raiseAnIssueFormData['type'] = 'ACCESS/INSTALLATION';
        raiseAnIssueFormData['subCategories'] = [...this.issueArray];
        raiseAnIssueFormData['tempFileKeys'] = [];
        raiseAnIssueFormData['assetType'] = this.assetTypeId;
        raiseAnIssueFormData['title'] = 'Access/Installation Requested';

        this.commonHttpService.raiseIssue(raiseAnIssueFormData).subscribe({
          next: (response) => {
            if (response instanceof HttpResponse) {
              const { data } = response.body;
              this.issueArray = data.ticket_sub_categories.map(
                (category: { sub_category: { subCategory: any } }) => {
                  return category.sub_category.subCategory;
                }
              );
              const alertBoxConfig = {
                ticketId: data.id,
                content: 'Your ticket has been generated successfully.',
                color: '#ECFDF3',
                icon: 'tick',
                status: 'success',
                linkText: 'View Ticket',
                showOnlySnackbar: false,
                hasMargin: true,
              };
              const popUpConfig = {
                headerIcon: 'success',
                title: 'Ticket Generated',
                subtitle:
                  'Ticket for reported an issue has been generated successfully',
                data: {
                  'Asset Details': {
                    'Asset Name': data.assetName,
                    'Asset ID': data.assetCode,
                  },
                  'Issue Description': {
                    // 'Issue Type': this.issues,
                    Description: data.description,
                  },
                  'Ticket Details': {
                    'Ticket No.': `#${data.ticketNumber}`,
                  },
                },
                primaryButtonText: 'Proceed to Dashboard',
                secondaryButtonText: 'View Ticket',
              };
              const dialogRef = this.dialog.open<string>(
                GenericPopUpComponent,
                {
                  panelClass: 'center-dialog-box',
                  backdropClass: 'backdrop',
                  data: popUpConfig,
                  width: '50%',
                }
              );
              dialogRef.closed.subscribe((result) => {
                if (result === 'Proceed to Dashboard') {
                  if (this.isAdmin) {
                    this.router.navigate([
                      ROUTES.ASSET_TEAM_DASHBOARD_PERSONAL,
                    ]);
                    this.dialogRef.close(alertBoxConfig);
                  } else {
                    this.router.navigate([ROUTES.USER_DASHBOARD]);
                    this.dialogRef.close(alertBoxConfig);
                  }
                } else if (result === 'View Ticket') {
                  this.router.navigate([ROUTES.TICKET_DETAILS + data.id]);

                  this.dialogRef.close(alertBoxConfig);
                }
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            const selectedAsset = [...this.userDetails].filter(
              (el) => el.asset.name === this.popupForm.value.option
            )[0];
            const alertBoxConfig = {
              content: 'Ticket could not be generated.',
              color: '#FFFBFA',
              icon: 'fail',
              status: 'fail',
              linkText: '',
              showOnlySnackbar: false,
              popupAlertConfig: {
                assetDetails: {
                  assetName: this.popupForm.value.option,
                  assetId: selectedAsset.asset.assetCode,
                },
                issueDescription: {
                  description: this.popupForm.value.description,
                },
                isErrorState: true,
              },
            };

            const popUpConfig = {
              headerIcon: 'error',
              title: 'Ticket could not be generated',
              subtitle:
                'Looks like you lost your connection. Please check your connection and try again.',
              data: {
                'Asset Details': {
                  'Asset Name': this.popupForm.value.option,
                  'Asset ID': selectedAsset.asset.assetCode,
                },
                'Issue Description': {
                  // 'Issue Type': this.issues,
                  Description: this.popupForm.value.description,
                },
              },
              primaryButtonText: 'Cancel',
              secondaryButtonText: 'Try Again',
            };
            const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
              panelClass: 'center-dialog-box',
              backdropClass: 'backdrop',
              data: popUpConfig,
              width: '50%',
            });
            dialogRef.closed.subscribe((result) => {
              if (result === 'Try Again') {
                this.isDisable = false;
              } else {
                this.dialogRef.close();

                if (this.isAdmin) {
                  this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
                } else {
                  this.router.navigate([ROUTES.USER_DASHBOARD]);
                }
              }
            });
          },
        });
      }
    });
  }

  displayCount(value: any) {
    this.countData = value;
  }
  openCloseConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response:any) => {
      if (response.buttonText === 'Discard') {
        this.popupForm.reset();
        this.dialogRef.removePanelClass('dialog-box');
        this.dialogRef.addPanelClass('close-dialog');
        const current = this;
        setTimeout(() => {
          current.dialogRef.close();
        }, 1000);
      }
    });
  }
}
