
<section class="wrapper" [ngClass]="{ loader: loaderView }">
    <app-loader class="loader" *ngIf="loaderView; else popupHeader"></app-loader>
    <ng-template #popupHeader>
    <section class="popup-header">
      <div class="header-text">
        <img class="profile-image" [src]="userDetails.profilePictureUrl ? userDetails.profilePictureUrl : 'assets/40x40/user-default.svg'"  alt=""/>
        <div class="header">
          <h1 class="primary-header user-full-name">{{ userDetails.firstName }} {{ userDetails.lastName }}</h1>
          <div>
            <span class="attribute-name">Employee ID:</span>
            <span class="attribute-value">{{ userDetails.employeeId }}</span>
          </div>
          <div>
            <span class="attribute-name">User's Role:</span>
            <span class="attribute-value">{{
              userDetails.role
            }}</span>
          </div>
        </div>
  
      </div>
      <div class="edit-button" (click)="onEditUserRole()">
        <img
        src="assets/14x14/edit_blue.svg"
        alt="Edit"
        class="edit-icon"
      />
        <span >Edit User Role</span>
      </div>
      <div>
        <div class="close" (click)="closeDialog()">
          <img src="assets/24x24/close.svg" alt="" />
        </div>
      </div>
    </section>
  
    <div>
      <div class="user-detail-section">
        <span class="attribute-name">Designation</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.employeeDesignation.attributeValue; else notAvailable">
          {{ userDetails.employeeDesignation.attributeValue }}
        </span>
      </div>
      <div class="user-detail-section">
        <span class="attribute-name">Email Address</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.email; else notAvailable">
          {{ userDetails.email }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Source ID</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.sourceId; else notAvailable">
          {{ userDetails.sourceId }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Date of Joining</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.dateOfJoining; else notAvailable">
          {{ userDetails.dateOfJoining | date : "dd MMMM, yyyy" }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Employee Status</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.employeeStatus.attributeValue; else notAvailable">
          {{ userDetails.employeeStatus.attributeValue }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Employee Type</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.employeeType.attributeValue; else notAvailable">
          {{ userDetails.employeeType.attributeValue }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Work Location</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.employeeWorkLocation?.attributeValue; else notAvailable">
          {{ userDetails.employeeWorkLocation?.attributeValue }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Phone Number</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.phoneNumber; else notAvailable">
          {{ userDetails.phoneNumber }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">Reporting Manager</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.reportingManagerDetails; else notAvailable">
          {{ userDetails.reportingManagerDetails.firstName }} {{ userDetails.reportingManagerDetails.lastName }}
        </span>
      </div> 
      <div class="user-detail-section">
        <span class="attribute-name">User Onboarding</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.isUserOnboarded; else notOnboarded">
          {{ "Onboarded" }}
        </span>
        <ng-template #notOnboarded>Not Onboarded</ng-template>
      </div>
      <div class="user-detail-section">
        <span class="attribute-name">Created On</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.createdAt; else notAvailable">
          {{ userDetails.createdAt | date : "dd MMMM, yyyy" }}
        </span>
      </div>
      <div class="user-detail-section">
        <span class="attribute-name">Updated On</span>
        <span class="colon">:</span>
        <span class="attribute-value" *ngIf="userDetails.updatedAt; else notAvailable">
          {{ userDetails.updatedAt | date : "dd MMMM, yyyy" }}
        </span>
      </div>
      <ng-template #notAvailable>Not Available</ng-template>
    </div>
  </ng-template>
</section>
