<div class="asset-team-container">
  <app-loader
    class="loader"
    *ngIf="loading || loaderView; else body"
  ></app-loader>
  <ng-template #body>
    <app-back-button></app-back-button>
    <div class="ticket-wrapper" *ngIf="ticket && ticketNo">
      <div class="left-section">
        <!-- ticket related details ***/Start/*** -->
        <div class="ticket-details" [@basicAnimation]>
          <div class="headings">
            <div>
              <h3>Ticket No. {{ ticketNo }}</h3>
              <p>
                {{
                  ticketType === TICKET_TYPES.REPAIR_OR_REPLACEMENT
                    ? "Report an Issue"
                    : ticketType === TICKET_TYPES.ACCESS_INSTALLATION
                    ? "Request access/software installation"
                    : "Requesting an Asset"
                }}
                <span *ngIf="ticketType !== TICKET_TYPES.ACCESS_INSTALLATION">
                  {{ ticket.assetTypes.name || "" }}
                </span>
              </p>
            </div>
            <div>
              <app-chip [status]="ticket.ticketDetails.status"></app-chip>
            </div>
          </div>

          <!-- Role based permissions for ticket actions EKO-ADMIN or ADMIN - (START HERE) -->
          <ng-container
            *ngIf="ticketType === TICKET_TYPES.REPAIR_OR_REPLACEMENT"
          >
            <ng-container
              *ngIf="ticket.assetDetails.assetId?.startsWith('CD-EKO')"
            >
              <div
                class="buttons"
                *stessaHasPermission="
                  'EKO__TICKETS__TICKET_DETAILS__EDIT_BUTTON'
                "
              >
                <app-button
                  size="larger"
                  theme="tertiary"
                  class="edit-btn"
                  [ngClass]="{
                    disabled:
                      ticket.ticketDetails.status === TICKET_STATUS.CLOSED ||
                      ticket.ticketDetails.status === TICKET_STATUS.ON_HOLD
                  }"
                  [disabled]="
                    ticket.ticketDetails.status === TICKET_STATUS.CLOSED ||
                    ticket.ticketDetails.status === TICKET_STATUS.ON_HOLD
                  "
                  (click)="onEditTicket()"
                >
                  <span>
                    <img [src]="editButtonImage" class="edit-btn-img" />
                  </span>
                  <span class="disable-button-text">Edit</span>
                </app-button>
                <app-button
                  size="largest"
                  theme="primary"
                  class="check-in-btn"
                  [ngClass]="{ disabled: getDisableStatus() }"
                  [disabled]="getDisableStatus()"
                  (click)="handleClick()"
                  >{{ textOnCheckInButton }}</app-button
                >
              </div>
            </ng-container>
            <div
              class="buttons"
              *stessaHasPermission="'TICKETS__TICKET_DETAILS__EDIT_BUTTON'"
            >
              <app-button
                size="larger"
                theme="tertiary"
                class="edit-btn"
                [ngClass]="{
                  disabled:
                    ticket.ticketDetails.status === TICKET_STATUS.CLOSED ||
                    ticket.ticketDetails.status === TICKET_STATUS.ON_HOLD
                }"
                [disabled]="
                  ticket.ticketDetails.status === TICKET_STATUS.CLOSED ||
                  ticket.ticketDetails.status === TICKET_STATUS.ON_HOLD
                "
                (click)="onEditTicket()"
              >
                <span>
                  <img [src]="editButtonImage" class="edit-btn-img" />
                </span>
                <span class="disable-button-text">Edit</span>
              </app-button>
              <app-button
                size="largest"
                theme="primary"
                class="check-in-btn"
                [ngClass]="{ disabled: getDisableStatus() }"
                [disabled]="getDisableStatus()"
                (click)="handleClick()"
                >{{ textOnCheckInButton }}</app-button
              >
            </div>
          </ng-container>
          <!-- Role based permissions for ticket actions EKO-ADMIN or ADMIN - (END HERE) -->

          <!-- Ticket assigning feature as per ROLE -->
          <div class="alert-details">
            <div class="container">
              <div
                class="width-100"
                *ngIf="
                  ticketType === TICKET_TYPES.ASSET_ALLOCATION ||
                  TICKET_TYPES.ACCESS_INSTALLATION
                "
              >
                <h3 *ngIf="ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT">
                  Assignee Details
                </h3>
                <div
                  class="dropdown row width-100"
                  *ngIf="ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT"
                >
                  <div class="col">
                    <p>Ticket Assigned To</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div
                    class="col"
                    *stessaHasPermission="
                      'TICKETS__TICKET_DETAILS__EDIT_BUTTON'
                    "
                  >
                    <app-drop-down
                      from="raise_issue"
                      [options]="options"
                      [disabled]="
                        ticketStatus === TICKET_STATUS.CLOSED ||
                        ticketStatus === TICKET_STATUS.ON_HOLD
                    "
                      [title]="assignedTo"
                      (index)="getIndex($event)"
                      (value)="getValue($event)"
                    ></app-drop-down>
                  </div>
                  <ng-container
                    *ngIf="ticket.assetDetails.assetId.startsWith('CD-EKO')"
                  >
                    <div
                      class="col"
                      *stessaHasPermission="
                        'EKO__TICKETS__TICKET_DETAILS__EDIT_BUTTON'
                      "
                    >
                      <app-drop-down
                        from="raise_issue"
                        [options]="options"
                        [disabled]="ticketStatus === TICKET_STATUS.CLOSED"
                        [title]="assignedTo"
                        (index)="getIndex($event)"
                        (value)="getValue($event)"
                      ></app-drop-down>
                    </div>
                  </ng-container>
                  <div class="col" *ngIf="!isAdmin">
                    <p *ngIf="assignedTo !== 'Unassigned'">
                      {{ assignedTo }}
                    </p>
                    <p *ngIf="assignedTo === 'Unassigned'">Unassigned</p>
                  </div>
                </div>
              </div>

              <div class="width-100 ticket-details">
                <h3>Ticket Details</h3>
                <div
                  class="row width-100"
                  *ngIf="ticketType === TICKET_TYPES.REPAIR_OR_REPLACEMENT"
                >
                  <div class="col">
                    <p>Assigned To</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p *ngIf="assignedTo !== 'Unassigned' && isAdmin">
                      <a routerLink="/home/employee-details/{{ assigneeId }}">{{
                        assignedTo
                      }}</a>
                    </p>
                    <p *ngIf="assignedTo !== 'Unassigned' && !isAdmin">
                      {{ assignedTo }}
                    </p>
                    <p *ngIf="assignedTo === 'Unassigned'">Unassigned</p>
                  </div>
                </div>
                <div
                  class="row width-100"
                  *ngIf="
                    isAdmin && ticketType !== TICKET_TYPES.ASSET_ALLOCATION
                  "
                >
                  <div class="col">
                    <p>Priority</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p [ngClass]="getPriorityClass()">
                      {{ ticketPriority || "No priority selected" }}
                      <span *ngIf="ticketPriority"
                        >( {{ ticketTimeStamp }} )</span
                      >
                    </p>
                  </div>
                </div>
                <div class="row width-100">
                  <div class="col">
                    <p class="raised-by">Ticket Raised By</p>
                  </div>
                  <div class="col"><p>:</p></div>
                  <div class="col">
                    <ng-container
                      *ngIf="
                        ticket.ticketDetails.userId && isAdmin;
                        else externalUser
                      "
                    >
                      <a
                        routerLink="/home/employee-details/{{
                          ticket.ticketDetails.userId
                        }}"
                        *ngIf="isAdmin"
                        >{{ ticket.ticketDetails.reporter || "-" }}</a
                      >
                    </ng-container>
                    <ng-template #externalUser>
                      <p>{{ ticket.ticketDetails.reporter }}</p>
                    </ng-template>
                  </div>
                </div>
                <div class="row width-100">
                  <div class="col">
                    <ng-container *ngIf="ticket.ticketDetails.isExternalUser">
                      <p>Email ID</p>
                    </ng-container>
                    <ng-container *ngIf="!ticket.ticketDetails.isExternalUser">
                      <p>Employee ID</p>
                    </ng-container>
                    <ng-container *ngIf="ticket.ticketDetails.userLocation">
                      <p>Location</p>
                    </ng-container>
                  </div>
                  <div class="col">
                    <p>:</p>
                    <ng-container *ngIf="ticket.ticketDetails.userLocation">
                      <p>:</p>
                    </ng-container>
                  </div>
                  <div class="col">
                    <ng-container *ngIf="ticket.ticketDetails.isExternalUser">
                      <p>
                        {{ ticket.ticketDetails.externalUserEmail || "-" }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="!ticket.ticketDetails.isExternalUser">
                      <p>
                        {{ ticket.ticketDetails.reporterEmployeeId || "-" }}
                      </p>
                    </ng-container>
                    <ng-container *ngIf="ticket.ticketDetails.userLocation">
                      <p>
                        {{ ticket.ticketDetails.userLocation || "-" }}
                      </p>
                    </ng-container>
                  </div>
                </div>
                <div class="row width-100">
                  <div class="col">
                    <p>Ticket Raised Date</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p>
                      {{ ticket.ticketDetails.ticketRaisedDate || "-"
                      }}<span class="time">
                        | {{ ticket.ticketDetails.ticketRaisedTime }}</span
                      >
                    </p>
                  </div>
                </div>
                <div
                  class="row width-100"
                  *ngIf="
                    ticketType === TICKET_TYPES.REPAIR_OR_REPLACEMENT ||
                    TICKET_TYPES.ACCESS_INSTALLATION
                  "
                >
                  <div class="col">
                    <p>Issue Type</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p>
                      {{
                        getSubCategoriesString(
                          ticket.ticketSubcategories || null
                        ) || "Not Applicable"
                      }}
                    </p>
                  </div>
                </div>
                <div class="row width-100">
                  <div class="col">
                    <p>Description</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p>{{ ticket.issueDescription.description || "N/A" }}</p>
                  </div>
                </div>
              </div>
              <div
                class="width-100"
                *ngIf="ticket.ticketDetails.files.length !== 0"
              >
                <div class="row width-100">
                  <div class="col">
                    <p>Attachment(s)</p>
                    <p></p>
                  </div>
                  <div class="col">
                    <p>:</p>
                    <p></p>
                  </div>
                  <div class="col">
                    <div
                      class="images"
                      *ngFor="let file of ticket.ticketDetails.files"
                    >
                      <p>
                        <a
                          href="#"
                          *ngIf="file.fileUrl"
                          (click)="
                            $event.preventDefault();
                            openFile(file.fileUrl, file.fileName)
                          "
                          >{{ file.fileName }}</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row width-100" *ngIf="ticketSubStatus">
                <div class="col">
                  <p>Closed Ticket Status</p>
                </div>
                <div class="col">
                  <p>:</p>
                </div>
                <div class="col">
                  <p
                    [ngClass]="{
                      completed: ticketSubStatus === 'COMPLETED',
                      deferred: ticketSubStatus === 'DEFERRED'
                    }"
                  >
                    {{ ticketSubStatus || "Not available" | titlecase }}
                  </p>
                </div>
              </div>

              <div class="row width-100" *ngIf="ticketSubStatus && isAdmin">
                <div class="col">
                  <p>Reason of ticket closure</p>
                </div>
                <div class="col">
                  <p>:</p>
                </div>
                <div class="col">
                  <p>
                    {{ reasonForResolution || "Not available" | titlecase }}
                  </p>
                </div>
              </div>

              <!-- Ticket Closure Attachments -->
              <div
                class="width-100"
                *ngIf="ticket.ticketClosureFile && isAdmin"
              >
                <div class="row width-100">
                  <div class="col">
                    <p>Ticket closure attachment(s)</p>
                    <p></p>
                  </div>
                  <div class="col">
                    <p>:</p>
                    <p></p>
                  </div>
                  <div class="col">
                    <div class="images">
                      <p>
                        <a
                          href="#"
                          (click)="
                            $event.preventDefault();
                            openFile(
                              ticket.ticketClosureFile.fileUrlFromS3,
                              ticket.ticketClosureFile.fileName
                            )
                          "
                          >{{ ticket.ticketClosureFile.fileName }}</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row width-100" *ngIf="ticketSubStatus && isAdmin">
                <div class="col">
                  <p>Ticket Closed By</p>
                </div>
                <div class="col">
                  <p>:</p>
                </div>
                <div class="col">
                  <p *ngIf="ticket.ticketClosedBy; else userNotAvailable">
                    <a
                      routerLink="/home/employee-details/{{
                        ticket.ticketClosedBy.id
                      }}"
                      *ngIf="isAdmin"
                      >{{ ticket.ticketClosedBy.firstName }}
                      {{ ticket.ticketClosedBy.lastName }}</a
                    >
                  </p>
                  <ng-template #userNotAvailable>
                    <p>Not Available</p>
                  </ng-template>
                </div>
              </div>

              <div
                class="width-100"
                *ngIf="ticketType === TICKET_TYPES.ACCESS_INSTALLATION"
              >
                <h3>Assigned Asset Details</h3>
                <div class="row width-100">
                  <div class="col">
                    <p>Asset Name</p>
                    <p>Asset ID</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p>{{ ticket.assetDetails.assetName | titlecase }}</p>
                    <p>{{ ticket.assetDetails.assetId }}</p>
                  </div>
                </div>
              </div>
              <div
                class="width-100"
                *ngIf="ticketType === TICKET_TYPES.REPAIR_OR_REPLACEMENT"
              >
                <h3>Reported Asset Details</h3>
                <div class="row width-100">
                  <div class="col">
                    <p>Asset Name</p>
                    <p>Asset ID</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p>{{ ticket.assetDetails.assetName | titlecase }}</p>
                    <p>{{ ticket.assetDetails.assetId }}</p>
                  </div>
                </div>
              </div>
              <div
                class="width-100"
                *ngIf="
                  isAdmin &&
                  ticket.ticketDetails.status !== TICKET_STATUS.CLOSED &&
                  !ticket.newAsset &&
                  !ticket.ticketDetails.isExternalUser &&
                  ticket.issueDescription.approvalStatus !==
                    APPROVAL_STATUS.DECLINED &&
                  ticket.ticketDetails.status === 'ONGOING'
                "
              ></div>
              <div
                class="width-100"
                *ngIf="
                  (ticketType === TICKET_TYPES.ASSET_ALLOCATION ||
                    TICKET_TYPES.ACCESS_INSTALLATION) &&
                  !ticket.ticketDetails.isExternalUser &&
                  ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT
                "
              >
                <h3>Reporting Manager's Details</h3>
                <div class="row width-100">
                  <div class="col">
                    <p>Name</p>
                    <!-- <p>Approval Status</p> -->
                  </div>
                  <div class="col">
                    <p>:</p>
                    <!-- <p>:</p> -->
                  </div>
                  <div class="col">
                    <p>{{ reportingManager }}</p>
                    <!-- <p
                      *ngIf="
                        ticket.issueDescription.approvalStatus;
                        else notRequested
                      "
                    >
                      <span
                        [ngClass]="{
                          success:
                            ticket.issueDescription.approvalStatus ===
                            APPROVAL_STATUS.APPROVED,
                          warning:
                            ticket.issueDescription.approvalStatus ===
                            APPROVAL_STATUS.PENDING,
                          danger:
                            ticket.issueDescription.approvalStatus ===
                            APPROVAL_STATUS.DECLINED
                        }"
                      >
                        {{ ticket.issueDescription.approvalStatus | titlecase }}
                      </span>
                    </p>
                    <ng-template #notRequested>
                      <p>Not yet requested</p>
                      <p
                        *ngIf="
                          isAdmin &&
                          assigneeSelected &&
                          ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT
                        "
                      >
                        <a
                          href="#"
                          (click)="
                            $event.preventDefault();
                            triggerModal('request-approval-confirmation')
                          "
                        >
                          <span
                            *ngIf="
                              ticket.issueDescription.approvalStatus === null &&
                              !showResendApproval
                            "
                            >Request Approval</span
                          >
                          <span *ngIf="showResendApproval"
                            >Resend Approval</span
                          >
                        </a>
                      </p>
                    </ng-template> -->
                    <!-- <a
                      href="#"
                      (click)="
                        $event.preventDefault();
                        triggerModal('request-approval-confirmation')
                      "
                      *ngIf="isAdmin"
                    >
                      <span
                        class="resend-approval"
                        *ngIf="
                          ticket.issueDescription.approvalStatus ===
                          APPROVAL_STATUS.PENDING
                        "
                        >Resend Approval</span
                      >
                    </a> -->
                  </div>
                </div>
              </div>

              <hr class="separator" *ngIf="!showRequestApprovalDetails && ticket.ticketDetails.status !== TICKET_STATUS.CLOSED && assignedTo !== 'Unassigned'" />
              <div class="approver-section" *ngIf="!showRequestApprovalDetails && ticket.ticketDetails.status !== TICKET_STATUS.CLOSED &&  ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT && assignedTo !== 'Unassigned'">
                <p class="request-approval-title">Request Approval</p>
                <form class="popup-form" [formGroup]="form">
                  <select class="hidden-drop-down" formControlName="approver">
                    <option value="hidden-select"></option>
                  </select>
                  <div class="dropdown-wrapper dropdown-container">
                    <label class="label">Approver Name</label>
                    <app-drop-down
                      from="select_an_issue"
                      [options]="subCategoryOptions"
                      titleName="subCategory"
                      valueName="id"
                      title="Select Approver name"
                      formArrayName="subCategories"
                      (value)="getValueDropdown($event)"
                      (index)="getIndexDropdown($event)"
                      [isDropDownofSelectIssue]="true"
                      [onClickOfCancel]="onClickOfCancelForDropDown"
                      [dropDownForApproverList]="true"
                      [approverDropDown]="true"
                    ></app-drop-down>
                    <!-- <p class="select-asset-remainder" *ngIf="!isAssetSelected">
                      Please select the asset
                    </p> -->
                    <div class="dropdown-button-container">
                      <app-button
                        size="xsmall"
                        theme="tertiary"
                        class="cancel-button"
                        (click)="onCancelSelection()"
                        >Cancel</app-button
                      >
                      <app-button
                        theme="primary"
                        type="submit"
                        size="xsmall"
                        (click)="requestApproval()"
                        [disabled]="!isRequestApproverDisabled"
                        >Request</app-button
                      >
                    </div>
                  </div>
                </form>
              </div>


              <div class="request-approval-details" *ngIf="requestApprovalDetails.length > 0 && ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT">
                <h3>Request Approval</h3>
                <ng-container *ngFor="let request of requestApprovalDetails">
                  <div class="row width-100">
                    <div class="col">
                      <p>{{ request.approver.approverName }}</p>
                    </div>
                    <div class="col">
                      <p>:</p>
                    </div>
                    <div class="col">
                      <p
                        [ngClass]="{
                          'approveRequest': request.approvalStatus === 'APPROVED',
                          'pendingRequest': request.approvalStatus === 'PENDING'
                        }"
                      >
                        {{ request.approvalStatus === 'PENDING' ? 'Pending' : 'Approved' }}
                      </p>
                      <a
                        href="#"
                        (click)="
                          $event.preventDefault();
                          triggerModal('resend-approval-confirmation')
                        "
                        *ngIf="request.approvalStatus === 'PENDING'"
                      >
                        <span>Resend Request</span>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- <div class="width-100" *ngIf="ticket.newAsset">
                <h3>Assigned Asset Details</h3>
                <div class="row width-100">
                  <div class="col">
                    <p *ngIf="showAssetName">Asset Name</p>
                    <p>Asset ID</p>
                  </div>
                  <div class="col">
                    <p *ngIf="showAssetName">:</p>
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p *ngIf="showAssetName">
                      {{ ticket.newAsset.name | titlecase }}
                    </p>
                    <p>{{ newAssetCode }}</p>
                  </div>
                </div>
              </div> -->
              <!-- <ng-container
                *ngIf="
                  ticketType === TICKET_TYPES.ASSET_ALLOCATION ||
                  (TICKET_TYPES.ACCESS_INSTALLATION &&
                    ticketStatus !== TICKET_STATUS.PENDING) ||
                  ticket.issueDescription.approvalStatus ===
                    APPROVAL_STATUS.APPROVED
                "
              >
              </ng-container> -->
              <!-- <div class="width-100" *ngIf="isAdmin && ticket.newAsset">
                <h3>Custody Verification</h3>
                <div class="row width-100">
                  <div class="col">
                    <p>Status</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                  </div>
                  <div
                    class="col"
                    *ngIf="
                      getCustodyStatus(ticket.newAsset!.asset_allocations) ===
                      CUSTODY_VERIFICATION_STATUS.VERIFIED
                    "
                  >
                    <p class="verified">Verified</p>
                  </div>
                  <div
                    class="col"
                    *ngIf="
                      getCustodyStatus(ticket.newAsset!.asset_allocations) ===
                      CUSTODY_VERIFICATION_STATUS.PENDING
                    "
                  >
                    <p class="pending">Pending</p>
                    <a
                      href="#"
                      (click)="
                        $event.preventDefault();
                        triggerModal('resend-approval-confirmation')
                      "
                      *ngIf="
                        isAdmin &&
                        ticket.newAsset &&
                        !ticket.ticketDetails.isExternalUser &&
                        getCustodyStatus(ticket.newAsset!.asset_allocations) ===
                          CUSTODY_VERIFICATION_STATUS.PENDING
                      "
                    >
                      <span>Resend Request</span>
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- ticket related details ***/End/*** -->

        <!-- ticket action section ***/Start/*** -->
        <div
          class="ticket-action"
          *ngIf="
            showCheckOutForRequestAnAsset &&
            !showMarkAsCompleted &&
            ticketType !== TICKET_TYPES.REPAIR_OR_REPLACEMENT
          "
        >
          <div
            class="check-out-section"
            *stessaHasPermission="'TICKETS__TICKET_DETAILS__CHECKOUT_BUTTON'"
          >
            <!-- checkin -->
            <!-- <div
              class="headings"
              *ngIf="!viewVerifyAsset?.isAssetVerified && !checkInSuccessful"
            >
              <h3>Check In</h3>
            </div>
            <div>
              <form
                [formGroup]="checkInAssetForm"
                (submit)="checkInAsset()"
                class="check-in-form"
              >
                <div class="form-control">
                  <label class="form-label">Check in Reported Asset</label>
                  <app-text-field
                    type="text"
                    placeholder="CSL-L-21-22-L-0001"
                    class="text-feild"
                    formControlName="assetCode"
                  ></app-text-field>
                </div> -->
                <!-- <app-button theme="primary" type="submit" size="xsmall"
                  >Verify Asset</app-button
                > -->
                <!-- <div class="reason-container">
                  <label class="label select-lonee-text">Reason *</label>
                  <app-textarea
                    class="text-area"
                    cols="30"
                    rows="10"
                    formControlName="textArea"
                    characterCounter
                    maxLength="255"
                    (countEvent)="displayCount($event)"
                    [countData]="countData"
                    [placeholder]="'Enter your reason here...'"
                    [isRequired]="true"
                    [isLabelPresent]="false"
                  >
                  </app-textarea>
                </div>
                <div class="check-in-out-button">
                  <app-button theme="primary" type="submit" size="xsmall"
                    >Check In</app-button
                  >
                </div>
              </form>
            </div> -->

            <!-- check-out -->
            <div class="headings checkout-section">
              <h3>Check Out</h3>
            </div>
            <p class="check-in-required" *ngIf="checkInSuccessful">
              Asset check-in is required
            </p>
            <div
              class="checkout-form"
              *ngIf="!viewVerifyAsset?.isAssetVerified && !checkInSuccessful"
            >
              <label class="form-label">Assign New Assets</label>
              <form [formGroup]="verifyAssetForm" (submit)="verifyAsset()">
                <div class="form-control">
                  <app-text-field
                    type="text"
                    placeholder="CSL-L-21-22-L-0001"
                    class="text-feild"
                    formControlName="assetCode"
                  ></app-text-field>
                </div>
                <app-button
                  theme="primary"
                  type="submit"
                  size="xsmall"
                  [disabled]="verifyAssetForm.invalid"
                  >Verify Asset</app-button
                >
              </form>
              <small *ngIf="viewVerifyAsset?.errorMsg" class="error-message">{{
                viewVerifyAsset.errorMsg!
              }}</small>
            </div>

            <div class="asset-details" *ngIf="viewVerifyAsset?.isAssetVerified">
              <div class="width-100">
                <h3>Assign New Asset</h3>
                <div class="row width-100">
                  <div class="col">
                    <p>Asset Name</p>
                    <p>Asset Code</p>
                  </div>
                  <div class="col">
                    <p>:</p>
                    <p>:</p>
                  </div>
                  <div class="col">
                    <p>{{ viewVerifyAsset.name }}</p>
                    <p>{{ viewVerifyAsset.assetCode }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="btn-wrapper"
            *ngIf="ticketType === TICKET_TYPES.ASSET_ALLOCATION"
          >
            <app-button
              class="spacing margin-top"
              type="submit"
              size="xsmall"
              theme="primary"
              (onClicked)="verifyAsset()"
              *ngIf="viewVerifyAsset?.isAssetVerified"
            >
              Check Out
            </app-button>
          </div>
        </div>
        <!-- ticket action section ***/End/*** -->

        <!-- ticket resolving section ***/Start/*** -->
        <ng-container
          *ngIf="ticket.ticketDetails.status !== TICKET_STATUS.CLOSED"
        >
          <ng-container
            *ngIf="ticket.assetDetails.assetId.startsWith('CD-EKO')"
          >
            <div
              class="ticket-resolved"
              *stessaHasPermission="'EKO__TICKETS__TICKET_DETAILS__EDIT_BUTTON'"
            >
              <div class="mark-as-complete-block">
                <p>
                  Want to conclude this ticket?
                  <span
                    class="mark-as-completed-hyperlink"
                    (click)="markTicketAsResolved()"
                    >Mark as Closed</span
                  >
                </p>
              </div>
            </div>
          </ng-container>
          <div
            class="ticket-resolved"
            *stessaHasPermission="'TICKETS__TICKET_DETAILS__EDIT_BUTTON'"
          >
            <div class="mark-as-complete-block">
              <p>
                Want to conclude this ticket?
                <span
                  class="mark-as-completed-hyperlink"
                  (click)="markTicketAsResolved()"
                  >Mark as Closed</span
                >
              </p>
            </div>
          </div>
          <div
            *ngIf="
              ticket.ticketDetails.status === TICKET_STATUS.ON_HOLD && isAdmin
            "
            class="ongoing-button"
          >
            <app-button size="small" theme="primary" (click)="moveTicket()"
              >Move to ‘Ongoing’
            </app-button>
          </div>
          <div
            *ngIf="
              ticket.ticketDetails.status === TICKET_STATUS.ASSIGNED &&
              isAdmin &&
              !ticket.assetDetails.assetId.startsWith('CD-EKO') &&
              adminRole !== 'EKO-ADMIN'
            "
            class="ongoing-button"
          >
            <app-button size="small" theme="primary" (click)="moveToOngoing()"
              >Move to ‘On-Hold’
            </app-button>
          </div>
        </ng-container>

        <!-- ticket resolving section ***/End/*** -->
      </div>
      <div class="right-section">
        <div class="header-section">
          <h3>Comments</h3>
        </div>

        <div
          [ngClass]="{
            'comments-section': ticketComments.length !== 0,
            'no-comments': ticketComments.length === 0
          }"
          [@basicAnimation]
        >
          <ng-container *ngIf="ticketComments.length === 0">
            <app-empty-placeholder
              imageSrc="assets/58x58/no_chats.svg"
              [title]="placeholderTitle"
              [subtitle]="placeholderSubtitle"
            ></app-empty-placeholder>
          </ng-container>
          <app-comment
            [allComments]="ticketComments"
            class="comments-container"
          ></app-comment>
        </div>
        <div class="input-section">
          <form [formGroup]="addCommentForm">
            <div class="text-area-container">
              <app-textarea
                id="comment-area"
                rows="1"
                formControlName="comment"
                characterCounter
                maxLength="255"
                [countData]="countData"
                placeholder="Type your message here"
                [isRequired]="false"
                [isLabelPresent]="false"
                libTextareaAutoresize
                #textArea
              >
              </app-textarea>
            </div>

            <app-button
              theme="secondary"
              type="submit"
              size="xsmall"
              [disabled]="!addCommentForm.valid || makeCommentButtonDisabled"
              (click)="addComment()"
              >Comment</app-button
            >
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</div>
