import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ISearchData,
  ITicketSnackbarConfig,
  PAGINATION_DATA,
  TICKETS_MENU,
  TICKET_SEARCH_OPTIONS,
  TICKET_SORT_COLUMNS,
  getAssetTeamTicketsData,
  EKO_TICKET_STATUS,
  TICKET_STATUS,
  PROVIDER,
  ASSET_INFO_HEADINGDS,
} from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { FIELD_ARRAY_TICKET_LIST } from 'libs/common/src/lib/constants/table-field-constants';
import { UploadDownloadService } from 'libs/common/src/lib/service/upload-download.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-admin-ticket',
  templateUrl: './admin-ticket.component.html',
  styleUrls: ['./admin-ticket.component.scss'],
})
export class AdminTicketComponent implements OnInit {
  tableDetails: any;
  searchColumn!: string;
  searchText!: string;
  status = '';
  PAGINATION_DATA = PAGINATION_DATA;
  pageSize = '5';
  isAssignedTicketSelected: boolean = false;
  displayTicketList: boolean = false;
  fieldArray = FIELD_ARRAY_TICKET_LIST;
  titleForClientDropDown!: string;
  provider!: string;
  tableConfig = [
    {
      title: 'Ticket no.',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isTicket: true,
        keys: [
          {
            key: 'ticketNumber',
            from: 'ticket',
            handleAction: (ticketId: string) => {
              this.navigateToTicket(ticketId);
            },
          },
          { key: 'title', from: 'ticket' },
        ],
      },
    },
    {
      title: 'Ticket date',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'createdAt', from: 'ticket', isDate: true },
        isTicket: true,
      },
    },
    {
      title: 'TicketStatus',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [{ key: 'status', from: 'ticket', isStatus: true }],
        isTicket: true,
      },
    },
    {
      dataType: 'asset',
      title: 'Asset name',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'name', from: 'asset' },
        isAsset: true,
      },
    },
    {
      dataType: 'userInfo',
      title: 'Ticket raised by',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [
          { key: 'firstName', from: 'user' },
          { key: 'lastName', from: 'user' },
          { key: 'externalUserName', from: 'user' },
          { key: 'email', from: 'user' },
        ],
        isUserInfo: true,
      },
    },
    {
      dataType: 'assigneeInfo',
      title: 'Ticket assigned to',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [
          { key: 'firstName', from: 'assignee' },
          { key: 'lastName', from: 'assignee' },
          { key: 'email', from: 'assignee' },
        ],
        isAssigneeInfo: true,
      },
    },
  ];
  TICKET_SEARCH_OPTIONS = Object.values(TICKET_SEARCH_OPTIONS);
  sortDigit = 'DESC';
  currentPageNumber = '1';
  assetTag: string = '';
  ticketDate: string = '';
  assetName: string = '';
  ticketRaisedBy: string = '';
  ongoingFilter: boolean = true;
  todoFilter: boolean = false;
  completedFilter: boolean = false;
  allFilter: boolean = false;
  pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  button: boolean = true;
  isEkoAdmin!: boolean;
  isAdmin!: boolean;
  isSemiAdmin!: boolean;
  AssetTeamtableDetails!: any[];
  ticketStatusClicked = false;
  ticketStatus = TICKETS_MENU;
  params!: string;
  count!: number;
  toggleViewTicketModal = false;
  currentParams: any;
  alertBox: boolean = false;
  snackBarConfig!: ITicketSnackbarConfig;
  loaderView: boolean = false;
  downloadTicketsResponse: any;
  textOnButton = 'Download Ticket List';
  groupedTableDetails: any[] = [];
  constructor(
    private commonHttpService: CommonHttpService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private uploadAndDownloadService: UploadDownloadService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.isEkoAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'EKO-ADMIN';
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'ADMIN';
    this.isSemiAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'SEMI-ADMIN';
    this.activatedRoute?.queryParams?.subscribe((params) => {
      if (this.isEkoAdmin) {
        this.ticketStatus = EKO_TICKET_STATUS;
        this.provider = PROVIDER.EKO_CLIENT;
      } else if (this.isAdmin || this.isSemiAdmin) {
        if (params['provider'] === 'EKO CLIENT') {
          this.ticketStatus = EKO_TICKET_STATUS;
          this.provider = PROVIDER.EKO_CLIENT;
          this.titleForClientDropDown = 'Eko';
        } else {
          this.ticketStatus = TICKETS_MENU;
          this.provider = PROVIDER.CODITAS;
          this.titleForClientDropDown = 'Coditas';
        }
      }

      this.pageSize = params['size'] || '5';
      this.searchText = params['search'] || '';
      this.searchColumn = params['column'] || '';
      this.currentPageNumber = params['page'] || '1';
      this.status =
        params['status'] && params['status'] !== 'ALL' ? params['status'] : '';
      this.searchColumn = '';
      this.searchText = '';
      const param = {
        status: this.status,
        pageNumber: params['page'] || '1',
      };

      const pageSize = sessionStorage.getItem('pageSize');
      if (pageSize) {
        this.pageSize = pageSize;
      }

      this.loadCounters(this.provider);
      this.getAllTickets(param);
      this.changeDetectorRef.detectChanges();
    });
  }

  loadCounters(provider?: string) {
    this.commonHttpService
      .getTicketStatus(this.provider)
      .subscribe((res: any) => {
        if (provider === PROVIDER.EKO_CLIENT) {
          this.ticketStatus[0].count = res.data.todoTicketCount;
          this.ticketStatus[1].count = res.data.ongoingTicketCount;
          this.ticketStatus[2].count = res.data.closedTicketCount;
          this.ticketStatus[3].count = res.data.totalTicketCount;
        } else {
          this.ticketStatus[1].count = res.data.ongoingTicketCount;
          this.ticketStatus[0].count = res.data.todoTicketCount;
          this.ticketStatus[3].count = res.data.closedTicketCount;
          this.ticketStatus[4].count = res.data.externalTicketCount;
          this.ticketStatus[5].count = res.data.totalTicketCount;
          this.ticketStatus[2].count = res.data.onHoldTicketCount;
        }
      });
  }

  search(searchData: ISearchData) {
    this.searchColumn = searchData.filterBy;
    this.searchText = searchData.searchStr;
    this.getAllTickets();
    this.textOnButton = 'Download Ticket List';
  }

  formatDate(date: string) {
    return date.split('/').reverse().join('-');
  }
  getAllTickets(param?: any) {
    this.loaderView = true;
    if (this.router.url.includes('status=TO_DO')) {
      this.isAssignedTicketSelected = false;
    }
    const params = {
      provider: this.provider,
      status: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      searchColumn: this.searchColumn || '',
      searchText:
        this.searchColumn === 'TICKET_DATE'
          ? this.formatDate(this.searchText)
          : this.searchText || '',
      sortColumn: param?.sortColumn || '',
      sortDirection: param?.sortDirection || '',
      assignedTickets: this.isAssignedTicketSelected || false,
    };

    this.currentParams = params;

    const loadRequest = this.commonHttpService.getAssetTeamTableData(params);
    if (loadRequest) {
      loadRequest.subscribe({
        next: (response: any) => {
          const ticketsData = Object(response).data;
          this.tableDetails = getAssetTeamTicketsData(ticketsData.tickets);
          const groupedTableDetails: { [key: string]: any[] } = {};
          for (const object of this.tableDetails) {
            const date = object.Date;
            if (!groupedTableDetails.hasOwnProperty(date)) {
              groupedTableDetails[date] = [];
            }
            groupedTableDetails[date].push(object);
          }
          this.count = ticketsData.count;

          this.commonService.searchParam.next({
            searchColumn: param?.searchColumn,
            searchText: param?.searchText,
            sortColumn: param?.sortColumn,
            sortDirection: param?.sortDirection,
            pageNumber: param?.pageNumber,
            pageSize: param?.pageSize,
          });
          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
        },
      });
      this.currentPageNumber = param?.pageNumber;
      this.sortDigit = this.sortDigit === 'DESC' ? 'ASC' : 'DESC';
      this.changeDetectorRef.detectChanges();
    }
  }

  navigateToTicket(ticketId: string) {
    // this.ticketStatusClicked = true;
    const buildURL = this.commonService.buildURL(
      `home/admin-ticket/ticket/${ticketId}`,
      {
        page: this.currentPageNumber,
        size: this.pageSize,
        search: this.searchText,
        column: this.searchColumn,
        provider: this.provider,
      }
    );
    this.router.navigateByUrl(buildURL);
  }
  filterTickets(filter: any) {
    this.status = filter;
    this.getAllTickets();
  }

  pageSizeChange(pageSize: string) {
    const pageCount = sessionStorage.getItem('pageSize');
    if (pageCount) {
      this.pageSize = pageCount;
    } else {
      this.pageSize = pageSize;
    }
    this.getAllTickets();
  }
  handleSortAction(col: string) {
    const sortAction = col.split(' ');
    const columnName = sortAction[1];
    const sortOrder = sortAction[0];
    this.sortDigit = sortOrder;
    const param = {
      sortColumn: '',
      sortDirection: this.sortDigit,
      pageNumber: this.currentPageNumber,
    };
    switch (columnName) {
      case 'TicketNo':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_NUMBER;
        break;

      case 'Date':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_DATE;
        break;

      case 'AssetName':
        param.sortColumn = TICKET_SORT_COLUMNS.ASSET_NAME;
        break;

      case 'TicketStatus':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_STATUS;
        break;

      case 'Priority':
        param.sortColumn = TICKET_SORT_COLUMNS.PRIORITY;
        break;

      case 'TicketRaisedby':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_RAISED_BY;
        break;

      case 'TicketAssignedto':
        param.sortColumn = TICKET_SORT_COLUMNS.ASSIGNED_TO;
        break;

      default:
        param.sortColumn = '';
        break;
    }

    this.getAllTickets(param);
  }

  downloadTicketList(param?: any) {
    this.commonService.makeDownloadReportButtonDisabled.next({
      from: ASSET_INFO_HEADINGDS.TICKET_STATUS,
      flag: true,
    });

    if (this.router.url.includes('status=TO_DO')) {
      this.isAssignedTicketSelected = false;
    }

    const params = {
      provider: this.provider,
      status: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      searchColumn: this.searchColumn || '',
      searchText:
        this.searchColumn === 'TICKET_DATE'
          ? this.formatDate(this.searchText)
          : this.searchText || '',
      sortColumn: param?.sortColumn || '',
      sortDirection: param?.sortDirection || '',
      isDownload: true,
      assignedTickets: this.isAssignedTicketSelected || false,
    };

    this.commonHttpService.downloadTicketList(params).subscribe({
      next: (response: BlobPart) => {
        this.downloadTicketsResponse = Object(response).data.tickets;
        const modifiedResponse =
          this.uploadAndDownloadService.destructureDownloadTicketList(
            this.downloadTicketsResponse
          );

        const blob = new Blob([modifiedResponse], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        this.commonService.makeDownloadReportButtonDisabled.next({
          from: ASSET_INFO_HEADINGDS.TICKET_STATUS,
          flag: false,
        });
      },
      error: (error: HttpErrorResponse) => {
        const { errorList } = error.error;
        this.toastrService.error(
          errorList || 'Could not able to download the ticket reports.',
          'Download Failed!',
          { toastClass: 'toaster-wrapper' }
        );
        this.commonService.makeDownloadReportButtonDisabled.next({
          from: ASSET_INFO_HEADINGDS.TICKET_STATUS,
          flag: false,
        });
      },
    });
  }

  getFilteredData(filter: string, param?: any) {
    if (filter == 'assigned') {
      this.isAssignedTicketSelected = true;
      const params = {
        status: this.status,
        pageNumber: param?.pageNumber || '1',
        itemsPerPage: this.pageSize || '5',
        searchColumn: this.searchColumn || '',
        searchText:
          this.searchColumn === 'TICKET_DATE'
            ? this.formatDate(this.searchText)
            : this.searchText || '',
        sortColumn: param?.sortColumn || '',
        sortDirection: param?.sortDirection || '',
        assignedTickets: this.isAssignedTicketSelected,
      };

      this.getAllTickets(params);
    } else {
      this.isAssignedTicketSelected = false;
      this.getAllTickets();
    }
  }

  onClientChange(client: string) {
    if (client === 'Eko') {
      this.ticketStatus = EKO_TICKET_STATUS;
      this.provider = PROVIDER.EKO_CLIENT;
      const queryParams = { provider: 'EKO CLIENT' };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
      });
    } else {
      this.ticketStatus = TICKETS_MENU;
      this.provider = PROVIDER.CODITAS;
      const queryParams = { provider: 'CODITAS' };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
      });
    }
    this.loadCounters(this.provider);
    this.getAllTickets();
  }
}
