import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IWorkspaceDetail } from 'libs/common/src/lib/interfaces/interface';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';

@Component({
  selector: 'app-manage-workspace-details',
  templateUrl: './manage-workspace-details.component.html',
  styleUrls: ['./manage-workspace-details.component.scss']
})
export class ManageWorkspaceDetailsComponent implements OnInit {
  workspaceDetails!: IWorkspaceDetail;
  historyConfig = {
    status: '',
    page: '',
    size: '',
    search: '',
    column: '',
    provider: '',
  };
  loaderView: boolean = false;
  workspaceId: string = '';

  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe({
      next: (params) => {
        const { status, page, size, search, column, provider } =
          Object(params).params;
        this.historyConfig.status = status;
        this.historyConfig.page = page;
        this.historyConfig.size = size;
        this.historyConfig.search = search;
        this.historyConfig.column = column;
        this.historyConfig.provider = provider;
      },
    });

    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.workspaceId = Object(params).id;
        // Load workspace details
        this.getWorkSpaceDetails(this.workspaceId);
      },
    });
  }

  getWorkSpaceDetails(workspaceId: any) {
    this.loaderView = true;
    this.commonHttpService.getWorkspaceDetails(workspaceId).subscribe({
      next: (response: any) => {
        this.workspaceDetails = response.data;
        this.loaderView = false;
      },
      error: (err) => {
        this.loaderView = false;
        console.error('Error fetching workspace details', err);
      },
    });
  }

  formatCodeTemplate(codeTemplate: string): string {
    const financialYear = '24-25';
    const assetTypeCode = 'L';
    const assetNumber = '0001';

    return codeTemplate
      .replace('${financialYear}', financialYear)
      .replace('${assetTypeCode}', assetTypeCode)
      .replace('${assetNumber}', assetNumber);
  }

}
