<table *ngIf="dataSource?.length; else noData" class="table-container table" cdk-table [dataSource]="dataSource"
  fixedLayout [ngClass]="{ normal: !isClosedCardSelected, closed: isClosedCardSelected }">
  <ng-container *ngFor="let field of fieldArray; let i = index" cdkColumnDef="{{ field.fieldName }}">
    <th cdk-header-cell *cdkHeaderCellDef>
      <span class="sort-thead" (click)="sortAction(field)">
        <span>{{ field.fieldName }}</span>
        <img *ngIf="field.icon !== ''" [src]="getImageAddress(field)" alt="sort-icon" />
      </span>
    </th>

    <td cdk-cell *cdkCellDef="let element" [ngClass]="{ date: getClass(field.fieldName, element['Date']) }">
      <ng-container *ngIf="field.keyType === 'singleKey'">
        <span *ngIf="field.key === 'AssetName'" class="asset-name-container">
          {{ element[field.key] }}
        </span>
        <span *ngIf="field.key === 'Status'" class="status-chip" [ngClass]="getStatus(element['status'])">
          <span class="status-dot"></span>
          {{getStatus(element['status'])}}
        </span>

        <div>
          {{
          getDateForTicketRow(field.fieldName, element[field.key]) | shortDate
          }}
          <p class="asset-name-container" *ngIf="canDateBeShown && field.fieldName == 'Date'">
            {{ element["Day"] }}
          </p>
        </div>
        <div *ngIf="field.fieldName === 'Ticket Status'">
          <span [ngClass]="{
              deferred: element['ticketSubStatus'] == 'DEFERRED',
              completed: element['ticketSubStatus'] == 'COMPLETED'
            }">
            {{ element["ticketSubStatus"] | titlecase }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="field.keyType === 'multipleKeys'">
        <ng-container *ngIf="field.fieldName === 'Ticket No'">
          <div class="ticket-number-container">
            <div class="ticket-details-container">

              <div class="ticket-number">
                <a href="#" (click)="handleClick(element['ticketId']); $event.preventDefault()">
                  {{
                  "#" +
                  (element["isExternalTicket"] === true ? "Ext - " : "") +
                  element["TicketNo"]
                  }}
                </a>
              </div>
              <div class="status" [ngClass]="getPriorityClass(element['Priority'])">
                <span class="priority-text">{{ element['Priority'] }}</span>
              </div>
            </div>
            <div class="description">
              <span>{{ element["description"] }}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="field.fieldName === 'Ticket Raised by'">
          <ng-container *ngFor="let key of field.key; let i = index">
            <div class="user-details-container">
              <div *ngIf="!element['isExternalTicket']">
                <div class="user-name" *ngIf="key === 'UserName'">
                  {{ element[field.key[i]] }}
                </div>
                <div class="description" *ngIf="key === 'UserEmail'">
                  {{ element[field.key[i]] }}
                </div>
              </div>
              <div *ngIf="element['isExternalTicket']">
                <div class="user-name" *ngIf="key === 'UserName'">
                  {{ element["externalUserName"] }}
                </div>
                <div class="description" *ngIf="key === 'UserEmail'">
                  {{ element[field.key[i]] }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="field.fieldName === 'Ticket Assigned to'">
          <ng-container *ngFor="let key of field.key; let i = index">
            <div class="user-details-container">
              <div class="user-name" *ngIf="key === 'AssigneeName'">
                {{ element[field.key[i]] || "-" }}
              </div>
              <div class="description" *ngIf="key === 'AssigneEmail'">
                {{ element[field.key[i]] || "-" }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>
  <tr cdk-header-row *cdkHeaderRowDef="HeadArray"></tr>
  <tr cdk-row *cdkRowDef="let row; columns: HeadArray"></tr>
</table>
<ng-template #noData>
  <div class="no-data">
    <app-empty-placeholder imageSrc="{{ placeholderIcon }}" [title]="placeholderTitle"
      [subtitle]="placeholderSubtitle"></app-empty-placeholder>
  </div>
</ng-template>