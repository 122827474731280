import { Component, Input, OnInit } from '@angular/core';
import { ROUTES } from '@common/interfaces';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  @Input() coditasRoute!: string;
  @Input() personalRoute!: string;
  @Input() personalTabPermission!: string;

  // Variable to hold the workspace name
  workspaceName: string = 'Workspace'; // Default value (optional)

  constructor() {}

  ngOnInit(): void {
    // Retrieve workspace name from local storage
    const storedData = localStorage.getItem('workspaceName');
    if (storedData) {
      this.workspaceName = storedData; // Use the value from local storage
    }
  }
}
