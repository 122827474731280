import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { UploadDownloadService } from 'libs/common/src/lib/service/upload-download.service';
import { BulkDownloadListComponent } from 'libs/shared/ui-components/src/lib/bulk-download-list/bulk-download-list.component';
import { BulkUploadAlertModalComponent } from 'libs/shared/ui-components/src/lib/bulk-upload-alert-modal/bulk-upload-alert-modal.component';
import { BulkUploadListComponent } from 'libs/shared/ui-components/src/lib/bulk-upload-list/bulk-upload-list.component';

@Component({
  selector: 'app-admin-bulk-upload',
  templateUrl: './admin-bulk-upload.component.html',
  styleUrls: ['./admin-bulk-upload.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AdminBulkUploadComponent implements OnInit {
  cardCounts = {
    userListCount: '0',
    assetListCount: '0',
    assetAllocationListCount: '0',
    vendorListCount: '0',
  };

  toggleBulkUploadListModal = false;
  toggleBulkDownloadListModal = false;
  toggleBulkUploadAlertModal = false;

  @ViewChild('bulkUploadAlertModal')
  _bulkUploadAlertModal!: BulkUploadAlertModalComponent;

  constructor(
    private commonHttpService: CommonHttpService,
    private dialog: Dialog,
    private commonService: CommonService,
    private uploadDownloadService: UploadDownloadService
  ) { }

  close() {
    this.loadCounters();
    this.toggleBulkUploadAlertModal = !this.toggleBulkUploadAlertModal;
  }

  ngOnInit(): void {
    this.loadCounters();
  }

  alertBoxHandler(response: any) {
    // this._bulkUploadAlertModal.ngOnInit(response);
    this.toggleBulkUploadAlertModal = true;
  }

  loadCounters() {
    this.commonHttpService.getBulkUploadCounts().subscribe({
      next: (response) => {
        const counts = Object(response).data;
        this.cardCounts.userListCount = counts.userCount;
        this.cardCounts.assetListCount = counts.assetCount;
        this.cardCounts.assetAllocationListCount = counts.assetAllocationCount;
        this.cardCounts.vendorListCount = counts.vendorsCount;
      },
    });
  }
  triggerModal(modal: string) {
    if (modal === 'bulk-download-modal') {
      this.uploadDownloadService.openModal(BulkDownloadListComponent);
    } else if (modal === 'bulk-upload-modal') {
      this.uploadDownloadService.openModal(BulkUploadListComponent);
    }
  }
}
