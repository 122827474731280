import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAssetTeamTicketsData,TICKET_SORT_COLUMNS } from '@common/interfaces';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';

@Component({
  selector: 'app-admin-approvals-personal',
  templateUrl: './admin-approvals-personal.component.html',
  styleUrls: ['./admin-approvals-personal.component.scss']
})
export class AdminApprovalsPersonalComponent implements OnInit {
  loaderView: boolean = false;
  tableConfig = [
    {
      title: 'Ticket no.',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isTicket: true,
        keys: [
          {
            key: 'ticketNumber',
            from: 'ticket',
            handleAction: (ticketId: string) => {
              this.navigateToTicket(ticketId);
            },
          },
          { key: 'title', from: 'ticket' },
        ],
      },
    },
    {
      title: 'Asset tag',
      hasMultiKeys: true,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        isAssetTag: true,
        keys: [
          { key: 'assetCode', from: 'asset' },
          { key: 'name', from: 'asset' },
        ],
      },
    },
    {
      title: 'Status',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: [{ key: 'status', from: 'ticket', isStatus: true }],
        isTicket: true,
      },
    },
    {
      title: 'Asset name',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'name', from: 'asset' },
        isAsset: true,
      },
    },
    {
      title: 'Ticket date',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: { key: 'createdAt', from: 'ticket', isDate: true },
        isTicket: true,
      },
    },
  ];
  tableDetails: any[] = [];
  count: any;
  userData: any;
  currentPageNumber: any;
  sortDigit!: string;
  status!: string;
  pageSize!: string;
  filterOptions = [
    { title: 'Ongoing', value: 'ONGOING' },
    { title: 'To-Do', value: 'TO_DO' },
    { title: 'On-Hold', value: 'ON_HOLD' },
    { title: 'Closed', value: 'CLOSED' },
    { title: 'All Tickets', value: '' },
  ];
  currentParams!: {
    status: any;
    pageNumber: any;
    itemsPerPage: any;
    sortColumn: any;
    sortDirection: any;
  };
  constructor(
    private commonHttpService: CommonHttpService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private router: Router
  ) { }
  ngOnInit(): void {

    this.userData = JSON.parse(localStorage.getItem('userInfo') as string);
    this.getUserTickets();
  }

  filterBy(filter: string) {
    this.status = filter;
   this.getUserTickets();
  }
  navigateToTicket(ticketId: string) {
    this.router.navigate(['home/admin-ticket/ticket/' + ticketId]);
  }

  getUserTickets(param?: any) {
    this.loaderView = true;
    const params = {
      status: this.status || '',
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      sortColumn: param?.sortColumn || '',
      sortDirection: param?.sortDirection || '',
    };
    this.currentParams = params;
    const loadRequest = this.commonHttpService.getAllTicketsForUserApproval(params);
    if (loadRequest) {
      loadRequest.subscribe({
        next: (response) => {
          const ticketsData = Object(response).data;
          const tableDetails = ticketsData.tickets;

          // this.tableDetails = getTicketsTableData(ticketsData.tickets);
          this.tableDetails = getAssetTeamTicketsData(tableDetails);

          this.count = ticketsData.count;
          this.commonService.searchParam.next({
            sortColumn: param?.sortColumn,
            sortDirection: param?.sortDirection,
            pageNumber: param?.pageNumber,
          });
          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
        },
      });
      this.currentPageNumber = param?.pageNumber;
      this.sortDigit = this.sortDigit === 'DESC' ? 'ASC' : 'DESC';
      this.changeDetectorRef.detectChanges();
    }
  }

  handleSortAction(col: string) {
    const sortAction = col.split(' ');
    const columnName = sortAction[1];
    const sortOrder = sortAction[0];
    this.sortDigit = sortOrder;
    const param = {
      sortColumn: '',
      sortDirection: this.sortDigit,
      pageNumber: this.currentPageNumber,
    };
    switch (columnName) {
      case 'TicketNo':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_NUMBER;
        break;

      case 'Date':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_DATE;
        break;

      case 'AssetName':
        param.sortColumn = TICKET_SORT_COLUMNS.ASSET_NAME;
        break;

      case 'Status':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_STATUS;
        break;
      case 'Priority':
        param.sortColumn = TICKET_SORT_COLUMNS.PRIORITY;
        break;

      case 'TicketRaisedby':
        param.sortColumn = TICKET_SORT_COLUMNS.TICKET_RAISED_BY;
        break;

      case 'TicketAssignedto':
        param.sortColumn = TICKET_SORT_COLUMNS.ASSIGNED_TO;
        break;

      default:
        param.sortColumn = '';
        break;
    }
    this.getUserTickets(param);
  }

  pageSizeChange(pageSize: string) {
    this.pageSize = pageSize;
    this.getUserTickets();
  }


}
