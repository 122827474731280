<div class="asset-team-container" [ngClass]="{ loader: loaderView }">
  <!-- Loader -->
  <app-loader class="loader" *ngIf="loaderView"></app-loader>
  <!-- Content only shown when not loading -->
  <ng-container *ngIf="!showAddAssetType && !showAddAccessoryType">
    <div class="row">
      <div class="dash-section-wrapper">
        <div class="dash-section-wrapper-content">
          <div class="details" [@basicAnimation]>
            <div class="frame1">
              <p class="label">
                <span> Workspace Name </span>
                <span class="workspace-name">
                  {{ workspaceOwnerDetails.name || "-" }}
                </span>
              </p>
              <p class="label">
                <span> Sign-in Method </span>
                <span class="user-info">{{ workspaceOwnerDetails.workspace_auth.auth_type.displayName }}</span>
              </p>
              <div class="label">
                <span>Locations</span>
                <div class="locations-wrapper">
                  <div *ngFor="let location of workspaceOwnerDetails?.locations" class="location">
                    {{ location }}
                  </div>
                </div>
              </div>
            </div>
            <div class="frame2">
              <span class="label-name">Workspace Image </span>
              <div class="workspace-initial">
                <span class="initial-circle">
                  {{ workspaceOwnerDetails.name.charAt(0) || "-" }}
                </span>
              </div>
              <span class="label-name">Asset Code Acronyms</span>
              <div class="locations-wrapper">
                <div *ngFor="let acronyms of workspaceOwnerDetails?.acronyms" class="location">
                  {{ acronyms }}
                </div>
                <button class="add-more-button" (click)="onAddMoreAcronyms()">
                  Add More
                </button>
              </div>
            </div>
          </div>
          <div class="dash-section--inner-wrapper">
            <div class="company-details">
              <span class="company-details_heading">Company Details</span>
              <p class="company-details_label">
                <span>Name</span>
                <span class="company-details_value">{{
                  workspaceOwnerDetails.companyName || "NA"
                  }}</span>
              </p>
            </div>
            <div class="company-details_year">
              <p class="company-details_label">
                <span>Year of Establishment</span>
                <span class="company-details_value">{{
                  workspaceOwnerDetails.establishmentDate | date : "yyyy"
                  }}</span>
              </p>
              <p class="company-details_label">
                <span>Employees</span>
                <span class="company-details_value">{{
                  workspaceOwnerDetails.userCount + workspaceOwnerDetails.adminCount +
                  workspaceOwnerDetails.workspaceOwnerCount
                  }}</span>
              </p>
            </div>
            <div class="company-website">
              <img src="assets/16x16/website.svg" alt="" />
              <a [href]="workspaceOwnerDetails.companyWebsiteLink" target="_blank" class="website-link">
                {{ workspaceOwnerDetails.companyWebsiteLink }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="dash-section-wrapper">
        <div class="subscription-details-container">
          <h3 class="subscription-title">Subscription Details</h3>

          <div class="plan-info">
            <span class="plan-name"><strong>Standard Plan</strong></span>
            <span class="plan-expiry">expiring on
              <span class="expiry-date">{{
                workspaceOwnerDetails.plan[0].activeTo | date : "dd MMMM yyyy"
                }}</span></span>
          </div>

          <ul class="plan-features">
            <li>
              ✔
              <span *ngIf="workspaceOwnerDetails.plan[0].plan.workspaceOwnerLimit !== 10000000; else unlimited">
                {{ workspaceOwnerDetails.plan[0].plan.workspaceOwnerLimit }}
              </span>
              <ng-template #unlimited>Unlimited</ng-template>
              Workspace Owners
            </li>
            <li>
              ✔
              <span *ngIf="workspaceOwnerDetails.plan[0].plan.adminLimit !== 10000000; else unlimited">
                {{ workspaceOwnerDetails.plan[0].plan.adminLimit }}
              </span>
              <ng-template>Unlimited</ng-template>
              IT Admins
            </li>
            <li>
              ✔
              <span *ngIf="workspaceOwnerDetails.plan[0].plan.userLimit !== 10000000; else unlimited">
                {{ workspaceOwnerDetails.plan[0].plan.userLimit }}
              </span>
              <ng-template>Unlimited</ng-template>
              End Users
            </li>
            <li>
              ✔
              <span *ngIf="workspaceOwnerDetails.plan[0].plan.assetLimit !== 10000000; else unlimited">
                {{ workspaceOwnerDetails.plan[0].plan.assetLimit }}
              </span>
              <ng-template>Unlimited</ng-template>
              Assets
            </li>
          </ul>

          <div class="purchase-info">
            <div>
              <span class="purchase-label">Purchased on</span>
              <div class="purchase-date">{{
                workspaceOwnerDetails.plan[0].createdAt | date : "dd MMMM yyyy"
                }}</div>
            </div>
            <div>
              <span class="amount-label">Amount paid</span>
              <div class="amount-value">{{ workspaceOwnerDetails.plan[0].plan.currency.symbol }} {{
                workspaceOwnerDetails.plan[0].plan.price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dash-section-wrapper">
      <div class="projects-header">
        <p class="asset-heading">Projects</p>
        <app-button class="spacing" type="submit" size="xsmall" theme="secondary" (click)="onAddProject()"
          class="add-project-button">Add Project</app-button>
      </div>
      <app-project-search (searchData)="onSearch($event)"></app-project-search>
      <div class="table-container" [@basicAnimation]>
        <table class="table" cdk-table [dataSource]="dataSource">
          <!-- Projects Column -->
          <ng-container cdkColumnDef="projects">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head">
              Projects ({{ displayedColumnsCount.projects }})
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data do-flex">
              <p class="count-with-asset-type asset-name">
                <span class="table-data">{{ element?.name }}</span>
              </p>
            </td>
          </ng-container>

          <!-- Assets Linked Column -->
          <ng-container cdkColumnDef="assetsLinked">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head">
              Assets Linked ({{ displayedColumnsCount.assetsLinked }})
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data">
              {{ element?.assetLinked }}
            </td>
          </ng-container>

          <!-- Created By Column -->
          <ng-container cdkColumnDef="createdBy">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head">
              Created By
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data">
              {{ element?.createdBy }}
            </td>
          </ng-container>

          <!-- Created On Column -->
          <ng-container cdkColumnDef="createdOn">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head">
              Created On
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data">
              {{ element?.createdOn }}
            </td>
          </ng-container>

          <ng-container cdkColumnDef="edit">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head">Edit Project</th>
            <td cdk-cell *cdkCellDef="let element" class="table-data">
              <span class="edit-button">
                <img [src]="editButtonImage" class="edit-btn-img" (click)="onEditProject(element)" />
              </span>
            </td>
          </ng-container>
          <!-- Header and Rows -->
          <tr cdk-header-row *cdkHeaderRowDef="displayedColumns" class="table-head-row"></tr>
          <tr cdk-row *cdkRowDef="let row; columns: displayedColumns" class="table-body-row"></tr>
        </table>
        <div class="pagination-wrapper">
          <div class="filter-wrapper">
            <p class="filter-text">View Rows</p>
            <app-generic-drop-down [label]="pageCount" (index)="pageSizeEmitter($event)" [options]="pageSizeOptions"
              [title]="pageCount || '5'" class="drop-down-container" [showDropContainerTop]="true"
              [shouldEmitIndex]="true"></app-generic-drop-down>
          </div>

          <app-pagination (responseData)="paginationEmitter($event)" [recordsPerPage]="+pageCount" [count]="count"
            [reload]="reload">
          </app-pagination>
        </div>
      </div>
    </div>

    <div class="dash-section-wrapper">
      <div class="projects-header">
          <p class="asset-heading">Vendors</p>
          <app-button class="spacing" type="submit" size="xsmall" theme="secondary" (click)="onAddVendor()"
              class="add-vendor-button">Add Vendor</app-button>
      </div>
      <app-vendor-search (searchData)="onVendorSearch($event)"></app-vendor-search>
      <div class="table-container" [@basicAnimation]>
          <table class="table" cdk-table [dataSource]="vendorsDataSource">
              <!-- Name Column -->
              <ng-container cdkColumnDef="name">
                  <th cdk-header-cell *cdkHeaderCellDef class="table-head">Name</th>
                  <td cdk-cell *cdkCellDef="let element" class="table-data">{{ element?.name }}</td>
              </ng-container>

              <!-- Email Column -->
              <ng-container cdkColumnDef="email">
                  <th cdk-header-cell *cdkHeaderCellDef class="table-head">Email</th>
                  <td cdk-cell *cdkCellDef="let element" class="table-data">{{ element?.email }}</td>
              </ng-container>

              <!-- Phone Number Column -->
              <ng-container cdkColumnDef="phoneNumber">
                  <th cdk-header-cell *cdkHeaderCellDef class="table-head">Phone Number</th>
                  <td cdk-cell *cdkCellDef="let element" class="table-data">{{ element?.phoneNumber }}</td>
              </ng-container>

              <!-- Header and Rows -->
              <tr cdk-header-row *cdkHeaderRowDef="['name', 'email', 'phoneNumber']" class="table-head-row"></tr>
              <tr cdk-row *cdkRowDef="let row; columns: ['name', 'email', 'phoneNumber']" class="table-body-row"></tr>
          </table>
          <div class="pagination-wrapper">
              <div class="filter-wrapper">
                  <p class="filter-text">View Rows</p>
                  <app-generic-drop-down [label]="vendorPageCount" (index)="vendorPageSizeEmitter($event)" [options]="pageSizeOptions"
                      [title]="vendorPageCount || '5'" class="drop-down-container" [showDropContainerTop]="true"
                      [shouldEmitIndex]="true"></app-generic-drop-down>
              </div>

              <app-pagination (responseData)="vendorPaginationEmitter($event)" [recordsPerPage]="+vendorPageCount" [count]="vendorCount"
                  [reload]="reload">
              </app-pagination>
          </div>
      </div>
  </div>

    <div class="dash-section-wrapper">
      <div class="projects-header">
        <p class="asset-heading">
          Asset Types</p>
        <app-button class="spacing" type="submit" size="xsmall" theme="secondary" (click)="onAddAssetType()"
          class="add-asset-type-button">
          Add Asset Type
        </app-button>
      </div>

      <div class="wrapper" [@basicAnimation]>
        <ng-container *ngIf="workspaceOwnerDetails?.assetTypes?.length !== 0; else noAssets">
          <div *ngFor="let details of workspaceOwnerDetails?.assetTypes" (click)="onAssetTypeClick(details.id)"
            class="asset-group">
            <app-cards [assets]="details || false"></app-cards>
          </div>
        </ng-container>
        <ng-template #noAssets>
          <app-cards [assets]="false"></app-cards>
          <app-empty-placeholder imageSrc="assets/58x58/no_assets.svg" [title]="placeholderTitle"
            [subtitle]="placeholderSubtitle"></app-empty-placeholder>
        </ng-template>
      </div>
    </div>
    <div class="dash-section-wrapper">
      <div class="projects-header">
        <p class="asset-heading">Accessory Types</p>

        <app-button class="spacing" type="submit" size="xsmall" theme="secondary" (click)="onAddAccessoryType()"
          class="add-accessory-type-button">
          Add Accessory Type
        </app-button>
      </div>

      <div class="wrapper" [@basicAnimation]>
        <ng-container *ngIf="
            workspaceOwnerDetails?.accessories_type?.length !== 0;
            else noAccessory
          ">
          <div *ngFor="
              let accessoriesDetails of workspaceOwnerDetails?.accessories_type
            " (click)="onAccessoryTypeClick(accessoriesDetails.id)" class="asset-group">
            <app-cards [accessory]="accessoriesDetails || false"></app-cards>
          </div>
        </ng-container>
        <ng-template #noAccessory>
          <app-cards [accessory]="false"></app-cards>
          <app-empty-placeholder imageSrc="assets/58x58/no_assets.svg" [title]="'No accessory types assigned'"
            [subtitle]="placeholderSubtitle"></app-empty-placeholder>
        </ng-template>
      </div>
    </div>
    <div class="dash-section-wrapper">
      <p class="asset-heading">Users</p>

      <!-- Tabs Section -->
      <div class="users-tabs">
        <button *ngFor="let tab of userTabs" class="tab-link" [class.active]="selectedUserTab === tab.value"
          (click)="onTabChange(tab.value)">
          {{ tab.label }} ({{ tab.count }})
        </button>
      </div>
      <app-user-search [searchOptions]="userSearchOptions" (searchData)="onUserSearch($event)"></app-user-search>
      <!-- Table Container -->
      <div class="table-container" [@basicAnimation]>
        <table class="table" cdk-table [dataSource]="usersDataSource">
          <!-- Name Column -->
          <ng-container cdkColumnDef="name">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head text-left">
              Name
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data text-left">
              <div class="user-info-cell">
                <div class="avatar-container">
                  <img [src]="element?.profileImage || 'assets/40x40/user-default.svg'" alt="" class="user-avatar" />
                </div>
                <div class="employee-info">
                  <a class="user-name" (click)="onUserClick(element?.id)">{{ element?.name }}</a>
                  <div class="email">{{ element?.isPrimary ? "Primary" : "" }}</div>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Employee ID and Email Column -->
          <ng-container cdkColumnDef="employeeId">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head text-left">
              Employee ID and Email
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data text-left">
              <div class="employee-info">
                <div>{{ element?.employeeId }}</div>
                <div class="email">{{ element?.email }}</div>
              </div>
            </td>
          </ng-container>

          <!-- Designation Column -->
          <ng-container cdkColumnDef="designation">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head text-left">
              Designation
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data text-left">
              {{ element?.designation }}
            </td>
          </ng-container>

          <!-- Reporting Manager Column -->
          <ng-container cdkColumnDef="reportingManager">
            <th cdk-header-cell *cdkHeaderCellDef class="table-head text-left">
              Reporting Manager
            </th>
            <td cdk-cell *cdkCellDef="let element" class="table-data text-left">
              {{ element?.reportingManager || "Not Available"}}
            </td>
          </ng-container>

          <!-- Header and Row Definitions -->
          <tr cdk-header-row *cdkHeaderRowDef="userDisplayedColumns" class="table-head-row"></tr>
          <tr cdk-row *cdkRowDef="let row; columns: userDisplayedColumns" class="table-body-row"></tr>
        </table>

        <!-- Pagination -->
        <div class="pagination-wrapper">
          <ng-container *ngIf="userCount > 0">
            <div class="filter-wrapper">
              <p class="filter-text">View Rows</p>
              <app-generic-drop-down [options]="pageSizeOptions" [title]="userPageCount"
                (index)="userPageSizeEmitter($event)" class="drop-down-container" [showDropContainerTop]="true"
                [shouldEmitIndex]="true"></app-generic-drop-down>
            </div>

            <app-pagination (responseData)="userPaginationEmitter($event)" [recordsPerPage]="+userPageCount"
              [count]="userCount" [reload]="userReload" #userPagination>
            </app-pagination>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <router-outlet *ngIf="showAddAssetType"></router-outlet>
  <router-outlet *ngIf="showAddAccessoryType"></router-outlet>
  <!-- <router-outlet *ngIf="showAssetDetails "></router-outlet> -->
</div>
