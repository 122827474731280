import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { convertToTitleCase, IUserData } from "@common/interfaces";
import { CommonHttpService } from "libs/common/src/lib/service/common-http.service";

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
    userId!: string;
    userDetails!: IUserData;
    loaderView: boolean = false;
    isuserDetailsModal: boolean = false;
    constructor(
        private commonHttpService: CommonHttpService,
        @Inject(DIALOG_DATA) public data: any,
        private dialogRef: DialogRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.userId = data.id;
    }

    ngOnInit(): void {
        this.fetchUserDetails();
    }

    closeDialog(action?: string) {
        this.dialogRef.close(action);
    }

    fetchUserDetails() {
        this.isuserDetailsModal = true;
        this.loaderView = true;
        this.commonHttpService.getOneUserDetails(this.userId).subscribe({
            next: (response: any) => {
                this.userDetails = response.data;
                const { user_role: { roleId } } = this.userDetails;
                this.userDetails.role = convertToTitleCase(roleId);
                this.loaderView = false;
            },
            error: (error) => {
                console.error('Error fetching user details:', error);
            }
        });
    }

    onEditUserRole() {
        this.closeDialog("Edit User Role");
    }
}