import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ROUTES } from '../constants/routes.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): boolean {
    const token = localStorage.getItem('token');  // Get the token from localStorage
    const userData = JSON.parse(localStorage.getItem('userInfo') as string);  // Get user data from localStorage

    if (token && userData) {
      const roles = userData.roles;

      // Check if the user has the ADMIN role
      if (roles.includes('ADMIN')) {
        this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);  // Navigate to admin dashboard
        return false;  // Prevent navigation to sign-in page
      }

      // Check if the user has the USER role
      if (roles.includes('USER')) {
        this.router.navigate([ROUTES.USER_DASHBOARD]);  // Navigate to user dashboard
        return false;  // Prevent navigation to sign-in page
      }

      // If no matching role is found, default to user dashboard (or handle as needed)
      this.router.navigate([ROUTES.USER_DASHBOARD]);
      return false;
    }

    // If no token or userData, allow navigation to the sign-in page
    return true;
  }
}
