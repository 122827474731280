import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'libs/shared/web/auth/auth.service';
import { SignInService } from 'libs/shared/web/auth/sign-in/sign.in.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService {
  constructor(private injector: Injector, private router: Router, private authService: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authService = this.injector.get(SignInService);

    if (!window.navigator.onLine) {
      return throwError(() => new HttpErrorResponse({ error: 'Internet is required.' }));
    } else {
      let tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authService.getToken()}`,
          workspaceId: `${authService.getWorkspaceId()}`,
        },
      });

      return next.handle(tokenizedReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Token expired or invalid, trigger logout immediately
            this.authService.logOut();
            this.router.navigate(['/signin']); // Redirect to login page
          }
          return throwError(() => error);
        })
      );
    }
  }
}
