  import { NgModule } from '@angular/core';
  import { RouterModule, Routes } from '@angular/router';
  import { ROUTES } from '@common/interfaces';
import { AuthGuard } from 'libs/common/src/lib/guards/auth.guard';
import { SignInComponent } from 'libs/shared/web/auth/sign-in/sign-in.component';


  const routes: Routes = [
    { path: '', redirectTo: ROUTES.SIGN_IN, pathMatch: 'full' },
    { path: ROUTES.SIGN_IN, component: SignInComponent, canActivate: [AuthGuard] },
  ]
  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
  })
  export class AppRoutingModule { }
