import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ISearchData, ROUTES } from '@common/interfaces';
import {
  PAGINATION_DATA,
  WORKSPACE_MENU,
  WORKSPACE_SEARCH_OPTIONS,
} from 'libs/common/src/lib/constants/constants';
import { getWorkspaceTableData } from 'libs/common/src/lib/constants/shared-common';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';

@Component({
  selector: 'app-manage-workspace',
  templateUrl: './manage-workspace.component.html',
  styleUrls: ['./manage-workspace.component.scss'],
})
export class ManageWorkspaceComponent implements OnInit {
  workspaceMenu = WORKSPACE_MENU;
  loaderView: boolean = false;
  WORKSPACE_SEARCH_OPTIONS = WORKSPACE_SEARCH_OPTIONS;
  workspaceDetails: any;
  PAGINATION_DATA = PAGINATION_DATA;
  pageSize = '5';
  count!: number;
  currentPageNumber = 1;
  searchColumn!: string;
  searchText!: string;
  status: string = '';
  sortDigit = 'DESC';
  workspacetableDetails: any;
  @ViewChild('app-manage-workspace-details') manageWorkspaceDetails: any;

  constructor(
    private http: HttpClient,
    private commonHttpService: CommonHttpService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {}

  tableConfig = [
    {
      title: 'Name',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: 'name',
        from: 'workspace', // Directly referencing the name field
      },
    },
    {
      title: 'Status',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: 'status',
        from: 'workspace',
        isStatus: true,
      },
    },
    {
      title: 'Expires On',
      hasMultiKeys: false,
      sortAction: (col: string) => {
        this.handleSortAction(col);
      },
      icon: 'sort-asc',
      config: {
        keys: 'expiresOn',
        from: 'workspace',
        isDate: true,
      },
    },
    {
      title: 'View Details',
      hasMultiKeys: false,
      config: {
        keys: 'viewDetails', // Using the asset ID for the view action
        handleAction: (workspace_id: string) => {
          this.navigateToAssetDetails(workspace_id);
        },
      },
    },
  ];

  ngOnInit(): void {
    this.loadCounters();
    this.getAllWorkspaces();
  }

  loadCounters() {
    this.commonHttpService.getAllWorkspaceCount().subscribe((res: any) => {
      const data = Object(res).data;
      this.workspaceMenu[0].count = res.data[0]?.count;
      this.workspaceMenu[1].count = res.data[1]?.count;
      this.workspaceMenu[2].count = res.data[2]?.count;
    });
  }
  createWorkspaceIsClicked(event: Event) {
    this.router.navigate([`${ROUTES.SUPER_ADMIN_MANAGE_WORKSPACE}/workspace`]);
  }

  getAllWorkspaces(param?: any) {
    this.loaderView = true; // Assuming you want to show loader

    const params = {
      searchColumn: this.searchColumn || '',
      searchText: this.searchText || '',
      workSpaceStatus: this.status,
      pageNumber: param?.pageNumber || '1',
      itemsPerPage: this.pageSize || '5',
      sortColumn: param?.sortColumn,
      sortDirection: param?.sortDirection,
    };

    const loadRequest = this.commonHttpService.getAllWorkSpaces(params);

    if (loadRequest) {
      loadRequest.subscribe({
        next: (response) => {
          const workspaceData = Object(response).data.workspaces;

          // Process workspace data into the required table format
          this.workspacetableDetails = getWorkspaceTableData(workspaceData);


          // Update the count if present in the response
          this.count = workspaceData.count;

          // Optionally, you can store or pass the search parameters as needed
          this.commonService.searchParam.next({
            searchColumn: param?.searchColumn,
            searchText: param?.searchText,
            sortColumn: param?.sortColumn,
            sortDirection: param?.sortDirection,
          });

          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false; // Hide loader on error
        },
      });

      // Update the current page number and toggle the sort direction
      this.currentPageNumber = param?.pageNumber;
      this.sortDigit = this.sortDigit === 'DESC' ? 'ASC' : 'DESC';
      this.changeDetectorRef.detectChanges();
    }
  }


  search(searchData: ISearchData) {
    this.searchColumn = searchData.filterBy;

    // Check if the filter is 'WORKSPACE_STATUS' and convert search text
    if (this.searchColumn === 'WORKSPACE_STATUS') {
      const normalizedSearchText = searchData.searchStr?.trim().toLowerCase();
      if (normalizedSearchText === 'active') {
        this.searchText = 'true';
      } else if (normalizedSearchText === 'inactive') {
        this.searchText = 'false';
      } else {
        this.searchText = '';
      }
    } else {
      // For other columns, keep the search text as is
      this.searchText = searchData.searchStr?.trim();
    }

    this.getAllWorkspaces();
  }

  pageSizeChange(pageSize: string) {
    this.pageSize = pageSize;
    this.getAllWorkspaces();
  }

  handleSortAction(col: string) {
    const param = {
      sortColumn: '',
      sortDirection: this.sortDigit,
      pageNumber: this.currentPageNumber,
    };

    // Update the icon based on the sort direction
    const iconConfig = this.tableConfig.filter(
      (response: any) => response.title === col
    );
    iconConfig[0].icon = this.sortDigit === 'ASC' ? 'sort-asc' : 'sort-desc';

    // Switch to determine which column to sort by based on the title
    switch (col) {
      case 'Name':
        param.sortColumn = 'name'; // Sort by 'name' field
        break;

      case 'Status':
        param.sortColumn = 'acronyms'; // Sort by 'acronyms' field (or adjust based on status logic)
        break;

      case 'Expires On':
        param.sortColumn = 'expiresOn'; // Sort by 'expiresOn' field
        break;

      case 'View Details':
        // View Details doesn't need sorting logic; it's more of an action link
        param.sortColumn = ''; // No sorting for 'View Details'
        break;

      default:
        param.sortColumn = ''; // Default case, no sorting
        break;
    }

    // Call the function to fetch sorted data
    this.getAllWorkspaces(param);
  }

  navigateToAssetDetails(workspace_id: string) {
    const buildURL = this.commonService.buildURL(
      `${ROUTES.MANAGE_WORKSPACE_DETAILS}/${workspace_id}`, // Added a '/' between the route and workspace_id
      {
        page: this.currentPageNumber,
        size: this.pageSize,
        search: this.searchText,
        column: this.searchColumn,
        status: this.status,
      }
    );
    this.router.navigateByUrl(buildURL);
  }
}
