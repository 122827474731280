import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ASSET_INFO_HEADINGDS, ROUTES } from '@common/interfaces';
import { Store } from '@ngrx/store';

import { getAssetCounts } from 'libs/common/src/lib/constants/shared-common';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { reset } from 'store/search-state/search.action';
import { ISearchState } from 'store/search-state/search.state';

@Component({
  selector: 'app-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss'],
  animations: [
    trigger('basicAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AssetInfoComponent implements OnInit {
  @Input() assets!: any;
  @Input() displayDashboardContainer!: boolean;
  @Input() title: string = '';
  @Input() isDashboard!: boolean;
  @Input() isAssetTypeCounter: boolean = false;
  @Input() hasAddAsset = false;
  @Input() hasEditAsset = false;
  @Output() addAssetClicked = new EventEmitter();
  @Output() createWorkspaceClicked = new EventEmitter();
  @Output() editAssetClicked = new EventEmitter();
  @Output() buttonClick = new EventEmitter();
  @Output() onClientSelect = new EventEmitter<string>();
  @Input() buttonText!: string;
  @Input() titleForClientDropDown!: string;
  type!: string;
  status!: string;
  ticketType!: string;
  queryParams!: any;
  displayedColumns: string[] = [
    'assets',
    'assigned',
    'unAssigned',
    'outForRepair',
    'inHouseRepair',
    'disabled',
  ];
  displayedColumnsCount = {
    totalAssets: 0,
    assignedAssets: 0,
    unAssignedAssets: 0,
    outForRepairAssets: 0,
    inHouseRepairAssets: 0,
    disabledAssets: 0,
  };
  isDisabled = false;
  dataSource!: any;
  loaderView: boolean = false;
  showButton: boolean = true;
  currentParam!: string;
  isAdmin!: boolean;
  isSemiAdmin!: boolean;
  isEkoAdmin!: boolean;
  showWorkSpace!: boolean;
  isDownloadReportButtonDisabled: BehaviorSubject<{
    from: string;
    flag: boolean;
  }> = this.commonService.makeDownloadReportButtonDisabled;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private commonHttpService: CommonHttpService,
    private store: Store<ISearchState>
  ) {}

  ngOnInit(): void {
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'ADMIN';
    this.isSemiAdmin =
      JSON.parse(localStorage.getItem('userInfo') || '{}')?.roles[0] ===
      'SEMI-ADMIN';
    this.activatedRoute.queryParams.subscribe((param) => {
      this.currentParam = param['provider'];
      this.isDisabled =
        this.currentParam === 'EKO CLIENT' ? !this.isDisabled : false;
    });
    this.getCurrentMenu();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.status = params['status'];
      this.getCurrentMenu();
    });
    this.getAssetCountMetrics();
    if (this.title === ASSET_INFO_HEADINGDS.TICKET_STATUS) {
      this.buttonText = `Download Ticket List`;
    }
    if (this.title === ASSET_INFO_HEADINGDS.ASSET_STATUS) {
      this.buttonText = `Download Asset List`;
    }

    if (this.router.url === ROUTES.ASSET_TEAM_DASHBOARD + '/coditas') {
      this.showButton = false;
    }
    const isManageWorkspaceWithQueryParams =
      this.router.url.startsWith(ROUTES.SUPER_ADMIN_MANAGE_WORKSPACE) &&
      this.router.url.includes('?size=5&column=WORKSPACE_NAME');

    if (
      this.router.url === ROUTES.SUPER_ADMIN_MANAGE_WORKSPACE ||
      isManageWorkspaceWithQueryParams
    ) {
      this.showButton = false;
      this.showWorkSpace = true;
    }

    this.activatedRoute.queryParams.subscribe((param) => {
      this.currentParam = param['provider'];
    });
  }
  getAssetCountMetrics() {
    this.loaderView = true;
    const loadRequest = this.commonHttpService.getAllAssetTypeCounts();
    if (loadRequest) {
      loadRequest.subscribe({
        next: (response) => {
          const formattedResponse = Object(response).data;

          // Update dataSource with assetsCount array from the response
          this.dataSource = formattedResponse.assetsCount;
          // Update displayedColumnsCount with summary values from the response
          this.displayedColumnsCount.totalAssets =
            formattedResponse.assetsTotalByStatus.totalByType;
          this.displayedColumnsCount.assignedAssets =
            formattedResponse.assetsTotalByStatus.allocated;
          this.displayedColumnsCount.unAssignedAssets =
            formattedResponse.assetsTotalByStatus.available;
          this.displayedColumnsCount.outForRepairAssets =
            formattedResponse.assetsTotalByStatus.outForRepair;
          this.displayedColumnsCount.inHouseRepairAssets =
            formattedResponse.assetsTotalByStatus.inHouseRepair;
          this.displayedColumnsCount.disabledAssets =
            formattedResponse.assetsTotalByStatus.disabled;

          this.loaderView = false;
        },
        error: () => {
          this.loaderView = false;
          this.dataSource = [];
        },
      });
    }
  }

  setAssetPageParam(param: string) {
    this.store.dispatch(reset());
    this.commonService.assetsPageParam = param;
    if (this.router.url !== '/home/asset-team-dashboard/coditas') {
      if (this.currentParam === 'EKO CLIENT') {
        const queryParams = { provider: 'EKO CLIENT', status: param };
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
        });
      } else {
        const queryParams = { provider: 'CODITAS', status: param };
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
        });
      }
    }
  }
  getCurrentMenu() {
    if (this.router.url.includes('ticket')) {
      this.type = 'ticket';
    } else if (this.router.url.includes('assets')) this.type = 'assets';
  }

  addAssetModal(event: Event) {
    this.addAssetClicked.emit(true);
  }
  CreateWorkspaceModal(event: Event) {
    this.createWorkspaceClicked.emit(true);
  }
  onDownload() {
    this.buttonClick.emit();
  }

  handleAssetClick(value: string) {
    const selectedAsset = value === 'Mobile Phone' ? 'Mobile' : value;
    this.commonService.filterAssetByAssetName(selectedAsset);
    const queryParams = { assetType: selectedAsset };
    this.router.navigate([ROUTES.ADMIN_INVENTORY], { queryParams });
  }

  onSelectClient(client: string) {
    this.onClientSelect.emit(client);
  }

  isDownloadButtonDisabled(): boolean {
    const shouldDisable =
      this.title === this.isDownloadReportButtonDisabled.getValue().from
        ? this.isDownloadReportButtonDisabled.getValue().flag
        : false;
    return shouldDisable;
  }
}
