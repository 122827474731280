<router-outlet #outlet="outlet"></router-outlet>

<ng-container *ngIf="!outlet.isActivated">
  <div class="asset-team-container">
    <app-asset-info
      [assets]="workspaceMenu"
      [title]="'Workspace Management'"
      (createWorkspaceClicked)="createWorkspaceIsClicked($event)"
    ></app-asset-info>
    <div class="divider-container"><div class="divider"></div></div>

    <div
      class="dash-section-wrapper container-with-no-border"
      [ngClass]="{ loader: loaderView }"
    >
      <app-loader *ngIf="loaderView"></app-loader>
      <app-table
        [tableConfig]="tableConfig"
        [tableData]="workspacetableDetails"
        [searchOptions]="WORKSPACE_SEARCH_OPTIONS"
        (searchData)="search($event)"
        (paginatorEmitter)="getAllWorkspaces($event)"
        [filterOptions]="PAGINATION_DATA"
        (pageSizeChange)="pageSizeChange($event)"
        [activeMenu]="pageSize"
        [count]="count"
        [displayTicketList]="false"
        [currentPageNumber]="currentPageNumber"
        class="width-100"
      ></app-table>
    </div>
  </div>
</ng-container>
