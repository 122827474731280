<div class="modal-wrapper" *ngIf="!popUpConfig.fileSrc">
  <div class="popup-heading">
    <div class="popup-header">
      <div class="popup-icon">
        <img src="{{ iconType }}" alt="" />
      </div>
      <div>
        <h1 class="popup-header-title">{{ popUpConfig.title }}</h1>
        <p class="popup-header-subtitle">{{ popUpConfig.subtitle }}</p>
      </div>
    </div>
    <div class="close" (click)="closeDialog()">
      <img src="assets/24x24/close.svg" alt="" />
    </div>
  </div>
  <div class="popup-main">

    <ng-container *ngIf="!hasSummary">
      <ng-container *ngIf="!popUpConfig.hasForm; else form">
        <div class="field-container" *ngFor="let key of keys; let index">
          <ng-container *ngIf="key !== 'hasAssetAlready'">
            <p class="label">{{ key }}</p>
            <div
              *ngFor="
                let field of popUpConfig.data[key] | keyvalue : originalOrder
              "
            >
              <div class="field">
                <p class="field-key">{{ field.key }}</p>
                <p>:</p>
                <p class="field-value">{{ field.value }}</p>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="popUpConfig.data?.['hasAssetAlready']?.['data']">
          <p class="warning">Warning: This asset has been already assigned to this user.</p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="popUpConfig.showTextarea">

        <div class="reason-container">
          <label class="label select-lonee-text">Reason *</label>
          <app-textarea
            class="text-area"
            cols="30"
            rows="10"
            [formControl]="textArea"
            characterCounter
            maxLength="255"
            (countEvent)="displayCount($event)"
            [countData]="countData"
            [placeholder]="'Enter your reason here...'"
            [isRequired]="true"
            [isLabelPresent]="false"
          ></app-textarea>
        </div>
      </ng-container>
      <ng-template #form>
        <div class="form">
          <h3>Assign New Asset</h3>
          <app-text-field
            type="text"
            placeholder="CSL-L-21-22-L-0001"
            class="text-feild"
            [(ngModel)]="assetCode"
          ></app-text-field>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="hasSummary">
      <div *ngFor="let count of uploadCounts">
        <ng-container
          *ngIf="count?.status === 'success'; else failedFilesSection"
        >
          <div class="container">
            <div class="header">
              <div>
                <label class="label">File uploaded successfully</label>
                <p class="file-name">{{ count?.fileName }}</p>
              </div>
              <div>
                <img src="assets/12x12/tick.svg" alt="" />
              </div>
            </div>
            <div class="counts">
              <div class="row">
                <p>Total</p>
                <p>: {{ count?.totalCount || 0 }}</p>
              </div>
              <div class="row">
                <p>Successful</p>
                <p>: {{ count?.successCount || 0 }}</p>
              </div>
              <div class="row">
                <p [ngClass]="{ unsuccessful: count?.failedCount > 0 }">
                  Unsuccessful
                </p>
                <p [ngClass]="{ unsuccessful: count?.failedCount > 0 }">
                  : {{ count?.failedCount || 0 }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #failedFilesSection>
          <div class="file-and-progress-bar failed">
            <div class="show-file-list">
              <div class="file-details">
                <div class="file">
                  <img src="assets/24x24/file.svg" alt="" />
                </div>
                <div class="file-name-size">
                  <div class="file-info">
                    <p class="name">
                      {{ count?.fileName }} -
                      <span class="failed"> Upload failed</span>
                    </p>
                    <p class="size">{{ count?.fileSize | fileUnit }}</p>
                    <!-- <p class="name" *ngIf="count?.reason">
                      <small class="failed">*{{ count?.reason }}</small>
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <div class="popup-footer">
    <ng-container>
      <app-button
        size="xsmall"
        theme="secondary"
        *ngIf="popUpConfig.primaryButtonText"
        (click)="handleAction(popUpConfig.primaryButtonText)"

        >{{ popUpConfig.primaryButtonText }}

      </app-button>
    </ng-container>
    <ng-container>
      <app-button
        size="xsmall"
        theme="primary"
        *ngIf="popUpConfig.secondaryButtonText"
        [disabled]="
          popUpConfig.secondaryButtonText === 'Verify Asset' && !assetCode || ((popUpConfig.showTextarea ?? false) && !textArea.valid)
        "
        (click)="handleAction(popUpConfig.secondaryButtonText)"
        >{{ popUpConfig.secondaryButtonText }}
      </app-button>
    </ng-container>
  </div>
</div>

<div *ngIf="popUpConfig.fileSrc">
  <div class="view-media-container">
    <img
      class="close-button"
      src="assets/24x24/close.svg"
      alt="close-button"
      (click)="closeDialog()"
    />
    <img
      [src]="popUpConfig.fileSrc"
      alt="ticket-image"
      class="ticket-image"
      *ngIf="popUpConfig.isImage"
    />
    <video *ngIf="popUpConfig.isVideo" height="400" width="100%" controls>
      <source [src]="popUpConfig.fileSrc" *ngIf="popUpConfig.isVideo" />
    </video>
  </div>
</div>
