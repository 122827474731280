import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { INavigationOptions, ROUTES } from '@common/interfaces';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { AuthService } from 'libs/shared/web/auth/auth.service';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { Store } from '@ngrx/store';
import { reset } from 'store/search-state/search.action';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  accordionIcon = 'assets/12x6/Icondropdown-closed.svg';
  questionMarkIcon = 'assets/20x20/Iconquestion-mark.svg';
  accordionOpenIcon = 'assets/10x5/Iconupper-arrow.svg';
  @Output()
  showInfo: EventEmitter<boolean> = new EventEmitter();
  @Output() employeeDetailsNavigation: EventEmitter<string> =
    new EventEmitter();
  helpMenuOptions = [
    {
      label: 'Privacy Policy',
      img: 'assets/13x16/Iconprivacy-policy.svg',
      route: 'privacy-policy',
    },
    {
      label: 'Terms and Conditions',
      img: 'assets/13x16/Iconterms-and-conditions.svg',
      route: 'terms-and-conditions',
    },
  ];
  // showInfo:boolean=false;
  dashboardClicked = true;
  inventoryClicked = false;
  ticketClicked = false;
  bulkUpload = false;
  info = false;
  activeOption: string = '';
  userData: any = {};
  userId: string = '';
  helpExpanded = false;
  @Input() navigationOption!: INavigationOptions[];

  constructor(
    private authService: AuthService,
    private router: Router,
    private commonService: CommonService,
    private dialog: Dialog,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('userInfo') as string);
    this.userId = localStorage.getItem('userId') as string;
    this.helpExpanded = this.router.url.includes('/help');
    this.router.events.subscribe((route) => {
      if (route instanceof ActivationEnd) {
        this.helpExpanded = this.router.url.includes('/help');
      }
    });
  }

  logOut() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to logout?',
          description: 'This action will log out of the application',
          primaryBtn: 'Logout',
          secondaryBtn: 'Stay',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      console.log(response,"responsee")
      if (response.buttonText === 'Logout') {
        this.authService.logOut();
        this.store.dispatch(reset())
        this.router.navigate([ROUTES.SIGN_IN]);
      }
    });
  }

  goToEmployeeDetails() {
    this.employeeDetailsNavigation.emit(this.userId);
  }
  clearSessionStorage() {
    sessionStorage.removeItem('searchStr');
    sessionStorage.removeItem('filterBy');
    sessionStorage.removeItem('pageSize');
    this.store.dispatch(reset())
  }
}
