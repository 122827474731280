import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { Dialog } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { ToastrService } from 'ngx-toastr';
import { IVendor, ROUTES } from '@common/interfaces';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmationPopupWithFormComponent } from '../confirmation-popup-with-form/confirmation-popup-with-form.component';

@Component({
  selector: 'app-add-asset-modal',
  templateUrl: './add-asset-modal.component.html',
  styleUrls: ['./add-asset-modal.component.scss'],
})
export class AddAssetModalComponent implements OnInit{
  @Output() closeModal = new EventEmitter();
  @Output() alertBoxEmitter = new EventEmitter();
  pageTitle: string = '';
  maxDate = new DatePipe('en-US').transform(new Date(), 'YYYY-MM-DD');
  selectedCategory: any;

  backRouteLink: { path: string; params: any } | undefined;
  workspaceId = localStorage.getItem('workspaceId');
  loaderView: boolean = true;
  assetTypeId!: string;
  assetId: string | null = null;
  ROUTES = ROUTES;
  assetForm!: FormGroup;
  assetTypeOptions: any;
  vendorNames: Record<string, any>[] = [];
  assetCode!: string;
  acronyms: string[] = [];
  assetTypeData: { [key: string]: any } = {};
  clients: any[] = [];
  selectedValue: string = '';
  assetDetails: any;
  properties: Record<string, any> = {};

  constructor(
    private commonHttpService: CommonHttpService,
    public dialog: Dialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.assetForm = this.fb.group({
      assetCategory: ['', Validators.required],
      assetCode: ['', Validators.required],
      modelName: ['', Validators.required],
      purchaseDate: ['', Validators.required],
      vendorId: ['', Validators.required],
      ownedBy: ['', Validators.required],
      managedBy: ['', Validators.required],
    });

    this.assetId = this.activatedRoute.snapshot.paramMap.get('id');

    // Initialization logic here
    this.getVendorsList();
    this.getClients();
    this.loadAllAssetList();
    this.getAcronyms();

    this.pageTitle = 'Add Asset';
    if (this.assetId) {
      this.pageTitle = 'Edit Asset';
      this.loaderView = true;
      this.getAssetDetails();
    }

    !this.assetId && (this.loaderView = false);
  }

  getClients() {
    this.commonHttpService.getAllClientNames().subscribe({
      next: (res: any) => {
        this.clients = res.data;
      },
    });
  }

  getAcronyms() {
    this.commonHttpService.getAcronyms().subscribe({
      next: (response: any) => {
        this.acronyms = response.data;
      },
    });
  }

  loadAllAssetList() {
    this.commonHttpService.getAllAssetType().subscribe((response: any) => {
      this.assetTypeOptions = response.data.map((asset: any) => ({
        id: asset.id,
        name: asset.name,
        assetTypeCode: asset.assetTypeCode,
      }));
    });
  }

  getValueOfCategory(event: any) {
    this.assetTypeId = event;

    this.selectedValue =  this.assetTypeOptions?.find(
      (option: any) => option.id === event
    )?.name;

    this.assetForm.get('assetCategory')?.setValue(this.selectedValue);

    this.commonHttpService.getAssetTypeDetails(this.assetTypeId).subscribe({
      next: (res: any) => {
        this.assetTypeData = res.data;
        this.addConfigControls();
        this.assetTypeData['categories'] = this.assetTypeData[
          'categories'
        ].filter((category: any) =>
          this.assetTypeData['configs'].some(
            (config: any) => config.category === category.name
          )
        );
          if(this.assetId){
            this.fetchCategoryValueAndUpdateAsset();
          }
      },
      error: (err: any) => {
        this.toastrService.error('Asset type not found', '', {
          toastClass: 'toaster-wrapper',
        });
      },
    });
  }

  getConfigsByCategory(categoryName: string) {
    return this.assetTypeData['configs'].filter(
      (config: any) => config.category === categoryName
    );
  }

  addConfigControls(): void {
    if (!this.assetTypeData?.['configs']) return;

    for (const config of this.assetTypeData['configs']) {
      this.assetForm.addControl(
        config.name,
        new UntypedFormControl(
          '',
          config.isRequired ? Validators.required : null
        )
      );

      if (config.units?.length) {
        this.assetForm.addControl(
          config.name + 'Unit',
          new UntypedFormControl(
            '',
            config.isRequired ? Validators.required : null
          )
        );
      }
    }
  }

  onSelectionChange(event: any, controlName: string) {
    this.assetForm.get(controlName)?.setValue(event);
    this.properties[controlName] = event;
  }

  getVendorsList() {
    this.vendorNames = [];
    this.commonHttpService.getAllVendorNames().subscribe({
      next: (response: any) => {
        if (Array.isArray(response.data.rows)) {
          response.data.rows.forEach((item: IVendor) => {
            if (item.name !== null) {
              this.vendorNames.push({ id: item.id, name: item.name });
            }
          });
        }
      },
      error: (err) => {
        this.toastrService.error("Error loading vendor's list", '', {
          toastClass: 'toaster-wrapper',
        });
      },
    });
  }

  onGenerateCode(event: any) {
    const formData = {
      header: 'Generate Asset Code',
      description: 'Please enter the acronym and financial year',
      image: 'cautionImage',
      form: {
        confirmationForm: new UntypedFormGroup({
          acronym: new UntypedFormControl('', [Validators.required]),
          financialYear: new UntypedFormControl('', [Validators.required]),
        }),
      },
      fields: [
        {
          field: 'text-field',
          controlName: 'financialYear',
          placeholder: 'E.g., 2024-2025',
          label: 'Financial Year *',
        },
        {
          field: 'dropdown',
          controlName: 'acronym',
          options: [...this.acronyms],
          title: 'Select Acronym',
          shouldEmitOption: true,
          label: 'Acronym *',
          labelType: 'text-label',
        },
      ],
      primaryBtn: 'Add',
    };

    const dialogRef = this.dialog.open(ConfirmationPopupWithFormComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      disableClose: true,
      width: '50%',
      data: formData,
    });
    dialogRef.closed.subscribe((result: any) => {
      if (result) {
        this.loaderView = true;
        const response = this.commonHttpService
          .getAssetCode(this.assetTypeId, {
            acronym: result.acronym,
            financialYear: result.financialYear,
          })
          .subscribe({
            next: (res: any) => {
              this.onSelectionChange(res.data.newAssetCode, 'assetCode');
              this.assetCode = res.data.newAssetCode;
              this.loaderView = false;
            },
            error: (err: any) => {
              this.loaderView = false;
              this.toastrService.error('Error generating asset code', '', {
                toastClass: 'toaster-wrapper',
              });
            },
          });
      }
    });
  }

  onAssetCodeChange($event: any) {
    this.assetCode = $event.target.value;
    this.assetForm.get('assetCode')?.setValue(this.assetCode);
  }

  onSubmit(): void {
    this.assetForm.updateValueAndValidity();
    this.assetForm.markAllAsTouched();
    if (this.assetForm.valid) {
      this.loaderView = true;

      this.assetForm.value.purchaseDate = this.assetForm.value.purchaseDate
        .split('-')
        .reverse()
        .join('/');

      const {
        assetCode,
        modelName,
        purchaseDate,
        vendorId,
        ownedBy,
        managedBy,
        ...otherFields
      } = this.assetForm.value;

      const properties = this.assetTypeData['configs'].map((config: any) => {
        return {
          name: config.name,
          unit: otherFields[config.name + 'Unit'] || null,
          value: otherFields[config.name] || null,
        };
      });

      const payload = {
        assetCode,
        type: this.assetTypeId,
        modelName,
        purchaseDate,
        vendorId,
        ownedBy,
        managedBy,
        properties,
      };

      if(!this.assetId)
        {
          this.commonHttpService.addNewAsset(payload).subscribe({
        next: (response: any) => {
          const assetId = response.data.id;
          this.toastrService.success('Asset added successfully !', '', {
            toastClass: 'toaster-wrapper',
          });
          this.loaderView = false;
          this.router.navigate([`${ROUTES.ASSET_DETAILS}${assetId}`]);
        },
        error: (err: any) => {
          this.loaderView = true;
          this.toastrService.error(
            'Asset not added',
            '',
            {
              toastClass: 'toaster-wrapper',
            }
          );
          this.loaderView = false;
          this.assetId
            ? this.router.navigate([`${ROUTES.ASSET_DETAILS}${this.assetId}`])
            : this.router.navigate([`${ROUTES.ADD_ASSET}`]);
        },
      });
    }else if(this.assetId){
      this.commonHttpService.updateAsset(this.assetId, payload).subscribe({
        next: (response: any) => {
          this.toastrService.success('Asset updated successfully !', '', {
            toastClass: 'toaster-wrapper',
          });
          this.loaderView = false;
          this.router.navigate([`${ROUTES.ASSET_DETAILS}${this.assetId}`]);
        },
        error: (err: any) => {
          this.loaderView = true;
          const errorMessage = err.error.error.errorList || 'Asset not updated';  
          this.toastrService.error(errorMessage, '', {
            toastClass: 'toaster-wrapper',
          });
          this.loaderView = false;
          this.router.navigate([`${ROUTES.ASSET_DETAILS}${this.assetId}`]);
        },
      });
    }
    }
  }

  closeConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response === 'Discard') {
        this.router.navigate([ROUTES.ADMIN_INVENTORY]);
      }
    });
  }

  backToDetails() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response) => {
      if (response === 'Discard') {
        this.router.navigate([`${ROUTES.ADD_ASSET}`]);
      }
    });
  }

  showAddAssetConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );

    dialogRef.closed.subscribe((response: any) => {
      if (response.buttonText === 'Submit Form') {
        this.onSubmit();
      }
    });
  }

  getAssetDetails() {
    this.commonHttpService.getAssetDetails(this.assetId).subscribe({
      next: (res: any) => {
        this.assetDetails = res.data.asset;
        this.assetTypeId = this.assetDetails.asset_type.id;
        this.selectedValue = this.assetDetails.asset_type.name;
        this.getValueOfCategory(this.assetTypeId);
      },
      error: (err) => {
        this.toastrService.error('Asset Not Found', '', {
          toastClass: 'toaster-wrapper',
        });
      },
    });
  }

  fetchCategoryValueAndUpdateAsset() {
    // After getValueOfCategory completes, execute the following functions
    this.updateAssetForm();
    this.updateAssetProperties();
    this.loaderView = false;
  }

  updateAssetForm() {
    const keys = [
      { key: 'assetCategory', value: this.assetTypeId },
      { key: 'assetCode', value: this.assetDetails.assetCode },
      { key: 'managedByClient', value: this.assetDetails.managedByClient },
      { key: 'ownedByClient', value: this.assetDetails.ownedByClient },
      {
        key: 'managedBy',
        value: this.clients.find(
          (client: any) => client.name === this.assetDetails.managedByClient
        )?.id,
      },
      {
        key: 'ownedBy',
        value: this.clients.find(
          (client: any) => client.name === this.assetDetails.ownedByClient
        )?.id,
      },
      { key: 'vendorId', value: this.assetDetails.vendor?.id },
      { key: 'vendorName', value: this.assetDetails.vendor?.name },
      { key: 'modelName', value: this.assetDetails.modelName },
      {
        key: 'purchaseDate',
        value: new Date(this.assetDetails?.purchaseDate)
          .toISOString()
          .split('T')[0],
      },
    ];

    keys.forEach((key) => {
      this.onSelectionChange(key.value, key.key);
    });
  }

  updateAssetProperties() {
    this.assetDetails.properties.map((property: any) => {
      this.onSelectionChange(property.value, property.name);
      if (property.unit) {
        this.onSelectionChange(property.unit, property.name + 'Unit');
      }
    });
  }
}
