import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ASSET_STATUS,
  CUSTODY_VERIFICATION_STATUS,
  IAssetDetails,
  IAssetTypeDetails,
  IMappedCategory,
  IPopupConfig,
  IProjects,
  ITicketSnackbarConfig,
  IUpdateAssetResponse,
  months,
  ROUTES,
  TRANSACTION_STATUS,
} from '@common/interfaces';

import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { ModalService } from 'libs/common/src/lib/service/modal.service';
import { GenericConfirmationPopUpComponent } from 'libs/shared/ui-components/src/lib/generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { GenericPopUpComponent } from 'libs/shared/ui-components/src/lib/generic-pop-up/generic-pop-up.component';
import { ToastrService } from 'ngx-toastr';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'],
})
export class AssetDetailsComponent implements OnInit {
  assetDetails!: IAssetDetails;
  header: string = '';
  tempStatus: any;
  isEmailVerified: boolean = false;
  isCheckedOut: boolean = false;
  employeeDetails!: any;
  assetId: string = '';
  currentAssetLocation: string = '';
  assetLocations = [];
  assetLocationsObj: any;
  assigneeName!: string;
  vendorName!: string;
  vendorId!: string;
  isAssetHistoryUpdated!: string;
  CUSTODY_VERIFICATION_STATUS = CUSTODY_VERIFICATION_STATUS;
  checkoutAssetdata!: {
    email?: string;
    assetId?: string;
    assetCode?: string;
    assetName?: string;
    transactionStatus?: string;
    assetStatus?: string;
    assetTenure?: string;
    assetTenureEndDate?: string;
    assetType?: string;
    transactionReason?: string | null;
    projectId?: string;
  };
  iconStateData: any = {
    success: 'assets/16x16/success-input-indicator.svg',
  };
  loaneeVerifyStatus: string = 'default';
  AssetAllocationStatus!: boolean;
  snackBarConfig!: ITicketSnackbarConfig;
  historyConfig = {
    status: '',
    page: '',
    size: '',
    search: '',
    column: '',
    provider: '',
  };
  alertModalConfig = {
    title: '',
    content: '',
    status: '',
    icon: '',
  };
  checkOutForRepairStatus: boolean = false;
  alertBox: boolean = false;
  assetStatusParm: string = '';
  toggleRepairConfirmModal: boolean = false;
  toggleRepairAlertModal: boolean = false;
  loaderView: boolean = false;
  alertModalLoader: boolean = false;
  // auto-complete
  tabIndex = 1;
  serial = true;
  enabledCash = true;
  value = '';
  mousedown = false;
  visibility = false;
  createdBy = { firstName: '', lastName: '', id: '' };
  @Output() editAnAsset = new EventEmitter();
  filters$: Observable<
    { email: string; firstName: string; lastName: string }[]
  > = of([]);
  userList: { email: string; firstName: string; lastName: string }[] = [];
  formControl = new UntypedFormControl(null, [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
  ]);
  currentObj!: any;
  currentLength!: number;
  disableButtonText: string = 'Disable';
  disableButtonImage: string = 'assets/14x14/Iconclosed-icon.svg';
  assetStatus: string = '';
  disableProperty: boolean = false;
  disableMessage!: string;
  updateAssetStatusResponse!: IUpdateAssetResponse;
  editButtonImage: string = 'assets/14x14/Iconedit-button.svg';
  ASSET_STATUS = ASSET_STATUS;
  ROUTES = ROUTES;
  primaryBtn!: string;
  description!: string;
  isOutForRepair: boolean = false;
  isInHouseRepair: boolean = false;
  assigneeId!: string;
  assetProvider!: string | null;
  formattedDate!: string;
  formattedTime!: string;
  textArea = new FormControl('', Validators.required);
  countData: any;
  assetType!: IAssetTypeDetails;
  mappedCategories!: IMappedCategory[];
  purchaseDate!: string;
  projects: { id: string, name: string }[] = [];
  selectedValue: string = '';
  projectId: string = '';

  constructor(
    private commonHttpService: CommonHttpService,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dialog: Dialog,
    private toastrService: ToastrService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe({
      next: (params) => {
        const { status, page, size, search, column, provider } =
          Object(params).params;
        this.historyConfig.status = status;
        this.historyConfig.page = page;
        this.historyConfig.size = size;
        this.historyConfig.search = search;
        this.historyConfig.column = column;
        this.historyConfig.provider = provider;
      },
    });
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.assetId = Object(params).id;
        // Load asset-details
        this.getAssetDetails(this.assetId);
      },
    });

    this.getAllUsers();
    this.assetStatusParm = this.commonService.assetsPageParam;
    this.getAssetLocations();
    this.getWorkspaceProjects();
  }

  getAssetLocations() {
    this.commonHttpService.getAssestLocationsList().subscribe((res: any) => {
      this.assetLocationsObj = res.data;
      this.assetLocations = Object.values(this.assetLocationsObj);
    });
  }

  isEditAssetClicked = false;
  editAsset() {
    this.isEditAssetClicked = true;
    this.router.navigate([ROUTES.EDIT_ASSET, this.assetId]);
    this.editAnAsset.next(this.assetId);
  }
  navigateToProfile(id: string) {
    this.router.navigate([ROUTES.LOANEE_PROFILE, id]);
  }
  getValue(locationValue: string) {
    let locationKey = '';
    for (let key in this.assetLocationsObj) {
      if (this.assetLocationsObj[key] === locationValue) {
        locationKey = key;
      }
    }
    this.updateAssetLocation(locationKey);
  }

  updateAssetLocation(location: string) {
    const requestBody = {
      assetId: this.assetId,
      location,
    };
    this.commonHttpService
      .assetLocationUpdate(requestBody)
      .subscribe((result: any) => { });
  }

  verify() {
    const employeeEmail = this.formControl.value;
    this.commonHttpService.verifyEmployee(employeeEmail).subscribe({
      next: (response) => {
        this.employeeDetails = Object(response).data;
        this.isEmailVerified = true;
        this.loaneeVerifyStatus = 'success';
        this.commonService.loaneeEmployeeInfo = this.employeeDetails;
        this.commonService.assetDetailVerifyData.email = employeeEmail.trim();
        this.commonService.assetDetailVerifyData.loaneeVerifyStatus =
          this.loaneeVerifyStatus;
        this.commonService.assetDetailVerifyData.isEmailVerified =
          this.isEmailVerified;
        this.formControl.disable();
      },
      error: (error) => {
        this.isEmailVerified = false;
        this.loaneeVerifyStatus = 'error';
      },
    });
  }

  getAssetDetails(assetId: any) {
    this.loaderView = true;
    this.commonHttpService.getAssetDetails(assetId).subscribe({
      next: (response: Object) => {
        const assetDetailsData = Object(response).data as IAssetDetails;
        const timestamp = assetDetailsData.asset.createdAt;
        const dateObject = new Date(timestamp);
        const month = months[dateObject.getMonth()];
        const day = dateObject.getDate().toString().padStart(2, '0');
        const year = dateObject.getFullYear();
        const hours = (dateObject.getHours() % 12 || 12)
          .toString()
          .padStart(2, '0');
        const minutes = dateObject.getMinutes().toString().padStart(2, '0');
        const period = dateObject.getHours() >= 12 ? 'PM' : 'AM';
        this.formattedDate = `${day} ${month}, ${year}`;
        this.formattedTime = `${hours}:${minutes} ${period}`;

        const purchaseDateTimestamp = assetDetailsData.asset.purchaseDate;
        const purchaseDateObject = new Date(purchaseDateTimestamp);
        const purchaseDateYear = purchaseDateObject.getFullYear();
        const purchaseDateMonth = months[dateObject.getMonth()];
        const purchaseDateDay = dateObject.getDate().toString().padStart(2, '0');
        this.purchaseDate = `${purchaseDateDay} ${purchaseDateMonth} ${purchaseDateYear}`;

        const asset = assetDetailsData.asset;
        const assetStatus = asset.status;
        const vendor = assetDetailsData.asset.vendor;
        const assignee =
          assetDetailsData.asset.asset_allocations?.[0]?.assignee;
        if (assetDetailsData.asset.assetCreatedBy) {
          this.createdBy = assetDetailsData.asset.assetCreatedBy;
        }
        this.assetProvider = null;
        this.assetDetails = assetDetailsData;

        if (assetStatus === ASSET_STATUS.DISABLED) {
          if (asset.markedAsEwaste) {
            this.assetStatus = 'E-waste';
          } else {
            this.assetStatus = 'NOT_IN_USE';
          }
          this.assigneeName = '';
          this.vendorName = '';
          this.assigneeId = '';
          this.vendorId = '';
        } else {
          this.assetStatus = assetStatus;
        }

        this.isCheckedOut = assetStatus === ASSET_STATUS.ALLOCATED;

        if (assetStatus === ASSET_STATUS.OUT_FOR_REPAIR) {
          this.isOutForRepair = true;
          this.editButtonImage =
            'assets/14x14/Iconedit-button-disabled-image.svg';
        } else if (assetStatus === ASSET_STATUS.IN_HOUSE_REPAIR) {
          this.isInHouseRepair = true;
        } else {
          this.isInHouseRepair = false;
          this.isOutForRepair = false;
          this.editButtonImage = 'assets/14x14/Iconedit-button.svg';
        }

        if (assetStatus === ASSET_STATUS.ALLOCATED) {
          this.disableProperty = true;
          this.disableMessage =
            'It is not possible to disable an allocated asset or out for repair asset';
          this.disableButtonImage = 'assets/14x14/iconizer-Iconclosed-icon.svg';
        } else if (assetStatus === ASSET_STATUS.DISABLED) {
          this.disableButtonText = 'Enable';
          this.disableButtonImage = 'assets/14x14/icon-openeye.svg';
          this.editButtonImage =
            'assets/14x14/Iconedit-button-disabled-image.svg';
        } else if (assetStatus === ASSET_STATUS.MARK_AS_EWASTE) {
          this.disableProperty = true;
          this.disableMessage =
            'It is not possible to enable a "discarded as e-waste" asset or out for repair asset';
          this.disableButtonText = 'Enable';
          this.disableButtonImage = 'assets/14x14/icon-openeye-disable.svg';
          this.editButtonImage =
            'assets/14x14/Iconedit-button-disabled-image.svg';
        }

        if (assignee) {
          this.assigneeName = `${assignee.firstName} ${assignee.lastName}`;
          this.assigneeId = assignee.id;
        } else if (vendor) {
          this.vendorName = vendor.name;
          this.vendorId = vendor.id;
        }

        this.getAssetType(asset.asset_type.id);

        this.loaderView = false;
      },
      error: () => {
        this.loaderView = false;
        this.toastrService.error('Could not load asset details.', 'Failed!', {
          toastClass: 'toaster-wrapper',
        });
      },
    });
  }

  onCancel() {
    this.formControl.enable();
    this.formControl.setValue('');
    this.isEmailVerified = false;
    this.loaneeVerifyStatus = 'default';
  }

  getSubCategories() {
    let issues = '';
    if (this.assetDetails.asset.asset_allocations?.length) {
      this.assetDetails.asset.asset_allocations[0].assetSubCategories?.forEach(
        (issue: any) => {
          issues += issue.subCategory + ', ';
        }
      );
    }
    return issues.slice(0, issues.length - 2);
  }

  getWorkspaceProjects() {
    const params = { pageNumber: 1, itemsPerPage: 50 };
    this.commonHttpService.getOwnersProjects(params).subscribe({
      next: (response: any) => {
        const projects = response.data.projects as IProjects[];
        this.projects = projects.map(({ id, name }) => ({ id, name }));
      }
    })
  }

  onSelectProject(event: any) {
    this.projectId = event;
  }

  checkedOut() {
    this.checkoutAssetdata = {
      assetId: this.assetId,
      email: this.formControl.value,
      transactionStatus: TRANSACTION_STATUS.CHECKOUT,
      transactionReason: this.textArea.value,
      projectId: this.projectId
    };
    const assetData = {
      'Loanee Details': {
        'Employee Name':
          this.employeeDetails.firstName + ' ' + this.employeeDetails.lastName,
        'Employee ID': this.employeeDetails.employeeId,
        Designation: this.employeeDetails.designation,
      },
      'Asset Details': {
        'Asset Type': this.assetDetails.asset.modelName,
        'Asset Name': this.assetDetails.asset.asset_type.name,
        'Asset ID': this.assetDetails.asset.assetCode,
      },
      hasAssetAlready: {
        data: '',
      },
    };

    this.commonHttpService
      .duplicateAssetValidate(
        this.assetDetails.asset.assetCode,
        this.employeeDetails.employeeId
      )
      .subscribe((validation) => {
        const isDuplicate = Object(validation).data;

        if (isDuplicate) {
          const dualAssetDialogRef = this.dialog.open(GenericPopUpComponent, {
            panelClass: 'center-dialog-box',
            backdropClass: 'backdrop',
            width: '50%',
            data: {
              headerIcon: 'warning',
              title: 'Duplicate asset request',
              subtitle: `These user has requested the same type of asset they currently posses. Please
            review the user current asset allocation to check if an additional one is needed.`,

              secondaryButtonText: 'Submit',
              primaryButtonText: 'Back',
            } as IPopupConfig,
          });

          dualAssetDialogRef.closed.subscribe((result) => {
            if (result === 'Submit') {
              const dialogRef = this.dialog.open(GenericPopUpComponent, {
                panelClass: 'center-dialog-box',
                backdropClass: 'backdrop',
                width: '50%',
                data: {
                  headerIcon: 'warning',
                  title: 'Do you want to check out?',
                  subtitle:
                    'Please verify the following details before checking out the asset.',
                  data: assetData,
                  secondaryButtonText: 'Check Out',
                  primaryButtonText: 'Cancel',
                } as IPopupConfig,
              });

              dialogRef.closed.subscribe((result) => {
                if (result === 'Check Out') {
                  this.commonHttpService
                    .checkInOrCheckOut(this.checkoutAssetdata)
                    .subscribe({
                      next: () => {
                        const dialogRef = this.dialog.open(
                          GenericPopUpComponent,
                          {
                            panelClass: 'center-dialog-box',
                            backdropClass: 'backdrop',
                            width: '50%',
                            data: {
                              headerIcon: 'success',
                              title: 'Check Out Successful',
                              subtitle:
                                'Asset has been checked out successfully.',
                              data: assetData,
                              secondaryButtonText: 'View Asset Details',
                              primaryButtonText: 'Proceed to Dashboard',
                            } as IPopupConfig,
                          }
                        );
                        dialogRef.closed.subscribe((result) => {
                          this.isAssetHistoryUpdated = 'Checked Out';
                          if (result === 'Proceed to Dashboard') {
                            this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
                          }
                          this.getAssetDetails(this.assetId);
                        });
                      },
                      error: (error) => {
                        const dialogRef = this.dialog.open(
                          GenericPopUpComponent,
                          {
                            panelClass: 'center-dialog-box',
                            backdropClass: 'backdrop',
                            width: '50%',
                            data: {
                              headerIcon: 'error',
                              title: 'Check Out Unsuccessful',
                              subtitle:
                              error?.error?.error?.errorList[0]?.msg ||
                                'Looks like you lost your connection. Please check your connection and try again. ',
                              data: assetData,
                              secondaryButtonText: 'Try Again',
                              primaryButtonText: 'View Asset Details',
                            } as IPopupConfig,
                          }
                        );
                        dialogRef.closed.subscribe((result) => {
                          if (result === 'Try Again') {
                            this.checkedOut();
                          }
                        });
                      },
                    });
                }
              });
            }
          });
        } else {
          const dialogRef = this.dialog.open(GenericPopUpComponent, {
            panelClass: 'center-dialog-box',
            backdropClass: 'backdrop',
            width: '50%',
            data: {
              headerIcon: 'warning',
              title: 'Do you want to check out?',
              subtitle:
                'Please verify the following details before checking out the asset.',
              data: assetData,
              secondaryButtonText: 'Check Out',
              primaryButtonText: 'Cancel',
            } as IPopupConfig,
          });

          dialogRef.closed.subscribe((result) => {
            if (result === 'Check Out') {
              this.commonHttpService
                .checkInOrCheckOut(this.checkoutAssetdata)
                .subscribe({
                  next: () => {
                    const dialogRef = this.dialog.open(GenericPopUpComponent, {
                      panelClass: 'center-dialog-box',
                      backdropClass: 'backdrop',
                      width: '50%',
                      data: {
                        headerIcon: 'success',
                        title: 'Check Out Successful',
                        subtitle: 'Asset has been checked out successfully.',
                        data: assetData,
                        secondaryButtonText: 'View Asset Details',
                        primaryButtonText: 'Proceed to Dashboard',
                      } as IPopupConfig,
                    });
                    dialogRef.closed.subscribe((result) => {
                      this.isAssetHistoryUpdated = 'Checked Out';
                      if (result === 'Proceed to Dashboard') {
                        this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
                      }
                      this.getAssetDetails(this.assetId);
                    });
                  },
                  error: (error) => {
                    const dialogRef = this.dialog.open(GenericPopUpComponent, {
                      panelClass: 'center-dialog-box',
                      backdropClass: 'backdrop',
                      width: '50%',
                      data: {
                        headerIcon: 'error',
                        title: 'Check Out Unsuccessful',
                        subtitle:
                        error?.error?.error?.errorList[0]?.msg ||
                          'Looks like you lost your connection. Please check your connection and try again. ',
                        data: assetData,
                        secondaryButtonText: 'Try Again',
                        primaryButtonText: 'View Asset Details',
                      } as IPopupConfig,
                    });
                    dialogRef.closed.subscribe((result) => {
                      if (result === 'Try Again') {
                        this.checkedOut();
                      }
                    });
                  },
                });
            }
          });
        }
      });
  }
  checkInAsset() {
    if (this.isOutForRepair) {
      this.checkoutAssetdata = {
        transactionStatus: TRANSACTION_STATUS.CHECKIN_OUT_FOR_REPAIR,
        assetId: this.assetId,
      };
      this.checkIn(this.checkoutAssetdata);
    } else if (this.isInHouseRepair) {
      this.checkoutAssetdata = {
        transactionStatus: TRANSACTION_STATUS.CHECKIN_IN_HOUSE_REPAIR,
        assetId: this.assetId,
      };
      this.checkIn(this.checkoutAssetdata);
    } else if (this.isCheckedOut) {
      this.checkoutAssetdata = {
        email: this.assetDetails.asset.asset_allocations[0]?.user?.email,
        transactionStatus: TRANSACTION_STATUS.CHECKIN,
        assetId: this.assetId,
        transactionReason: ''
      };
      this.checkIn(this.checkoutAssetdata);
    }
  }
  checkIn(checkOutAssetData: any) {
    const assetData = {
      'Asset Details': {
        'Asset Type': this.assetDetails.asset.modelName,
        'Asset Name': this.assetDetails.asset.asset_type.name,
        'Asset ID': this.assetDetails.asset.assetCode,
      },
    };
    const dialogRef = this.dialog.open(GenericPopUpComponent, {
      panelClass: 'center-dialog-box',
      backdropClass: 'backdrop',
      width: '50%',
      data: {
        headerIcon: 'warning',
        title: 'Do you want to check in?',
        subtitle:
          'Please verify the following details before checking in the asset.',
        data: assetData,
        secondaryButtonText: 'Check In',
        primaryButtonText: 'Cancel',
        showTextarea: true
      } as IPopupConfig,
    });

    dialogRef.closed.subscribe((result: any) => {
      if (result[0] === 'Check In') {
        checkOutAssetData.transactionReason = result[1];

        this.commonHttpService.checkInOrCheckOut(checkOutAssetData).subscribe({
          next: () => {
            this.isAssetHistoryUpdated = 'Checked In';
            this.disableProperty = false;
            const dialogRef = this.dialog.open(GenericPopUpComponent, {
              panelClass: 'center-dialog-box',
              backdropClass: 'backdrop',
              width: '50%',
              data: {
                headerIcon: 'success',
                title: 'Check In Successful',
                subtitle: 'Asset has been checked in successfully.',
                data: assetData,
                secondaryButtonText: 'View Asset Details',
                primaryButtonText: 'Proceed to Dashboard',
              } as IPopupConfig,
            });

            dialogRef.closed.subscribe((result) => {
              if (result === 'Proceed to Dashboard') {
                this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
              }
              if (checkOutAssetData.transactionStatus === 'CheckIn') {
                this.isCheckedOut = false;
              } else {
                this.isOutForRepair = false;
              }
              this.toastrService.success(
                'Asset Checked-In Successfully...',
                '',
                {
                  toastClass: 'toaster-wrapper',
                }
              );
              this.getAssetDetails(this.assetId);
            });
          },
          error: () => {
            const dialogRef = this.dialog.open(GenericPopUpComponent, {
              panelClass: 'center-dialog-box',
              backdropClass: 'backdrop',
              width: '50%',
              data: {
                headerIcon: 'error',
                title: 'Check In Unsuccessful',
                subtitle:
                  'Looks like you lost your connection. Please check your connection and try again. ',
                data: assetData,
                secondaryButtonText: 'Try Again',
                primaryButtonText: 'View Asset Details',
              } as IPopupConfig,
            });

            dialogRef.closed.subscribe((result) => {
              this.toastrService.error('Asset Checked-In Failed', '', {
                toastClass: 'toaster-wrapper',
              });
              if (result === 'Try Again') {
                this.checkIn(checkOutAssetData);
              }
            });
          },
        });
        this.onCancel();
      }
    });
    this.getAssetDetails(this.assetId);
  }

  navigateToEmployeeDetails(userId: string) {
    this.router.navigate(['home/employee-details', userId]);
  }

  sendCustodayVerification(userId: string) {
    if (userId) {
      this.commonHttpService
        .sendCustodyVerificationMail({
          userId,
          assetId: this.assetId,
        })
        .subscribe((result: any) => {
          this.alertBox = true;
          this.snackBarConfig = {
            content: 'Custody verification request has been sent successfully.',
            color: '#ECFDF3',
            icon: 'tick',
            status: 'success',
            showOnlySnackbar: false,
          };
        });
    }
  }

  // auto-complete
  @ViewChildren('options') options!: QueryList<ElementRef>;

  @HostListener('keydown', ['$event']) arrowKey(e: any) {
    if (this.visibility) {
      if (e.key === 'ArrowUp' && this.tabIndex > 0) {
        this.tabIndex -= 1;
        this.focusElement(this.tabIndex, 'previous', this.options);
      } else if (
        e.key === 'ArrowDown' &&
        this.tabIndex < this.options.length - 1
      ) {
        this.tabIndex += 1;
        this.focusElement(this.tabIndex, 'next', this.options);
      }
    }
    this.cdr.markForCheck();
  }

  @HostListener('document:keyup.enter', ['$event']) onEnter() {
    const currentUser = this.getCurrentUserList(this.tabIndex);
    if (currentUser) this.onSelect(currentUser);
  }

  getCurrentUserList = (index: number) => {
    return this.autoFilter(this.formControl.value)[index];
  };

  @HostListener('document:click') onClick() {
    this.hide();
  }

  @HostListener('keyup.Escape') onEscape() {
    this.hide();
  }

  @HostListener('keyup.backspace') onBackspace() {
    this.show();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.serial) {
        this.assignIndex(this.options);
      }
    });
  }

  assignIndex(queryList: QueryList<ElementRef>) {
    queryList
      .toArray()
      .forEach(
        (obj, index) => (obj.nativeElement.tabIndex = this.tabIndex + index + 1)
      );
  }

  show() {
    this.visibility = true;
  }

  hide() {
    // this.getAllUsers();
    this.visibility = false;
  }

  onSelect(obj: any) {
    this.formControl.setValue(obj.email);
    this.value = this.formControl.value;
    this.hide();
  }
  onSearch(event: any) {
    const text = event.target.value;
    if (text.length === 0) {
      return this.hide();
    }
    this.filters$ = this.formControl.valueChanges.pipe(
      map((value) => {
        return this.autoFilter(value);
      })
    );
    this.userList.length > 0 ? this.show() : this.hide();
    this.cdr.markForCheck();
  }

  private autoFilter(value: string) {
    const text = value.toLowerCase();
    return this.userList.filter(
      (option) =>
        option?.email
          ?.toLocaleLowerCase()
          .startsWith(text.toLocaleLowerCase()) ||
        option?.firstName
          ?.toLocaleLowerCase()
          .startsWith(text.toLocaleLowerCase()) ||
        option?.lastName
          ?.toLocaleLowerCase()
          .startsWith(text.toLocaleLowerCase())
    );
  }

  getAllUsers() {
    this.commonHttpService.getAllUsers().subscribe({
      next: (usersData) => {
        this.userList = Object(usersData).data.users;
      },
    });
  }

  focusElement = (
    tabIndex: number,
    method: string,
    queryList: QueryList<ElementRef>
  ) => {
    let relatedTabIndex = 0;
    if (method === 'next') {
      relatedTabIndex = tabIndex + 1;
    } else if (method === 'previous') {
      relatedTabIndex = tabIndex - 1;
    }
    const element = queryList.find(
      (elm) => elm.nativeElement.tabIndex === relatedTabIndex
    );

    if (element) {
      if (element.nativeElement.disabled) {
        this.focusElement(relatedTabIndex, method, queryList);
      }
      element.nativeElement.focus();
    } else {
      if (method === 'next') {
        if (relatedTabIndex < queryList.length) {
          this.focusElement(relatedTabIndex + 1, 'next', queryList);
        } else if (relatedTabIndex > queryList.length) {
          this.focusElement(0, 'next', queryList);
        }
      } else if (method === 'previous') {
        if (relatedTabIndex > 0) {
          this.focusElement(relatedTabIndex - 1, 'previous', queryList);
        } else if (relatedTabIndex > -1) {
          this.focusElement(
            relatedTabIndex + queryList.length - 1,
            'next',
            queryList
          );
        }
      }
    }
  };

  onConfirmCheckOutForRepair() {
    this.toggleRepairConfirmModal = true;
  }
  triggerModal(modal: string) {
    if (modal === 'out-for-repair-modal') {
      this.checkoutAssetdata = {
        assetName: this.assetDetails?.asset?.name,
        assetCode: this.assetDetails?.asset?.assetCode,
        assetType: this.assetDetails?.asset?.asset_type?.id,
        assetId: this.assetDetails.asset.id,
      };
      this.modalService
        .triggerModal(modal, this.checkoutAssetdata)
        ?.subscribe((result) => {
          if (result) {
            this.onCheckOutForRepair();
          }
          this.isAssetHistoryUpdated = 'Out For Repair';
          this.getAssetDetails(this.assetId);
        });
    }
  }
  onCheckOutForRepair() {
    this.toggleRepairAlertModal = true;
    this.checkoutAssetdata = {
      transactionStatus: TRANSACTION_STATUS.CHECKOUT_OUT_FOR_REPAIR,
      assetId: this.assetId,
      assetStatus: 'OUT_FOR_REPAIR',
    };
    const loadRequest = this.commonHttpService.checkOutAsset(
      this.checkoutAssetdata
    );
    loadRequest.subscribe({
      next: () => {
        this.checkOutForRepairStatus = true;
        const data = {
          'Asset details': {
            'Asset Name': this.assetDetails.asset.name,
            'Asset ID': this.assetDetails.asset.assetCode,
          },
        };
        this.modalService
          .triggerModal('checkout-for-repair-successfull', data)
          ?.subscribe((result) => {
            this.getAssetDetails(this.assetId);
          });
      },
      error: (error: HttpErrorResponse) => {
        this.checkOutForRepairStatus = false;
        const popupConfig: IPopupConfig = {
          headerIcon: 'error',
          title: 'Check Out Unsuccessful',
          subtitle: 'Something went wrong!',
          data: {
            'Asset details': {
              'Asset Name': this.assetDetails.asset.name,
              'Asset ID': this.assetDetails.asset.assetCode,
            },
          },
          primaryButtonText: '',
          secondaryButtonText: '',
        };
        const dialogRef = this.dialog.open(GenericPopUpComponent, {
          panelClass: 'center-dialog-box',
          backdropClass: 'backdrop',
          data: popupConfig,
          width: '50%',
        });
        dialogRef.closed.subscribe((result) => {
          this.getAssetDetails(this.assetId);
        });
      },
    });
  }

  onDisable() {
    const data = {
      assetId: this.assetId,
      transactionStatus: '',
    };
    if (
      this.assetStatus === ASSET_STATUS.AVAILABLE ||
      ASSET_STATUS.IN_HOUSE_REPAIR
    ) {
      data.transactionStatus = TRANSACTION_STATUS.CHECKOUT_NOT_IN_USE;
      this.header = 'Do you want to disable this asset?';
      this.primaryBtn = 'Ok';
      this.description = 'Select one of the options below.';
    }
    if (this.assetStatus.toUpperCase() === ASSET_STATUS.NOT_IN_USE) {
      data.transactionStatus = TRANSACTION_STATUS.CHECKIN_NOT_IN_USE;
      this.header = 'Do you want to enable this asset?';
      this.primaryBtn = 'Enable';
      this.description = 'Select one of the options below';
    }
    this.openDialog(data);
    this.getAssetDetails(this.assetId);
  }

  openDialog(value: { assetId: string; transactionStatus: string }) {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: this.header,
          description: this.description,
          primaryBtn: this.primaryBtn,
          secondaryBtn: 'Cancel',
          image: 'cautionImage',
          assetStatusOption:
            value.transactionStatus === TRANSACTION_STATUS.CHECKIN_NOT_IN_USE
              ? false
              : true,
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response) {
        const data = response.index
          ? {
            assetId: value.assetId,
            transactionStatus: TRANSACTION_STATUS.CHECKOUT_E_WASTE,
            transactionReason: response.transactionReason,
          }
          : { ...value, transactionReason: response.transactionReason };
        const isValid =
          response.buttonText === 'Enable' ||
          response === 'Enable' ||
          response === 'Ok' ||
          response.buttonText === 'Ok';

        if (!this.disableProperty && isValid) {
          this.commonHttpService.checkInOrCheckOut(data).subscribe({
            next: (response) => {
              this.updateAssetStatusResponse = Object(response);
              if (
                this.updateAssetStatusResponse.data.newAssetStatus ===
                ASSET_STATUS.NOT_IN_USE
              ) {
                this.isAssetHistoryUpdated =
                  TRANSACTION_STATUS.CHECKOUT_NOT_IN_USE;
                this.disableButtonImage = 'assets/14x14/icon-openeye.svg';
                this.disableButtonText = 'Enable';
                this.assetStatus =
                  this.updateAssetStatusResponse.data.newAssetStatus;
              } else if (
                this.updateAssetStatusResponse.data.newAssetStatus ===
                ASSET_STATUS.MARK_AS_EWASTE
              ) {
                this.isAssetHistoryUpdated =
                  TRANSACTION_STATUS.CHECKOUT_E_WASTE;
                this.disableProperty = true;
                this.disableMessage =
                  'It is not possible to enable an "discarded as e-waste" asset or out for repair asset';
                this.disableButtonImage =
                  'assets/14x14/icon-openeye-disable.svg';
                this.editButtonImage =
                  'assets/14x14/Iconedit-button-disabled-image.svg';
                this.disableButtonText = 'Enable';
                this.assetStatus =
                  this.updateAssetStatusResponse.data.newAssetStatus;
              } else {
                this.isAssetHistoryUpdated =
                  TRANSACTION_STATUS.CHECKIN_NOT_IN_USE;
                this.disableButtonImage = 'assets/14x14/Iconclosed-icon.svg';
                this.disableButtonText = 'Disable';
                this.assetStatus =
                  this.updateAssetStatusResponse.data.newAssetStatus;
              }
              this.getAssetDetails(this.assetId);
            },
          });
        }
      }
    });
  }

  displayCount($event: any) {
    this.countData = $event;
  }

  getAssetType(assetTypeId: string) {
    this.commonHttpService.getAssetTypeDetails(assetTypeId).subscribe({
      next: (response: any) => {
        this.assetType = Object(response.data);
        const { categories, configs } = this.assetType;
        const { asset: { properties } } = this.assetDetails;

        const mappedCategories = categories.map(category => {
          const filteredConfigs = configs
            .filter(config => config.category === category.name)
            .sort((a, b) => a.sequence - b.sequence);
          const mappedProperties = filteredConfigs.map(config => {
            const matchedProperty = properties.find(property => property.name === config.name);
            return {
              propertyDisplayName: matchedProperty ? matchedProperty.displayName : null,
              value: matchedProperty ? matchedProperty.value : null,
              unit: matchedProperty ? matchedProperty.unit : null
            }
          })
          return {
            displayName: category.displayName,
            sequence: category.sequence,
            mappedProperties
          };
        });
        this.mappedCategories = mappedCategories;
      },
      error: (err) => {
        console.log(err);
      },
    })
  }
}
