<ng-container>
  <app-admin-header
    coditasRoute="/home/admin-ticket/coditas"
    personalRoute="/home/admin-ticket/personal"
    personalTabPermission="TICKETS__PERSONAL"
  ></app-admin-header>
  <div
    class="asset-team-container"
    *stessaHasPermission="'DASHBOARD__CODITAS__TICKET_STATS'"
  >
    <app-alert-box
      [config]="snackBarConfig"
      *ngIf="alertBox && snackBarConfig"
      (close)="alertBox = false"
    ></app-alert-box>
    <app-asset-info
      [assets]="ticketStatus"
      [title]="'Ticket Status'"
      [titleForClientDropDown]="titleForClientDropDown"
      class="ticket-status"
      (buttonClick)="downloadTicketList()"
      [buttonText]="textOnButton"
      (onClientSelect)="onClientChange($event)"
    ></app-asset-info>
    <div class="divider-container"><div class="divider"></div></div>
    <div
      class="dash-section-wrapper container-with-no-border"
      [ngClass]="{ loader: loaderView }"
      *stessaHasPermission="'TICKETS__CODITAS__TICKET_LIST'"
    >
      <div *ngIf="loaderView">
        <app-loader></app-loader>
      </div>
      <div>
        <app-table
         [tableConfig]="tableConfig"
          [tableData]="tableDetails"
          [searchOptions]="TICKET_SEARCH_OPTIONS"
          (searchData)="search($event)"
          (activeFilter)="filterTickets($event)"
          [count]="count"
          (paginatorEmitter)="getAllTickets($event)"
          [filterOptions]="PAGINATION_DATA"
          (pageSizeChange)="pageSizeChange($event)"
          [activeMenu]="pageSize"
          class="width-100"
          [displayTicketList]="true"
          (onFilterApply)="getFilteredData($event)"
          [fieldArray]="fieldArray"
          [tableDetails]="tableDetails"
          (tableNavigationClick)="navigateToTicket($event)"
          (onSort)="handleSortAction($event)"
        ></app-table>
      </div>
    </div>
  </div>
</ng-container>
