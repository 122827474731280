import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  IRaiseAnIssueFormData,
  ITicketSnackbarConfig,
} from '@common/interfaces';
import { ASSET_TYPES } from 'libs/common/src/lib/constants/constants';
import { CommonHttpService } from 'libs/common/src/lib/service/common-http.service';
import { CommonService } from 'libs/common/src/lib/service/common.service';
import { GenericConfirmationPopUpComponent } from '../generic-confirmation-pop-up/generic-confirmation-pop-up.component';
import { GenericPopUpComponent } from '../generic-pop-up/generic-pop-up.component';
import { ROUTES } from '@common/interfaces';

@Component({
  selector: 'app-request-asset-modal',
  templateUrl: './request-asset-modal.component.html',
  styleUrls: ['./request-asset-modal.component.scss'],
})
export class RequestAssetModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Output() alertBoxEmitter = new EventEmitter();
  countData!: number[];
  constructor(
    private commonHttpService: CommonHttpService,
    private dialogRef: DialogRef,
    private dialog: Dialog,
    private router: Router
  ) {}
  popupForm = new UntypedFormGroup({
    location: new UntypedFormControl('', [Validators.required]),
    option: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [
      Validators.required,
      CommonService.cannotContainSpace,
      Validators.minLength(3),
    ]),
  });

  isDisable: boolean = false;

  options = ASSET_TYPES;
  locations: any;
  selectedAsset!: string;
  isAdmin!: boolean;
  ngOnInit(): void {
    this.isAdmin =
      JSON.parse(localStorage.getItem('userInfo') as string).roles[0] ===
      'ADMIN';
    this.getLocations();
    this.getAssetTypes();
  }

  close() {
    this.popupForm.reset();
    this.closeModal.emit('request-asset-modal');
  }

  getLocations() {
    this.commonHttpService.getAssestLocationsList().subscribe({
      next: ({ data }: any) => {
        if (data) {
          this.locations = data.map(
            ({ attributeName, attributeValue }: any) => ({
              locationKey: attributeName,
              locationValue: attributeValue,
            })
          );
        }
      },
      error: (err) => {},
    });
  }

  getValue(value: string, type: string) {
    if (type === 'option') {
      this.options.forEach((item) => {
        if (item.assetId === value) {
          this.selectedAsset = item.assetName;
        }
      });
      this.popupForm.get('option')?.setValue(value);
    } else if (type === 'location') {
      this.popupForm.get('location')?.setValue(value);
    }
  }

  onSubmit() {
    this.openConfirmationDialog();
  }

  openConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Do you want to submit the form?',
          description: 'This action cannot be undone',
          primaryBtn: 'Submit Form',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response.buttonText === 'Submit Form') {
        this.isDisable = true;

        const raiseAnIssueFormData = {} as IRaiseAnIssueFormData;
        raiseAnIssueFormData['location'] = this.popupForm.value.location;
        raiseAnIssueFormData['description'] =
          this.popupForm.value.description.trim();
        raiseAnIssueFormData['type'] = 'ALLOCATION';
        raiseAnIssueFormData['assetType'] = this.popupForm.value.option;
        raiseAnIssueFormData['title'] = 'Asset Requested';

        this.commonHttpService.raiseIssue(raiseAnIssueFormData).subscribe({
          next: (res: any) => {
            if (res instanceof HttpResponse) {
              const alertBoxConfig: ITicketSnackbarConfig = {
                ticketId: Object(res).body?.data?.id,
                content: 'Your ticket has been generated successfully.',
                color: '#ECFDF3',
                icon: 'tick',
                status: 'success',
                linkText: 'View Ticket',
                showOnlySnackbar: false,
                hasMargin: true,
              };
              const popUpConfig = {
                headerIcon: 'success',
                title: 'Ticket Generated',
                subtitle:
                  'Ticket for requesting an asset has been generated successfully',
                data: {
                  'Ticket Details': {
                    'Asset Type': this.selectedAsset,
                    Description: Object(res).body?.data?.description,
                  },
                },
                primaryButtonText: 'Proceed to Dashboard',
                secondaryButtonText: 'View Ticket',
              };
              const dialogRef = this.dialog.open<string>(
                GenericPopUpComponent,
                {
                  panelClass: 'center-dialog-box',
                  backdropClass: 'backdrop',
                  data: popUpConfig,
                  width: '50%',
                }
              );
              dialogRef.closed.subscribe((result) => {
                this.alertBoxEmitter.emit(alertBoxConfig);
                if (result === 'Proceed to Dashboard') {
                  if (this.isAdmin) {
                    // this.router.navigate([
                    //   'home/asset-team-dashboard/admin-employee-dashboard',
                    // ]);
                    this.router.navigate([
                      ROUTES.ASSET_TEAM_DASHBOARD_PERSONAL,
                    ]);
                    this.dialogRef.close(alertBoxConfig);
                  } else {
                    this.router.navigate([ROUTES.USER_DASHBOARD]);
                    this.dialogRef.close(alertBoxConfig);
                  }
                } else if (result === 'View Ticket') {
                  this.router.navigate([
                    ROUTES.TICKET_DETAILS,
                    Object(res).body.data.id,
                  ]);
                  this.dialogRef.close(alertBoxConfig);
                } else if (result === 'Proceed to Dashboard') {
                  if (this.isAdmin) {
                    this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
                    this.dialogRef.close(alertBoxConfig);
                  } else {
                    this.router.navigate([ROUTES.USER_DASHBOARD]);
                    this.dialogRef.close(alertBoxConfig);
                  }
                } else if (result === 'View Ticket') {
                  this.router.navigate([
                    ROUTES.TICKET_DETAILS,
                    Object(res).body.data.id,
                  ]);
                  this.dialogRef.close(alertBoxConfig);
                }
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            const alertBoxConfig = {
              content: 'Ticket could not be generated.',
              color: '#FFFBFA',
              icon: 'fail',
              status: 'fail',
              linkText: '',
              showOnlySnackbar: false,
            };
            const popUpConfig = {
              headerIcon: 'error',
              title: 'Ticket could not be generated',
              subtitle:
                'Looks like you lost your connection. Please check your connection and try again.',
              data: {
                'Ticket Details': {
                  'Asset Type': this.selectedAsset,
                  Description: this.popupForm.value.description,
                },
              },
              primaryButtonText: 'Cancel',
              secondaryButtonText: 'Try Again',
            };
            const dialogRef = this.dialog.open<string>(GenericPopUpComponent, {
              panelClass: 'center-dialog-box',
              backdropClass: 'backdrop',
              data: popUpConfig,
              width: '50%',
            });
            dialogRef.closed.subscribe((result) => {
              if (result === 'Try Again') {
                // this.popupForm.reset();
                this.isDisable = false;
              } else {
                this.dialogRef.close();

                if (this.isAdmin) {
                  this.router.navigate([ROUTES.ASSET_TEAM_DASHBOARD]);
                } else {
                  this.router.navigate([ROUTES.USER_DASHBOARD]);
                }
                this.alertBoxEmitter.emit(alertBoxConfig);
                // this.progressInfos[0].status = 'fail';
              }
            });
          },
        });
      }
    });
  }

  displayCount(value: any) {
    this.countData = value;
  }
  openCloseConfirmationDialog() {
    const dialogRef = this.dialog.open<string>(
      GenericConfirmationPopUpComponent,
      {
        panelClass: 'center-dialog-box',
        height: '11.75rem',
        backdropClass: 'backdrop',
        disableClose: true,
        data: {
          header: 'Are you sure you want to cancel?',
          description: 'All progress in this session will be lost',
          primaryBtn: 'Discard',
          secondaryBtn: 'Go Back',
          image: 'cautionImage',
        },
      }
    );
    dialogRef.closed.subscribe((response: any) => {
      if (response.buttonText === 'Discard') {
        this.popupForm.reset();
        this.dialogRef.removePanelClass('dialog-box');
        this.dialogRef.addPanelClass('close-dialog');
        const current = this;
        setTimeout(() => {
          current.dialogRef.close();
        }, 1000);
      }
    });
  }

  getAssetTypes() {
    this.commonHttpService.getAllAssetType().subscribe({
      next: ({data} : any) => {
        this.options = data.map((assetType: any) => {
          return {
            assetId: assetType.id,
            assetName: assetType.name,
            assetCode: assetType.assetTypeCode,
          };
        }) as any;
      },
    });
  }
}
